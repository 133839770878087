import { Label, makeStyles } from '@fluentui/react-components';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultDropDownStyles, enabledDropDownStyles } from '../../../utils/CustomStyles';
import DefaultDropdown from '../../dropdown/DefaultDropdown';
import { ShowableTaxReturnItemRecord } from './AddReturnPanel';

export interface IEditReturnAdvancedOptionsSectionProps {
    taxReturnData: ShowableTaxReturnItemRecord | undefined;
    customGroups: any;
    headerColumns: any;
    filterFunction: (text: string, dataSource: any) => any[];
    onChangePostAmountGroup: (data: any) => void;
    onChangePostAmountOption: (data: ShowableTaxReturnItemRecord) => void;
}

const useStyles = makeStyles({
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    defaultLabelStyles: {
        lineHeight: 2,
    },
});

const EditReturnAdvancedOptionsSection: React.FC<IEditReturnAdvancedOptionsSectionProps> = ({
    taxReturnData,
    customGroups,
    headerColumns,
    onChangePostAmountGroup,
    onChangePostAmountOption,
    filterFunction,
}) => {
    const { t } = useTranslation();
    const { columnContainer, defaultLabelStyles } = useStyles();

    const noneValue = 0;
    const postAmountAndCreateReportValue = 1;
    const createReportWithoutAmounts = 2;

    const postAmountOptionOptions = [
        {
            value: noneValue,
            text: t('None'),
        },
        {
            value: postAmountAndCreateReportValue,
            text: t('postAmountAndCreateReport'),
        },
        {
            value: createReportWithoutAmounts,
            text: t('createReportWithoutAmounts'),
        },
    ];

    const getPostAmountOption = (taxReturn: ShowableTaxReturnItemRecord | undefined) => {
        if (taxReturn?.updateBackReport) {
            if (taxReturn?.updateBack) {
                return postAmountAndCreateReportValue;
            }

            return createReportWithoutAmounts;
        }

        return noneValue;
    };

    const updateReturnBasedOnPostAmountOption = (taxReturn: ShowableTaxReturnItemRecord | undefined, postAmountOption: number): void => {
        let taxReturnCopy = { ...taxReturn };

        if (postAmountOption === noneValue) {
            taxReturnCopy = { ...taxReturn, updateBack: false, updateBackReport: false };
        } else if (postAmountOption === postAmountAndCreateReportValue) {
            taxReturnCopy = { ...taxReturn, updateBack: true, updateBackReport: true };
        } else {
            taxReturnCopy = { ...taxReturn, updateBack: false, updateBackReport: true };
        }

        setSelectedPostAmountOption(postAmountOption);
        onChangePostAmountOption(taxReturnCopy);
    };

    const [selectedPostAmountOption, setSelectedPostAmountOption] = React.useState(getPostAmountOption(taxReturnData));

    return (
        <>
            <div className={columnContainer}>
                <Label className={defaultLabelStyles}>{t('postAmountOption').toString()}</Label>
                <DropDownListComponent
                    id='postAmountOption'
                    value={selectedPostAmountOption}
                    width={312}
                    cssClass={`${defaultDropDownStyles} ${enabledDropDownStyles}`}
                    dataSource={postAmountOptionOptions}
                    fields={{ text: 'text', value: 'value' }}
                    onChange={(props: any) => {
                        if (props.value !== undefined && props.value !== null)
                            updateReturnBasedOnPostAmountOption(taxReturnData, parseInt(props.value));
                    }}
                />
            </div>

            <DefaultDropdown
                id='postAmountGroup'
                value={taxReturnData?.updateColumnFileKey?.toString()}
                dataSource={customGroups}
                fields={{ text: 'nameAndDescription', value: 'customGroupKey' }}
                headerColumns={headerColumns}
                label={t('postAmountGroup').toString()}
                onChange={(data) => onChangePostAmountGroup(data)}
                filterFunction={filterFunction}
                placeholder={t('selectPostAmountGroup').toString()}
            />
        </>
    );
};

export default EditReturnAdvancedOptionsSection;

import { Label } from '@fluentui/react-components';
import { DropDownListModel, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import { MultiColumnComboBoxComponent } from '@syncfusion/ej2-react-multicolumn-combobox';
import { CSSProperties, KeyboardEventHandler, useEffect, useRef } from 'react';
import { defaultDropDownStyles, enabledDropDownStyles } from '../../utils/CustomStyles';

export interface IDefaultDropdownProps {
    dropdownListProps?: DropDownListModel;
    value: string | undefined;
    dataSource: any;
    onChange: (data: any) => void;
    label: string;
    headerColumns: any;
    fields: FieldSettingsModel | undefined;
    filterFunction: (text: string, dataSource: any) => any[];
    id: string;
    containerStyles?: CSSProperties | undefined;
    placeholder?: string;
}

const defaultContainerStyles: CSSProperties = {
    marginBottom: 10,
};

const defaultLabelStyles: CSSProperties = {
    lineHeight: 2,
};

const DefaultDropdown = ({
    dataSource,
    onChange,
    value,
    fields,
    id,
    label,
    headerColumns,
    filterFunction,
    containerStyles = defaultContainerStyles,
    placeholder,
}: IDefaultDropdownProps) => {
    const dropdownRef = useRef<any>(null);
    const itemsPerPage = 300;
    let currentPage = 1;
    let currentScroll = 0;
    let paginatedData: any[] = [];

    const getInitialData = () => {
        paginatedData = dataSource.slice(0, itemsPerPage);
        if (value && !paginatedData.some((item: any) => fields?.value && item[fields.value]?.toString() === value)) {
            const selectedItem = dataSource.find((item: any) => fields?.value && item[fields.value]?.toString() === value);
            if (selectedItem) paginatedData.unshift(selectedItem);
        }
        return paginatedData;
    };

    useEffect(() => {
        if (dropdownRef?.current && !paginatedData.length) {
            const initialData = getInitialData();
            dropdownRef.current.dataSource = initialData;
            dropdownRef.current.refresh();
        }
    }, [dropdownRef]);

    const clickOnSelectedElement = () => {
        const activeItem = dropdownRef?.current?.popupEle?.querySelector('[aria-selected="true"]');
        activeItem?.click();
    };

    const handleScroll = (e: any) => {
        const scrollTop = e?.target?.scrollTop ?? 0;
        const scrollHeight = e?.target?.scrollHeight ?? 0;
        const clientHeight = e?.target?.clientHeight ?? 0;
        const endPadding = 100;
        const endReached = scrollTop + clientHeight + endPadding >= scrollHeight;

        if (endReached && dropdownRef?.current) {
            const filteredData: any[] = filterFunction(dropdownRef?.current?.element?.value, dataSource);
            const nextPage = currentPage + 1;

            if (currentPage * itemsPerPage >= filteredData.length) {
                return;
            }

            const newItems = filteredData.slice(currentPage * itemsPerPage, nextPage * itemsPerPage);
            paginatedData = [...paginatedData, ...newItems];
            dropdownRef.current.dataSource = paginatedData;

            currentScroll = scrollHeight;
            currentPage++;
        }
    };

    const onOpen = () => {
        const popupElement = dropdownRef.current?.gridObj?.scrollModule?.content;
        if (popupElement) {
            popupElement.addEventListener('scroll', handleScroll);
        }
    };

    const onClose = () => {
        const popupElement = dropdownRef.current?.popupEle;
        if (popupElement) {
            document.removeEventListener('scroll', handleScroll);
        }
    };

    const isNotSelectingRow = (ev: React.FocusEvent<HTMLElement>) => {
        const relatedTarget = ev?.nativeEvent?.relatedTarget as HTMLElement;
        return relatedTarget?.tagName !== 'TD';
    };

    const onFocus = (ev: React.FocusEvent<HTMLElement>) => {
        if (isNotSelectingRow(ev)) dropdownRef.current.showPopup();
    };

    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev: React.KeyboardEvent<HTMLElement>) => {
        if (dropdownRef?.current?.isPopupOpen) {
            if (ev.key === 'Escape') {
                dropdownRef.current.hidePopup();
                ev.preventDefault();
                ev.stopPropagation();
            }

            if (ev.key === 'Enter') {
                clickOnSelectedElement();
            }
        }
    };

    const handleChange = (props: any) => {
        if (props?.value) {
            onChange(props.value);
        }
    };

    const handleFiltering = (event: any) => {
        const filteredData = filterFunction(event?.text, dataSource);
        event?.updateData(filteredData);
    };

    const onActionComplete = (action: any) => {
        if (action?.requestType === 'refresh') {
            setTimeout(() => {
                dropdownRef.current.gridObj.scrollModule.content.scrollTo(0, currentScroll);
            }, 0);
        }
    };

    const onBlur = (ev: any) => {
        if (ev?.relatedTarget && !dropdownRef?.current?.popupEle?.contains(ev?.relatedTarget)) {
            dropdownRef.current.hidePopup();
        }
    };

    return (
        <div onKeyDownCapture={onKeyDown} style={containerStyles}>
            <Label style={defaultLabelStyles}>{label}</Label>
            <MultiColumnComboBoxComponent
                ref={dropdownRef}
                id={id}
                key={id}
                cssClass={`${defaultDropDownStyles} ${enabledDropDownStyles}`}
                dataSource={paginatedData}
                fields={fields}
                actionComplete={onActionComplete}
                delayUpdate
                width={312}
                onFocus={onFocus}
                open={onOpen}
                close={onClose}
                popupWidth={350}
                value={value}
                filtering={handleFiltering}
                onChange={handleChange}
                onBlur={onBlur}
                placeholder={placeholder}
            >
                {headerColumns()}
            </MultiColumnComboBoxComponent>
        </div>
    );
};

export default DefaultDropdown;

import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogOpenChangeData,
    DialogOpenChangeEvent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { CheckBoxFilterBase, GridComponent } from '@syncfusion/ej2-react-grids';
import { FC, KeyboardEventHandler, MutableRefObject, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AllowNull } from '../../data-types/AllowUndefinedAndNull';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import {
    getSyncfusionFocusEventHandlerHack,
    getSyncfusionPopupMenuDismissHack,
    ISyncfusionGridPopupMenuDismissHack,
} from '../../utils/DialogUtilsV9';
import { JournalEntryGrid } from './JournalEntryGrid';

export interface IJournalEntryDialogProps {
    buttonTrigger?: HTMLElement;
    onClose?: () => void;
    rowDescription?: string;
    accountCode: string;
    adjustmentCode: string;
    caseCode: string;
    jurisdiction: string;
    entityCode: string;
    period: string;
}

export const JournalEntryDialog: FC<IJournalEntryDialogProps> = (props: IJournalEntryDialogProps) => {
    const { t } = useTranslation();
    const { modalStyles, dialogBodyStyles, buttonOverrides } = useEnterAmountsDialogStyles();
    const onDialogOpenChange: DialogOpenChangeEventHandler = (event: DialogOpenChangeEvent, data: DialogOpenChangeData) => {
        if (!data.open) {
            if (data.type === 'escapeKeyDown') {
                event.preventDefault();
                event.stopPropagation();
            }
            if (props.buttonTrigger) {
                props.buttonTrigger.focus();
            }
            props.onClose && props.onClose();
        }
    };
    const cancelButtonRef: RefObject<HTMLButtonElement> = useRef(null);
    const gridRef: RefObject<GridComponent> = useRef<GridComponent>(null);
    const gridFilterMenuRef: MutableRefObject<AllowNull<CheckBoxFilterBase>> = useRef(null);
    const syncfusionFocusHack: KeyboardEventHandler<HTMLDivElement> = getSyncfusionFocusEventHandlerHack(cancelButtonRef);
    const syncfusionGridPopupMenuDismissHack: ISyncfusionGridPopupMenuDismissHack = getSyncfusionPopupMenuDismissHack(
        gridRef,
        gridFilterMenuRef
    );

    return (
        <Dialog open={true} onOpenChange={onDialogOpenChange}>
            <DialogSurface
                className={modalStyles}
                onKeyDown={syncfusionFocusHack}
                onClick={syncfusionGridPopupMenuDismissHack.dialogSurfaceClick}
            >
                <DialogBody className={dialogBodyStyles}>
                    <DialogTitle
                        action={
                            <DialogTrigger action='close'>
                                <Button
                                    ref={cancelButtonRef}
                                    appearance='subtle'
                                    aria-label={t('close').toString()}
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        {t('journalEntry')}
                        <div style={{ fontFamily: 'Segoe UI', fontSize: 14, fontStyle: 'normal', fontWeight: 400 }}>
                            {props.rowDescription}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <JournalEntryGrid
                            accountCode={props.accountCode}
                            adjustmentCode={props.adjustmentCode}
                            caseCode={props.caseCode}
                            entityCode={props.entityCode}
                            gridActionComplete={syncfusionGridPopupMenuDismissHack.gridActionComplete}
                            gridRef={gridRef}
                            jurisdiction={props.jurisdiction}
                            period={props.period}
                        />
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger>
                            <Button className={buttonOverrides}>{t('close')}</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

import { useCorptaxTheme } from '@corptax/react-components-common';
import { ISearchBoxStyles, mergeStyles, SearchBox } from '@fluentui/react';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTreeFolder, FormTreePage } from '../../../contexts/formPdfViewContext';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getDefaultSearchBoxStyles, getSearcherStyles } from '../../../utils/CustomStyles';

export interface IFormTOCSidebarSearchProps {
    setFilteredData: (data: FormTreeFolder[]) => void;
    total: number;
    expandAll: any;
}

const SEARCH_DEBOUNCE_TIME = 250;

const FormTOCSidebarSearch: React.FC<IFormTOCSidebarSearchProps> = ({ setFilteredData, total, expandAll }) => {
    const { tocData } = useFormPdfView();
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();
    const { t } = useTranslation();

    const invisibleAriaSpan = mergeStyles({
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: 1,
    });

    const searchBarContainerClass = mergeStyles({
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        marginBottom: 10,
    });

    const defaultSearchBoxStyles: ISearchBoxStyles = getDefaultSearchBoxStyles(customPalette, palette);

    const searcherStyles = mergeStyles(getSearcherStyles(customPalette, palette), { width: '100%' });

    const iconProps = {
        iconName: 'Search',
        style: { color: customPalette.themeDarkAlt, fontWeight: 'bold' },
    };

    useEffect(() => {
        searchToc(filter);
    }, [tocData?.folders]);

    const searchToc = (searchText: string | undefined) => {
        if (!searchText) {
            setFilteredData(tocData?.folders || []);
            return;
        }
        const result: FormTreeFolder[] = [];
        const currentData = cloneDeep(tocData?.folders);

        currentData?.forEach((folder) => {
            let item: FormTreeFolder = {};
            const matchPages: FormTreePage[] = [];

            if (folder.title?.toLowerCase().includes(searchText.toLocaleLowerCase())) {
                item = folder;
            }

            folder.pages?.forEach((page) => {
                if (page.title?.toLowerCase().includes(searchText.toLocaleLowerCase())) {
                    item = folder;
                    matchPages.push(page);
                }
            });

            item.pages = matchPages;

            if (item.id) {
                result.push(item);
            }
        });
        setFilteredData(result);
    };

    const searchTocDebounced = useMemo(
        () =>
            debounce((searchText: string | undefined): void => {
                if (expandAll) expandAll();
                searchToc(searchText);
            }, SEARCH_DEBOUNCE_TIME),
        [tocData]
    );

    const onSearchBoxChange = (ev: any, value: string | undefined) => {
        setFilter(value);

        if (searchTocDebounced) {
            searchTocDebounced.cancel();
        }

        searchTocDebounced(value);
    };

    const onClear = () => {
        setFilteredData(tocData?.folders || []);
    };

    return (
        <div className={searchBarContainerClass}>
            <SearchBox
                styles={defaultSearchBoxStyles}
                className={searcherStyles}
                placeholder={t('search').toString()}
                onChange={onSearchBoxChange}
                onClear={onClear}
                showIcon={true}
                iconProps={iconProps}
            />
            <span aria-live='polite' className={invisibleAriaSpan}>
                {t('totalResultsFound', { total: total || t('No') })}
            </span>
        </div>
    );
};

export default FormTOCSidebarSearch;

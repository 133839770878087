import { useCorptaxTheme } from '@corptax/react-components-common';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { KeyboardEvent, ReactEventHandler } from 'react';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getDefaultSearchBoxStyles, getSearcherStyles } from '../../../utils/CustomStyles';

export interface IFilterSearchBarProps {
    searchBoxPlaceholderText: string;
    onSearchBoxChange: (ev: React.ChangeEvent<HTMLInputElement> | undefined, newValue: string | undefined) => void;
    onAbort?: ReactEventHandler<HTMLInputElement>;
    onClear?: ReactEventHandler<HTMLInputElement>;
    shouldFocusOnFirstItemOnTab?: boolean;
}

const FilterSearchBar: React.FC<IFilterSearchBarProps> = ({
    searchBoxPlaceholderText,
    onSearchBoxChange,
    onAbort,
    onClear,
    shouldFocusOnFirstItemOnTab = true,
}) => {
    const [isSearchboxFocused, { setTrue: setIsSearchboxFocusedTrue, setFalse: setIsSearchboxFocusedFalse }] = useBoolean(false);
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();

    const defaultSearchBoxStyles: ISearchBoxStyles = {
        ...getDefaultSearchBoxStyles(customPalette, palette),
        field: {
            '::placeholder': {
                color: customPalette.whiteTranslucent40,
            },
            '::-webkit-input-placeholder': {
                color: customPalette.whiteTranslucent40,
            },
            '::-moz-placeholder': {
                color: customPalette.whiteTranslucent40,
            },
        },
    };

    const searchIconProps = {
        iconName: 'Search',
        style: { color: customPalette.themeDarkAlt, fontWeight: 'bold' },
    };

    const focusOnFirstItemOnTab = (ev: KeyboardEvent<HTMLElement>) => {
        if (ev.key === 'Tab' && shouldFocusOnFirstItemOnTab) {
            ev.preventDefault();
            ev.stopPropagation();
            document.getElementById('item-0')?.focus();
        }
    };

    return (
        <SearchBox
            onFocus={setIsSearchboxFocusedTrue}
            onBlur={setIsSearchboxFocusedFalse}
            onKeyDown={focusOnFirstItemOnTab}
            onChange={onSearchBoxChange}
            onAbort={onAbort}
            placeholder={isSearchboxFocused ? '' : searchBoxPlaceholderText}
            className={getSearcherStyles(customPalette, palette)}
            styles={defaultSearchBoxStyles}
            iconProps={searchIconProps}
            showIcon={true}
            ariaLabel={searchBoxPlaceholderText}
            onClear={onClear}
        />
    );
};

export default FilterSearchBar;

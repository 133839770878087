import { MenuEventArgs } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from 'react-i18next';
import { ICurrentCellEventArgs } from '../data-types/ICurrentCellEventArgs';
import { ContextMenuItemModelExtended, ContextMenuVisibilityAccessor } from '../utils/SyncfusionContextMenuUtils';
import { IContextMenuItemOptions } from './IContextMenuItemOptions';

export const useJournalEntryContextMenuItem = <T>(
    onMenuItemClick?: (args?: ICurrentCellEventArgs<T>) => void,
    getVisibilityState?: ContextMenuVisibilityAccessor
): IContextMenuItemOptions<T> => {
    const { t } = useTranslation();
    const journalEntryMenuActionId = 'viewJournalEntry';
    const contextMenuItem: ContextMenuItemModelExtended = {
        text: t('journalEntryContextMenuItem').toString(),
        id: journalEntryMenuActionId,
        iconCss: 'icon--fluent icon--fluent--document-bullet-list-clock-20-regular',
        visibilityAccessor: getVisibilityState,
    };

    const handleContextMenuItemClick = (args: MenuEventArgs, currentCellArgs?: ICurrentCellEventArgs<T> | null | undefined) => {
        if (args.item.id === journalEntryMenuActionId && onMenuItemClick) {
            onMenuItemClick(currentCellArgs!);
            return true;
        }
        return false;
    };
    return {
        contextMenuItem,
        handleContextMenuItemClick,
    };
};

import { ICardGridListRowData } from '../components/card-grid-list/CardGridListInterfaces';
import { CardGridListExpandCollapseState } from '../components/card-grid-list/CardGridListTypes';
import { recalcNeededClass } from '../components/sidebar/AccountAmountTabPanel.constants';
import { IJournalEntryDialogProps } from '../components/sidebar/JournalEntryDialog';
import { DrillDownAction, DrillDownActionType, IEnterAmountsOptionalProps } from '../components/sidebar/ReportDrillDownActions';
import {
    getAccountAmountSummaryByEphemeralKey,
    IAccountAmountWhitePaperRow,
    ITrackedExtractionDetailsAccountSummary,
    ITrackedUpdateTime,
} from '../components/sidebar/ReportDrillDownUtils';
import { AllowNull } from '../data-types/AllowUndefinedAndNull';
import {
    ExtractionDetailsAdjustmentEntry,
    ExtractionDetailsAdjustmentInfo,
    ExtractionDetailsBookEntry,
    ExtractionDetailsBookInfo,
    ExtractionDetailsRequest,
    IWhitePaperReportChanges,
    ProblemDetails,
    Report,
    SaveAmountResponse,
    SaveAmountsResponse,
} from '../model';
import { addDrillDownAmountCellToChangeTrackingData } from '../utils/ChangeTrackingDataUtils';
import { deFormatNumber } from '../utils/NumberUtils';

export interface IExportButtonState {
    isProcessing: boolean;
    onClick: () => void;
}

export interface IExpandCollapseAllButtonState {
    disabled: boolean;
    expandCollapseState: CardGridListExpandCollapseState;
}

export interface IErrorMessage {
    message: string;
    needsTranslation: boolean;
    translationArgs?: any;
}

export interface IDrillDownState {
    journalEntryDialogProps: AllowNull<IJournalEntryDialogProps>;
    activeTabIndex: number;
    //TODO: merge these maps together. these are different buttons whose state both depend on which tax index is active at the moment
    exportButtonStateMap: Map<number, IExportButtonState>;
    expandCollapseAllButtonState: Map<number, IExpandCollapseAllButtonState>;
    showConfirmationDialog: boolean;
    currentAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[];
    isInitializing: boolean;
    errorMessages: IErrorMessage[];
    isServerError: boolean;
    initialAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[] | null;
    accountAmountGridHasData: boolean;
    amountsChangedOnServer: boolean;
    isFetchingOrSavingData: boolean;
    changeTrackingData: IWhitePaperReportChanges;
    showConfirmationDialogOnExit: boolean;

    // if not null, the enter amounts dialog will appear. if null, the dialog will not appear
    enterAmountsDialogProps: AllowNull<IEnterAmountsOptionalProps>;
    updatedChangeTrackingData: IWhitePaperReportChanges;
}

const getMergedAccountSummaries = (
    initialAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[],
    currentAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[]
) => {
    const mergedAccountSummaries = [] as ITrackedExtractionDetailsAccountSummary[];
    initialAccountAmountSummaries?.forEach((summary: ITrackedExtractionDetailsAccountSummary) => {
        const dirtySummary = getAccountAmountSummaryByEphemeralKey(
            summary.accountNumber!,
            summary.ephemeralKey!,
            currentAccountAmountSummaries
        );
        const summaryForAccount = dirtySummary ? dirtySummary : summary;
        mergedAccountSummaries.push({ ...summaryForAccount });
    });
    return mergedAccountSummaries;
};

export type DrillDownStateReducer = (state: IDrillDownState, action: DrillDownAction) => IDrillDownState;

export function drillDownStateReducer(state: IDrillDownState, action: DrillDownAction): IDrillDownState {
    try {
        switch (action.type) {
            case DrillDownActionType.ChangeActiveTabIndex:
                return { ...state, activeTabIndex: action.newActiveTab ?? 0 };
            case DrillDownActionType.StartProcessingExport:
                const exportButtonStateMap = new Map(state.exportButtonStateMap);
                const exportButtonState = exportButtonStateMap.get(action.eventTargetTabIndex!);
                if (exportButtonState) {
                    exportButtonState.isProcessing = true;
                    exportButtonStateMap.set(action.eventTargetTabIndex!, exportButtonState);
                }
                return { ...state, exportButtonStateMap, errorMessages: [] };
            case DrillDownActionType.StopProcessingExport:
                const exportButtonStateMapStop = new Map(state.exportButtonStateMap);
                const exportButtonStateStop = exportButtonStateMapStop.get(action.eventTargetTabIndex!);
                if (exportButtonStateStop) {
                    exportButtonStateStop.isProcessing = false;
                    exportButtonStateMapStop.set(action.eventTargetTabIndex!, exportButtonStateStop);
                }
                return { ...state, exportButtonStateMap: exportButtonStateMapStop };
            case DrillDownActionType.StartProcessingSaveAmounts:
                return {
                    ...state,
                    isInitializing: false,
                    isFetchingOrSavingData: true,
                    isServerError: false,
                    errorMessages: [],
                    showConfirmationDialog: false,
                    showConfirmationDialogOnExit: false,
                };
            case DrillDownActionType.SaveAmountsSuccess:
                return {
                    ...state,
                    initialAccountAmountSummaries: getMergedAccountSummaries(
                        state.initialAccountAmountSummaries!,
                        state.currentAccountAmountSummaries
                    ),
                    currentAccountAmountSummaries: [],
                    amountsChangedOnServer: true,
                    changeTrackingData: action.updatedChangeTrackingData!,
                    updatedChangeTrackingData: {},
                };
            case DrillDownActionType.SaveAmountsFailure:
                // what data comes back if there's no errors?
                if (action?.error?.response?.status === 500) {
                    const problemDetails: ProblemDetails = action?.error?.response?.data as ProblemDetails;
                    return {
                        ...state,
                        isServerError: true,
                        errorMessages: [{ message: `${problemDetails?.title ?? ''} - ${problemDetails?.traceId ?? ''}` } as IErrorMessage],
                    };
                } else {
                    // orval doesn't seem to handle the case where the api can return one of two distinct model types depending on the error code.
                    // so we need to cast the response data as unknown first as an intermediary step
                    const response: SaveAmountsResponse = action?.error?.response?.data as unknown as SaveAmountsResponse;
                    const errorMessagesForAmounts: IErrorMessage[] = response?.amountResponses?.map(mapSaveAmountsError()) ?? [];
                    const requestLevelErrorMessages: IErrorMessage[] =
                        response?.errors?.map((error) => {
                            return {
                                message: error,
                                needsTranslation: false,
                            } as IErrorMessage;
                        }) ?? [];
                    const errorMessages: IErrorMessage[] = requestLevelErrorMessages.concat(errorMessagesForAmounts);
                    return { ...state, isServerError: false, errorMessages: errorMessages };
                }
            case DrillDownActionType.SaveAmountsComplete:
                return { ...state, isFetchingOrSavingData: false };
            case DrillDownActionType.AmountEdited:
                const item: IAccountAmountWhitePaperRow = action.item;
                let updatedAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[] | undefined;
                let updatedChangeTrackingData: IWhitePaperReportChanges | undefined;

                switch (item!.dataObjectType) {
                    case 'AdjustmentEntry':
                    case 'AdjustmentSummary':
                        [updatedAccountAmountSummaries, updatedChangeTrackingData] = onAdjustmentEntryChanged(
                            action.event,
                            action.report,
                            action.rowNumber,
                            action.columnId,
                            item,
                            state.currentAccountAmountSummaries,
                            state.initialAccountAmountSummaries!,
                            state.updatedChangeTrackingData
                        );

                        break;

                    default:
                        break;
                }

                return {
                    ...state,
                    currentAccountAmountSummaries: updatedAccountAmountSummaries ?? state.currentAccountAmountSummaries,
                    updatedChangeTrackingData: updatedChangeTrackingData ?? state.updatedChangeTrackingData,
                    showConfirmationDialogOnExit: true,
                };

            case DrillDownActionType.ExportFailure:
                const drillDownExportType: string = action.eventTargetTabIndex === 0 ? 'Account/Amount' : 'Trace Calculation';
                const exportButtonStateMapFailure = new Map(state.exportButtonStateMap);
                const exportButtonStateFailure = exportButtonStateMapFailure.get(action.eventTargetTabIndex!);
                if (exportButtonStateFailure) {
                    exportButtonStateFailure.isProcessing = false;
                    exportButtonStateMapFailure.set(action.eventTargetTabIndex!, exportButtonStateFailure);
                }
                return {
                    ...state,
                    exportButtonStateMap: exportButtonStateMapFailure,
                    errorMessages: [{ message: 'exportError', needsTranslation: true, translationArgs: { drillDownExportType } }],
                };
            case DrillDownActionType.RequestCellAuditStart:
                const requests: ExtractionDetailsRequest[] | undefined = action.extractionDetailsRequests;

                // Calculated row references only rows which have been filtered from the report.
                // This is a valid scenario, but we don't need to make a request for this row.
                const initialAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[] | null = requests?.length === 0 ? [] : null;
                return {
                    ...state,
                    initialAccountAmountSummaries: initialAccountAmountSummaries,
                    isInitializing: false,
                    isFetchingOrSavingData: true,
                    isServerError: false,
                    errorMessages: [],
                };
            case DrillDownActionType.RequestCellAuditEnd:
                return { ...state, isFetchingOrSavingData: false };
            case DrillDownActionType.RequestCellAuditFailure:
                const problemDetails: ProblemDetails = action.error?.response?.data as ProblemDetails;
                const errorMessages: IErrorMessage[] = problemDetails
                    ? [{ message: `${problemDetails?.title ?? ''} - ${problemDetails?.traceId ?? ''}` } as IErrorMessage]
                    : // this handles network errors (error.code === "ERR_NETWORK") as well as HTTP 401 responses.
                      // It would be more consistent if HTTP 401 responses were returned as ProblemDetails too,
                      // but that would probably require some kind of middleware change in BFF which is riskier than just doing this
                      [{ message: 'networkError', needsTranslation: true } as IErrorMessage];
                return { ...state, isServerError: true, errorMessages: errorMessages };
            case DrillDownActionType.RequestCellAuditSuccess:
                return { ...state, initialAccountAmountSummaries: action.initialAccountAmountSummaries ?? null };
            case DrillDownActionType.CardGridListExpandCollapseStateChange:
                const expandCollapseAllButtonState = new Map(state.expandCollapseAllButtonState);
                const expandCollapseState = action.updatedExpandCollapseState;
                expandCollapseAllButtonState.set(state.activeTabIndex, {
                    disabled: false,
                    expandCollapseState: expandCollapseState!,
                });
                return { ...state, expandCollapseAllButtonState: expandCollapseAllButtonState };
            case DrillDownActionType.ConfirmationDialogOpened:
                return { ...state, showConfirmationDialog: true };
            case DrillDownActionType.ConfirmationDialogClosed:
                return { ...state, showConfirmationDialog: false };
            case DrillDownActionType.AdjustmentModalAmountsSave:
                return { ...state, amountsChangedOnServer: true, changeTrackingData: action.updatedChangeTrackingData! };
            case DrillDownActionType.EnterAmountsDialogOpened:
                return { ...state, enterAmountsDialogProps: action.enterAmountsDialogProps };
            case DrillDownActionType.EnterAmountsDialogClosed:
                const amountsChangedOnServer: boolean = state.changeTrackingData !== action.updatedChangeTrackingData;

                return {
                    ...state,
                    changeTrackingData: action.updatedChangeTrackingData!,
                    enterAmountsDialogProps: null,
                    amountsChangedOnServer: state.amountsChangedOnServer || amountsChangedOnServer,
                };
            case DrillDownActionType.JournalEntryDialogOpened:
                return { ...state, journalEntryDialogProps: action.journalEntryDialogProps };
            case DrillDownActionType.JournalEntryDialogClosed:
                return { ...state, journalEntryDialogProps: null };
            default:
                throw new Error('unsupported action type');
        }
    } catch (e: any) {
        const error: Error = e as Error;
        console.error(error.stack);
        return {
            ...state,
            errorMessages: [{ message: 'genericDrillDownError', needsTranslation: true, translationArgs: { errorMessage: error.message } }],
        };
    }
}

const extractionDetailEntriesAreEqual = (
    left: ExtractionDetailsBookEntry | ExtractionDetailsAdjustmentEntry,
    right: ExtractionDetailsBookEntry | ExtractionDetailsAdjustmentEntry
) => {
    return (
        left.corporation === right.corporation &&
        left.case === right.case &&
        left.jurisdiction === right.jurisdiction &&
        left.location === right.location &&
        left.year === right.year
    );
};

const getAccountSummaryForAmountUpdate = (
    accountNumber: string,
    ephemeralKey: number,
    currentAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[],
    initialAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[]
): [ITrackedExtractionDetailsAccountSummary | undefined, boolean] => {
    let accountSummary: ITrackedExtractionDetailsAccountSummary | undefined = getAccountAmountSummaryByEphemeralKey(
        accountNumber,
        ephemeralKey,
        currentAccountAmountSummaries
    );

    const isAccountSummaryDirty: boolean = !!accountSummary;

    if (!isAccountSummaryDirty) {
        accountSummary = getAccountAmountSummaryByEphemeralKey(accountNumber, ephemeralKey, initialAccountAmountSummaries ?? []);
    }

    return [accountSummary, isAccountSummaryDirty];
};

const onAdjustmentEntryChanged = (
    event: any,
    report: Report,
    rowNumber: number,
    columnId: number,
    item: IAccountAmountWhitePaperRow,
    currentAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[],
    initialAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[],
    currentChangeTrackingData: IWhitePaperReportChanges
): [ITrackedExtractionDetailsAccountSummary[] | undefined, IWhitePaperReportChanges | undefined] => {
    const accountAmountsSummaries: ITrackedExtractionDetailsAccountSummary[] | undefined = getUpdatedAccountSummaries(
        event,
        item,
        currentAccountAmountSummaries,
        initialAccountAmountSummaries
    );
    const changeTrackingData: IWhitePaperReportChanges | undefined = addDrillDownAmountCellToChangeTrackingData(
        report,
        rowNumber,
        columnId,
        item,
        currentChangeTrackingData
    );

    (item as any as ICardGridListRowData).gridGroup.className += ` ${recalcNeededClass}`;
    return [accountAmountsSummaries, changeTrackingData];
};

const addUpdatedAmountToCurrentAccountAmountSummaries = (
    newAmount: string,
    updatedItem:
        | ExtractionDetailsAdjustmentEntry
        | ExtractionDetailsAdjustmentInfo
        | ExtractionDetailsBookEntry
        | ExtractionDetailsBookInfo
        | undefined,
    updatedItemAccountSummary: ITrackedExtractionDetailsAccountSummary | undefined,
    isAccountSummaryAlreadyDirty: boolean,
    currentAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[] | null
): ITrackedExtractionDetailsAccountSummary[] | undefined => {
    if (updatedItem!.reportAmount === newAmount) {
        return;
    }

    const finalUpdates: ITrackedExtractionDetailsAccountSummary[] = [...currentAccountAmountSummaries!];

    if (!isAccountSummaryAlreadyDirty && updatedItemAccountSummary) {
        // TODO:  This will likely need to be revised if/when revert of changed values is implemented as it will overwrite original values
        //        in referenced data objects.
        finalUpdates.push({ ...updatedItemAccountSummary });
    }

    // TODO: Currently it is assumed that we wwill find the row to update.
    //       Checking and handling for when no existing row is found will need to be added if/when functionality is implemented where
    //       a row may not exist (I.E. adding a new row or deleting an existing row)
    updatedItem!.reportAmount = newAmount;
    return finalUpdates;
};

function mapSaveAmountsError(): (value: SaveAmountResponse, index: number, array: SaveAmountResponse[]) => IErrorMessage {
    return (response) => {
        return {
            message: response.adjustmentCode ? 'saveAmountErrorWithAdjustmentCode' : 'saveAmountError',
            needsTranslation: true,
            translationArgs: {
                account: response.accountCode,
                entity: response.entity,
                adjustmentCode: response.adjustmentCode,
                joinedMessages: response.errors?.join(' ') ?? '',
            },
        };
    };
}

const getUpdatedAccountSummaries = (
    event: any,
    item: IAccountAmountWhitePaperRow,
    currentAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[],
    initialAccountAmountSummaries: ITrackedExtractionDetailsAccountSummary[]
): ITrackedExtractionDetailsAccountSummary[] | undefined => {
    const isItemAdjustmentEntry: boolean = item.dataObjectType === 'AdjustmentEntry'; // If false, then item is AdjustmentSummary
    const accountNumber: string = item.account!;
    const ephemeralKey: number = item.summaryEphemeralKey;
    const adjustmentCode: string = isItemAdjustmentEntry ? item.parent!.adjustmentCode! : item.adjustmentCode!;
    const [accountSummary, isAccountSummaryDirty] = getAccountSummaryForAmountUpdate(
        accountNumber,
        ephemeralKey,
        currentAccountAmountSummaries,
        initialAccountAmountSummaries
    );

    const adjustmentInfo: ExtractionDetailsAdjustmentInfo | undefined = accountSummary!.adjustmentInfo!.find(
        (adjustmentInfo) => adjustmentInfo.adjustmentCode === adjustmentCode
    );

    let adjustmentItemToUpdate: ExtractionDetailsAdjustmentEntry | ExtractionDetailsAdjustmentInfo | undefined;

    if (isItemAdjustmentEntry) {
        adjustmentItemToUpdate = adjustmentInfo!.entries!.find((adjustmentEntry) =>
            extractionDetailEntriesAreEqual(adjustmentEntry, item.dataObject)
        );
    } else {
        adjustmentItemToUpdate = adjustmentInfo;
    }

    (adjustmentItemToUpdate as ITrackedUpdateTime).updateTimestamp = Date.now();

    return addUpdatedAmountToCurrentAccountAmountSummaries(
        deFormatNumber(event.target.innerHTML),
        adjustmentItemToUpdate,
        accountSummary,
        isAccountSummaryDirty,
        currentAccountAmountSummaries
    );
};

import { useCorptaxTheme } from '@corptax/react-components-common';
import { mergeStyles, mergeStyleSets, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFeatureEnabled } from '../../../api/features/features';
import { useDeleteReturnsFromFolder, useGetAllFolders } from '../../../api/report/report';
import { ISelectedTaxReturnsAndFilters } from '../../../data-types/exportReports';
import { IFilterItem } from '../../../data-types/filterItem';
import { FilterTabDefinition } from '../../../data-types/filterTabDefinition';
import { useFeedbackMessages } from '../../../hooks/useFeedbackMessages';
import { useFunctionalAccess } from '../../../hooks/useFunctionalAccess';
import { useGridState } from '../../../hooks/useGridState';
import { useSearchTable } from '../../../hooks/useSearchTable';
import { IAppTheme } from '../../../theme/IAppTheme';
import CartButton from '../../common/CartButton';
import ExportReportButton from '../../common/ExportReportButton';
import FilterGroup from '../../common/FilterGroup';
import FunctionalAccessDisableWrapper from '../../common/FunctionalAccessDisableWrapper';
import GridSearchBar from '../../common/GridSearchBar';
import CustomIconButton from '../../common/IconButton';
import ConfirmationDialog from '../../dialog/ConfirmationDialog';
import FilterButton from '../../filters/filter-button/FilterButton';
import TabFilterButton from '../../filters/tab-filter-button/TabFilterButton';
import AddToFolderModal from '../../folders/AddToFolderModal';
import FoldersListFilter from './FoldersListFilter';

export interface IFilterBarProps {
    isFiltered: boolean;
    periods: IFilterItem[];
    entities: IFilterItem[];
    entityGroups: IFilterItem[];
    cases: IFilterItem[];
    jurisdictions: IFilterItem[];
    folders: IFilterItem[];
    disabled: boolean;
    clearHighlightedElements: () => void;
    updateFolderList?: () => Promise<any>;
    getReportsToExport: () => ISelectedTaxReturnsAndFilters;
    setColumnFilters: Dispatch<SetStateAction<Record<string, IFilterItem[]>>>;
    disableActionButtons?: boolean;
    openAddReturnSidebarHandler?: () => void;
}

const FilterBar: React.FC<IFilterBarProps> = ({
    isFiltered,
    periods,
    entities,
    entityGroups,
    cases,
    jurisdictions,
    folders,
    disabled,
    getReportsToExport,
    setColumnFilters,
    clearHighlightedElements,
    updateFolderList,
    disableActionButtons = false,
    openAddReturnSidebarHandler,
}) => {
    const { t } = useTranslation();
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();
    const { displayFeedbackMessage } = useFeedbackMessages();
    const { isSearchValueApplied, clearSearch } = useSearchTable();
    const { grid } = useGridState();
    const queryClient = useQueryClient();
    const [isFolderModalVisible, { toggle: toggleFolderModalVisibility }] = useBoolean(false);
    const [removeFromFolderConfirmationVisible, { setTrue: showRemoveFromFolderConfirmation, setFalse: hideRemoveFromFolderConfirmation }] =
        useBoolean(false);
    const { functionalAccess } = useFunctionalAccess();
    const selectedReturns = getReportsToExport().selectedTaxReturns?.map((r) => r.id ?? 0) || [];

    const { data: folderItems, isLoading: isLoadingGetAllFolders, queryKey: getAllFoldersQuery } = useGetAllFolders();
    const { data: isCreateNewReturnEnabled } = useIsFeatureEnabled({
        featureFlagName: 'CreateNewReturnEnabled',
    });

    const { mutateAsync: deleteReturnsFromFolder, isLoading: isLoadingDeleteReturnsFromFolder } = useDeleteReturnsFromFolder({
        mutation: {
            onSuccess: () => {
                queryClient.invalidateQueries(getAllFoldersQuery);
                hideRemoveFromFolderConfirmation();
                displayFeedbackMessage({ type: MessageBarType.success, message: t('calculationRemovedSuccessMessage') });
            },
        },
    });

    const getHeaderBarStyle = (menuBarBaseStyle: string) =>
        mergeStyles(menuBarBaseStyle, {
            padding: '12px 16px',
        });

    const getFilterBarStyle = (menuBarBaseStyle: string) =>
        mergeStyles(menuBarBaseStyle, {
            position: 'relative',
            margin: '0 15px',
            border: '1px solid',
            borderColor: customPalette.greyD1D3D4,
            borderRadius: '6px 6px 0 0',
            boxShadow: 'none',
        });

    const getFilterBarStyles = () =>
        mergeStyleSets({
            breakStackStyle: {
                alignItems: 'flex-start',
                gap: '10px',
                width: 'auto',
                height: '15px',
                flex: 'none',
                order: 0,
                flexGrow: 0,
                zIndex: 0,
                opacity: '100%',
                backgroundColor: 'none',
            },
            menuBarBaseStyle: {
                alignItems: 'flex-end',
                padding: '12px 8px',
                width: 'auto',
                flex: 'none',
                order: 0,
                flexGrow: 0,
                zIndex: 0,
                border: 'none',
                boxShadow: '0px 1.6px 0px 0px #00000021',
                background: palette.white,
                '& > div': {
                    gap: '12px 15px',
                },
            },
            filterPanelStyle: {
                position: 'relative',
            },
            rightAlignButtons: {
                marginLeft: 'auto !important',
            },
        });

    const addNewReturnButtonStyle = mergeStyles({
        marginLeft: 'auto',
    });

    const { filterPanelStyle, breakStackStyle, menuBarBaseStyle, rightAlignButtons } = getFilterBarStyles();
    const headerStyles = getHeaderBarStyle(menuBarBaseStyle);
    const filterBarStyle = getFilterBarStyle(menuBarBaseStyle);

    const [checkedPeriodItems, setCheckedPeriodItems] = useState<IFilterItem[]>([]);
    const [checkedEntityItems, setCheckedEntityItems] = useState<IFilterItem[]>([]);
    const [checkedEntityGroupItems, setCheckedEntityGroupItems] = useState<IFilterItem[]>([]);
    const [checkedCaseItems, setCheckedCaseItems] = useState<IFilterItem[]>([]);
    const [checkedJurisdictionItems, setCheckedJurisdictionItems] = useState<IFilterItem[]>([]);

    const [searchEntityValue, setSearchEntityValue] = useState<string>('');
    const [searchJurisdictionValue, setSearchJurisdictionValue] = useState<string>('');
    const [searchPeriodValue, setSearchPeriodValue] = useState<string>('');
    const [searchCaseValue, setSearchCaseValue] = useState<string>('');

    const [selectedFolderFilter, setSelectedFolderFilter] = useState<IFilterItem | null>();
    const folderFilterName = 'folderName';
    const entityFilterName = 'entityName';
    const jurisdictionFilterName = 'jurisdiction';
    const periodFilterName = 'period';
    const caseFilterName = 'case';

    const entityFilterTabs = useMemo((): FilterTabDefinition[] => {
        return [
            {
                label: t('entity'),
                items: entities,
                checkedItems: checkedEntityItems,
                isSingleSelect: false,
                noElementsFoundtext: t('noEntitiesFound'),
                setCheckedItems: setCheckedEntityItems,
            },
            {
                label: t('entityGroups'),
                items: entityGroups,
                checkedItems: checkedEntityGroupItems,
                isSingleSelect: true,
                noElementsFoundtext: t('noEntityGroupsFound'),
                setCheckedItems: setCheckedEntityGroupItems,
            },
        ];
    }, [t, entities, checkedEntityItems, entityGroups, checkedEntityGroupItems]);

    const isFilteredByFolder = useMemo(() => {
        return !!selectedFolderFilter;
    }, [selectedFolderFilter]);

    const clearAllFilters = () => {
        clearHighlightedElements();
        setCheckedEntityItems([]);
        setCheckedEntityGroupItems([]);
        setCheckedJurisdictionItems([]);
        setCheckedPeriodItems([]);
        setCheckedCaseItems([]);

        setSearchCaseValue('');
        setSearchEntityValue('');
        setSearchJurisdictionValue('');
        setSearchPeriodValue('');
        setSelectedFolderFilter(null);
        clearSearch(grid);
    };

    useEffect(() => {
        let allEntityFilters: IFilterItem[] = [];
        if (checkedEntityItems?.length) {
            allEntityFilters = allEntityFilters.concat(checkedEntityItems);
        }
        if (checkedEntityGroupItems?.length) {
            allEntityFilters = allEntityFilters.concat(checkedEntityGroupItems);
        }
        const columnFilters = {
            [folderFilterName]: selectedFolderFilter ? [selectedFolderFilter] : [],
            [entityFilterName]: allEntityFilters,
            [jurisdictionFilterName]: checkedJurisdictionItems,
            [periodFilterName]: checkedPeriodItems,
            [caseFilterName]: checkedCaseItems,
        };
        setColumnFilters(columnFilters);
    }, [
        checkedEntityGroupItems,
        checkedCaseItems,
        checkedEntityItems,
        checkedJurisdictionItems,
        checkedPeriodItems,
        selectedFolderFilter,
        setColumnFilters,
    ]);

    const onFolderChange = useCallback(
        (filteredItems: IFilterItem[], filterName: string) => {
            setSelectedFolderFilter(filteredItems[0]);
        },
        [setSelectedFolderFilter]
    );

    const removeSelectedReturnsFromFolder = async () => {
        const selectedReturns = getReportsToExport().selectedTaxReturns ?? [];

        await deleteReturnsFromFolder({
            data: {
                folderKey: parseInt(selectedFolderFilter?.key ?? '0'),
                keys: selectedReturns.map((r) => r.id ?? 0),
            },
        });
    };

    if (isFilteredByFolder) {
        const updatedFilterItem = folders.find((folder) => folder.key === selectedFolderFilter?.key);

        if (updatedFilterItem && updatedFilterItem.label !== selectedFolderFilter?.label) {
            setSelectedFolderFilter(updatedFilterItem);
        }
    }

    return (
        <div className={filterPanelStyle}>
            <Stack horizontal className={headerStyles} tokens={{ childrenGap: 15 }}>
                <FunctionalAccessDisableWrapper hasFunctionalAccess={functionalAccess?.folderDropdownEnabled}>
                    <FoldersListFilter
                        folders={folders}
                        isFiltered={isFilteredByFolder}
                        filterName={folderFilterName}
                        label='allCalculations'
                        selectedFolderFilter={selectedFolderFilter}
                        showSingleSelect={true}
                        onFolderChange={onFolderChange}
                        disabled={disabled}
                        updateFolderList={updateFolderList}
                    />
                </FunctionalAccessDisableWrapper>

                <FunctionalAccessDisableWrapper
                    hasFunctionalAccess={functionalAccess?.folderDropdownEnabled && functionalAccess?.editFolderButtonEnabled}
                >
                    <CustomIconButton
                        label={t('addToFolder').toString()}
                        iconName='FolderArrowRight20Regular'
                        onButtonClick={toggleFolderModalVisibility}
                        disabled={disabled || disableActionButtons}
                    ></CustomIconButton>
                </FunctionalAccessDisableWrapper>
                {isFilteredByFolder && (
                    <FunctionalAccessDisableWrapper hasFunctionalAccess={functionalAccess?.editFolderButtonEnabled}>
                        <CustomIconButton
                            label={t('removeFromFolder').toString()}
                            iconName='Dismiss20Filled'
                            onButtonClick={showRemoveFromFolderConfirmation}
                            disabled={disabled || disableActionButtons}
                        ></CustomIconButton>
                    </FunctionalAccessDisableWrapper>
                )}

                <Stack horizontal className={rightAlignButtons}>
                    <CartButton getReportsToExport={getReportsToExport} disabled={disabled || disableActionButtons} />
                    <ExportReportButton
                        getReportsToExport={getReportsToExport}
                        disabled={disabled || disableActionButtons}
                        includeExportList={true}
                    ></ExportReportButton>
                </Stack>
            </Stack>
            <Stack className={breakStackStyle}></Stack>
            <Stack horizontal className={filterBarStyle}>
                <FilterGroup
                    clearAllFiltersLabel={t('filterBarClearAllFilters')}
                    isFiltered={isFiltered || isSearchValueApplied}
                    clearAllFilters={clearAllFilters}
                >
                    <TabFilterButton
                        filterName={entityFilterName}
                        tabs={entityFilterTabs}
                        label='entityOrEntityGroups'
                        searchValue={searchEntityValue}
                        setSearchValue={setSearchEntityValue}
                        disabled={disabled}
                    />
                    <FilterButton
                        filterName={jurisdictionFilterName}
                        items={jurisdictions}
                        checkedItems={checkedJurisdictionItems}
                        setCheckedItems={setCheckedJurisdictionItems}
                        searchValue={searchJurisdictionValue}
                        setSearchValue={setSearchJurisdictionValue}
                        noElementsFoundtext={t('noJurisdictionsFound')}
                        disabled={disabled}
                    />
                    <FilterButton
                        filterName={periodFilterName}
                        items={periods}
                        checkedItems={checkedPeriodItems}
                        setCheckedItems={setCheckedPeriodItems}
                        searchValue={searchPeriodValue}
                        setSearchValue={setSearchPeriodValue}
                        noElementsFoundtext={t('noPeriodsFound')}
                        disabled={disabled}
                    />
                    <FilterButton
                        filterName={caseFilterName}
                        items={cases}
                        checkedItems={checkedCaseItems}
                        setCheckedItems={setCheckedCaseItems}
                        searchValue={searchCaseValue}
                        setSearchValue={setSearchCaseValue}
                        noElementsFoundtext={t('noCasesFound')}
                        disabled={disabled}
                    />
                    <GridSearchBar searchBoxPlaceholderText={t('search')} />
                </FilterGroup>
                {isCreateNewReturnEnabled && (
                    <Stack className={addNewReturnButtonStyle}>
                        <FunctionalAccessDisableWrapper hasFunctionalAccess={functionalAccess?.createReportsButtonEnabled}>
                            <PrimaryButton
                                allowDisabledFocus
                                text={t('addNew').toString()}
                                onClick={() => {
                                    openAddReturnSidebarHandler && openAddReturnSidebarHandler();
                                }}
                                role='button'
                                id='addNewReturnButton'
                                className={addNewReturnButtonStyle}
                                iconProps={{ iconName: 'DocumentAdd20Regular' }}
                            ></PrimaryButton>
                        </FunctionalAccessDisableWrapper>
                    </Stack>
                )}
            </Stack>
            <AddToFolderModal
                isOpen={isFolderModalVisible}
                folders={folderItems ?? []}
                selectedReturns={selectedReturns}
                isLoadingGetAllFolders={isLoadingGetAllFolders}
                getAllFoldersQuery={getAllFoldersQuery}
                onClose={toggleFolderModalVisibility}
            ></AddToFolderModal>
            <ConfirmationDialog
                show={removeFromFolderConfirmationVisible}
                content={t('removeFromFolderDialogContent')}
                heading={t('removeFromFolderDialogHeading')}
                filterActionBarProps={{
                    primaryButtonLabel: 'remove',
                    primaryHandler: removeSelectedReturnsFromFolder,
                    primaryButtonProcessing: isLoadingDeleteReturnsFromFolder,
                    primaryButtonProcessingLabel: 'removing',
                    secondaryButtonLabel: 'cancel',
                    secondaryHandler: hideRemoveFromFolderConfirmation,
                    secondaryButtonDisabled: isLoadingDeleteReturnsFromFolder,
                }}
                onDismiss={hideRemoveFromFolderConfirmation}
            ></ConfirmationDialog>
        </div>
    );
};

export default FilterBar;

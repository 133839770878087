/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetJournalEntriesParams, GetJournalEntriesResponse, ProblemDetails } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetJournalEntriesHook = () => {
    const getJournalEntries = useCustomInstance<GetJournalEntriesResponse>();

    return (params?: GetJournalEntriesParams, signal?: AbortSignal) => {
        return getJournalEntries({ url: `/Amount/GetJournalEntries`, method: 'get', params, signal });
    };
};

export const getGetJournalEntriesQueryKey = (params?: GetJournalEntriesParams) =>
    [`/Amount/GetJournalEntries`, ...(params ? [params] : [])] as const;

export const useGetJournalEntriesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetJournalEntriesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetJournalEntriesQueryKey(params);

    const getJournalEntries = useGetJournalEntriesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>> = ({ signal }) =>
        getJournalEntries(params, signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetJournalEntriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>>;
export type GetJournalEntriesQueryError = ErrorType<ProblemDetails>;

export const useGetJournalEntries = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>,
    TError = ErrorType<ProblemDetails>
>(
    params?: GetJournalEntriesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJournalEntriesHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetJournalEntriesQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

import { QueryCellInfoEventArgs, SearchEventArgs } from '@syncfusion/ej2-react-grids';
import { useState } from 'react';
import { highlightSubstringWithClass } from '../utils/TableUtils';

export const useHighlightSearchedValues = <T>(getValue: (data: T, fieldName: string) => string) => {
    const [searchKey, setSearchKey] = useState('');
    const [hasMatch, setHasMatch] = useState<boolean>(false);
    const getCurrentSearchKey = () => searchKey;

    const storeSearchValueToHighlight = (args: SearchEventArgs) => {
        if (args.requestType === 'searching') {
            setSearchKey(args.searchString ? args.searchString.toLowerCase() : '');
        }
    };

    const clearSearch = () => {
        setSearchKey('');
    };

    const highlightSearchValueOnRender = (args: QueryCellInfoEventArgs) => {
        if (args.cell && args.data && searchKey !== '') {
            const columnField = args.column?.field ?? '';
            const data = args.data as T;
            const cellContent = getValue(data, columnField);
            const parsedContent = cellContent.toString().toLowerCase();
            setHasMatch(false);
            if (args.column && args.column.allowSearching && parsedContent.includes(searchKey.toLowerCase())) {
                const substringStartIndex = parsedContent.indexOf(searchKey);
                const searchedString = cellContent.toString().substring(substringStartIndex, substringStartIndex + searchKey.length);
                const shouldSearchDeepestChild = args.column.template !== undefined;
                setHasMatch(true);
                highlightSubstringWithClass(args.cell, searchedString, 'highlighted-search', shouldSearchDeepestChild);
            }
        }
    };

    return { getCurrentSearchKey, storeSearchValueToHighlight, highlightSearchValueOnRender, clearSearch, hasMatch };
};

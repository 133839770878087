import {
    DirectionalHint,
    ICalloutContentStyles,
    ICalloutProps,
    IPalette,
    ISearchBoxStyles,
    ITooltipHostProps,
    ITooltipProps,
    ITooltipStyles,
    mergeStyles,
    TooltipOverflowMode,
} from '@fluentui/react';
import { ICustomPalette } from '../theme/ICustomPalette';

export const getTooltipHostProps: (
    directionalHint?: DirectionalHint,
    overflowMode?: TooltipOverflowMode,
    palette?: ICustomPalette
) => Partial<ITooltipHostProps> = (directionalHint?: DirectionalHint, overflowMode?: TooltipOverflowMode, palette?: ICustomPalette) => {
    return {
        directionalHint: directionalHint,
        overflowMode: overflowMode,
        tooltipProps: {
            styles: { content: { color: 'white' } } as ITooltipStyles,
            calloutProps: {
                beakWidth: 9,
                styles: {
                    beak: { backgroundColor: palette?.neutralDarker },
                    beakCurtain: { backgroundColor: palette?.neutralDarker, borderRadius: '4px' },
                    calloutMain: { backgroundColor: palette?.neutralDarker },
                } as ICalloutContentStyles,
            } as ICalloutProps,
        } as ITooltipProps,
    } as ITooltipHostProps;
};

export const getCalloutProps: (palette?: ICustomPalette) => ICalloutProps = (palette?: ICustomPalette) => {
    return {
        beakWidth: 9,
        styles: {
            beak: { backgroundColor: palette?.neutralDarker },
            beakCurtain: { backgroundColor: palette?.neutralDarker, borderRadius: '4px' },
            calloutMain: {
                backgroundColor: palette?.neutralDarker,
                color: palette?.themeLight,
                borderRadius: '4px',
                padding: '5px 12px 7px 12px',
                lineHeight: '16px',
            },
        } as ICalloutContentStyles,
        // calloutWidth: 200,
        // calloutMinWidth: 200,
    } as ICalloutProps;
};

export const getSearcherStyles = (customPalette: ICustomPalette, palette: IPalette) =>
    mergeStyles({
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '4px 0px 4px 0px ',
        width: '510px',
        borderRadius: '8px',
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
        outline: 'none',
        color: palette.black,
        borderColor: customPalette.statusCoolLightGrey,
        ':hover': {
            borderColor: customPalette.neutralLightGrey,
        },
        ':focus': {
            borderColor: customPalette.blueLight,
        },
    });

export const getDefaultSearchBoxStyles = (customPalette: ICustomPalette, palette: IPalette): ISearchBoxStyles => ({
    root: {
        color: customPalette.whiteTranslucent40,
        '&.ms-SearchBox.is-active:after': {
            // eslint-disable-next-line no-template-curly-in-string
            border: '1px solid ${(props) => props.customPalette.themePrimary} !important',
            borderRadius: '8px !important',
        },
    },
    icon: { color: palette.black },
});

export const defaultDropDownStyles = mergeStyles({
    borderRadius: '4px !important',
    borderColor: 'lightgray !important',
});

export const enabledDropDownStyles = mergeStyles({
    boxShadow: '0px 2px 1px lightgray !important',
    ':focus': {
        boxShadow: '0 0 0 1px #003b5c !important',
    },
});

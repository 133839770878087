import { useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, ITooltipHostProps, TooltipHost } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getTooltipHostProps } from '../../../utils/CustomStyles';
import IconTabButton from '../../common/IconTabButton';
interface ActionBarButtonsProps {
    isReportActive: boolean;
    handleOpenPathToPortal: () => void;
    navigateToReport: () => void;
    hasForms: boolean;
    isLoading: boolean;
}

const ActionBarButtons: React.FC<ActionBarButtonsProps> = ({
    isReportActive,
    handleOpenPathToPortal,
    navigateToReport,
    hasForms,
    isLoading,
}) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { getCurrentFormPageUrl } = useFormPdfView();
    const url = getCurrentFormPageUrl();

    const tooltipHostProps: ITooltipHostProps = {
        ...getTooltipHostProps(DirectionalHint.topCenter, undefined, customPalette),
        content: t('NoFormsAreAvailable').toString(),
    };

    const disableFormsTab = !hasForms || isLoading;

    const formsTab = (
        <IconTabButton
            label={t('form').toString()}
            ariaLabel={!hasForms && !isLoading ? t('NoFormsAreAvailable').toString() : t('form').toString()}
            iconName='Form20Regular'
            activeIconName='Form20Filled'
            onButtonClick={(e: any) => {
                e.preventDefault();
                handleOpenPathToPortal();
            }}
            buttonKey='viewFormTabButton'
            url={url}
            isActive={!isReportActive}
            disabled={disableFormsTab}
            role='link'
        />
    );

    return (
        <>
            <IconTabButton
                label={t('report').toString()}
                iconName='DocumentBulletList20Regular'
                activeIconName='DocumentBulletList20Filled'
                onButtonClick={() => {
                    navigateToReport();
                }}
                buttonKey='reportTabButton'
                isActive={isReportActive}
            />
            {hasForms ? formsTab : <TooltipHost {...tooltipHostProps}>{formsTab}</TooltipHost>}
        </>
    );
};

export default ActionBarButtons;

import { useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, Icon, IconButton, ITooltipHostProps, Stack, TooltipHost } from '@fluentui/react';
import { Card, CardHeader, CardPreview, makeStyles, tokens } from '@fluentui/react-components';
import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonIndividualReportTableHeaderStyles } from '../../../hooks/useCommonIndividualReportTableHeaderStyles';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getTooltipHostProps } from '../../../utils/CustomStyles';

export interface IAddReturnCardProps {
    taxReturnItemName: string;
    calculationKey: number;
    calculationName: string;
    calculationDescription: string;
    entityKey: number;
    jurisdictionCode: string;
    caseCode: string;
    taxYearName: string;
    onEditReturn: () => void;
    onDeleteReturn: () => void;
}

const useStyles = makeStyles({
    flexContainerStyles: {
        display: 'flex !important',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
    },
    flexContainerRowStyles: {
        flexDirection: 'row',
        gap: '12px',
    },
    flexContainerColumnStyles: {
        flexDirection: 'column',
        gap: '4px',
    },
    growingFlexItemStyles: {
        flexGrow: 1,
    },
    cardStyles: {
        padding: '0px 12px 12px 12px',
    },
    cardHeaderContainerStyles: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    cardHeaderTextStyles: {
        fontSize: '14px',
        fontWeight: 600,
    },
    cardContentTextStyles: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
    },
    lightTextStyles: {
        color: tokens.colorNeutralForeground3,
    },
    cardIconButtonStyle: {
        position: 'relative',
        top: '-4px',
        ':focus': {
            outline: `-webkit-focus-ring-color auto 1px`,
        },
    },
});

const AddReturnCard = forwardRef(
    (
        {
            taxReturnItemName,
            calculationKey,
            calculationName,
            calculationDescription,
            entityKey,
            jurisdictionCode,
            caseCode,
            taxYearName,
            onEditReturn,
            onDeleteReturn,
        }: IAddReturnCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const { t } = useTranslation();
        const { customPalette } = useCorptaxTheme<IAppTheme>();
        const { iconButton, iconWrapper } = useCommonIndividualReportTableHeaderStyles();
        const {
            flexContainerStyles,
            flexContainerColumnStyles,
            flexContainerRowStyles,
            growingFlexItemStyles,
            cardStyles,
            cardHeaderContainerStyles,
            cardHeaderTextStyles,
            cardContentTextStyles,
            lightTextStyles,
            cardIconButtonStyle,
        } = useStyles();

        const deleteTooltipHostEditProps: Partial<ITooltipHostProps> = getTooltipHostProps(
            DirectionalHint.topCenter,
            undefined,
            customPalette
        );
        deleteTooltipHostEditProps.content = t('deleteCalculation').toString();
        deleteTooltipHostEditProps.id = 'deleteCalculation';

        const editTooltipHostEditProps: Partial<ITooltipHostProps> = getTooltipHostProps(
            DirectionalHint.topCenter,
            undefined,
            customPalette
        );
        editTooltipHostEditProps.content = t('editCalculation').toString();
        editTooltipHostEditProps.id = 'editCalculation';

        const handleKeyDown = (event: any) => {
            const buttons = document.getElementsByName(`card-button-${calculationKey}-${entityKey}`);
            let currentIndex = -1;
            buttons.forEach((element, index) => {
                if (element === document.activeElement) {
                    currentIndex = index;
                }
            });
            if (event.key === 'ArrowRight') {
                const nextIndex = (currentIndex + 1) % buttons.length;
                buttons[nextIndex].focus();
                event.preventDefault();
            }

            if (event.key === 'ArrowLeft') {
                const nextIndex = (currentIndex - 1 + buttons.length) % buttons.length;
                buttons[nextIndex].focus();

                event.preventDefault();
            }
        };

        return (
            <Card ref={ref} tabIndex={-1} data-testid={`return-card-${calculationName}`} onKeyDown={handleKeyDown}>
                <CardHeader
                    header={
                        <div className={cardHeaderContainerStyles}>
                            <h3 className={cardHeaderTextStyles}>{taxReturnItemName}</h3>
                            <div style={{ display: 'flex' }}>
                                <TooltipHost {...editTooltipHostEditProps}>
                                    <IconButton
                                        tabIndex={-1}
                                        data-testid='edit-calculation-icon'
                                        name={`card-button-${calculationKey}-${entityKey}`}
                                        className={`${iconButton} ${cardIconButtonStyle}`}
                                        ariaLabel={t('editCalculation').toString()}
                                        allowDisabledFocus
                                        onClick={onEditReturn}
                                        role='button'
                                    >
                                        <Stack className={iconWrapper}>
                                            <Icon iconName='Edit20Regular' />
                                        </Stack>
                                    </IconButton>
                                </TooltipHost>
                                <TooltipHost {...deleteTooltipHostEditProps}>
                                    <IconButton
                                        tabIndex={-1}
                                        data-testid='remove-calculation-icon'
                                        name={`card-button-${calculationKey}-${entityKey}`}
                                        className={`${iconButton} ${cardIconButtonStyle}`}
                                        aria-label={t('deleteCalculation').toString()}
                                        allowDisabledFocus
                                        onClick={onDeleteReturn}
                                        role='button'
                                    >
                                        <Stack className={iconWrapper}>
                                            <Icon iconName='Delete20Regular' />
                                        </Stack>
                                    </IconButton>
                                </TooltipHost>
                            </div>
                        </div>
                    }
                ></CardHeader>
                <CardPreview className={cardStyles}>
                    <div className={`${flexContainerStyles} ${flexContainerRowStyles}`}>
                        <div className={`${flexContainerStyles} ${flexContainerColumnStyles} ${growingFlexItemStyles}`}>
                            <label className={`${cardContentTextStyles} ${lightTextStyles}`}>{t('formOrCalculation')}</label>
                            <label className={cardContentTextStyles}>{calculationDescription}</label>
                        </div>
                        <div className={`${flexContainerStyles} ${flexContainerColumnStyles} `}>
                            <label className={`${cardContentTextStyles} ${lightTextStyles}`}>{t('jurisdiction')}</label>
                            <label className={cardContentTextStyles}>{jurisdictionCode}</label>
                        </div>
                        <div className={`${flexContainerStyles} ${flexContainerColumnStyles}`}>
                            <label className={`${cardContentTextStyles} ${lightTextStyles}`}>{t('context')}</label>
                            <label className={cardContentTextStyles}>
                                {caseCode}, {taxYearName}
                            </label>
                        </div>
                    </div>
                </CardPreview>
            </Card>
        );
    }
);

export default AddReturnCard;

import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { IGroupedTaxReturnsToCreate } from '../data-types/IGroupedTaxReturnsToCreate';
import { Calculation } from '../model';

export const unselectCalculationIfNotPresent = (
    calculationId: string,
    selectedCalculations: Calculation[],
    groupedTaxReturns: IGroupedTaxReturnsToCreate[],
    removeCalculation: (calculationName: string) => void
) => {
    if (!groupedTaxReturns.some((item) => item.taxReturnsToCreate.some((taxReturn) => taxReturn.calculation.id === calculationId))) {
        const calculationToRemove = selectedCalculations.find((calculation) => calculation.id === calculationId);

        if (calculationToRemove) {
            removeCalculation(calculationToRemove.name ?? '');
        }
    }
};

export const unselectElementFromMultiselect = <T extends object>(
    multiselect: MultiSelectComponent | null,
    filterFunction: (item: T) => boolean,
    setStateFunction: (newState: T[]) => void
) => {
    if (multiselect) {
        const currentValue = multiselect.value as T[];
        const newValue = currentValue.filter(filterFunction);

        if (newValue.length === 0) {
            multiselect.clear();
        } else {
            multiselect.value = newValue;
        }

        multiselect.refresh();
        setStateFunction(newValue);
    }
};

export const unselectAllElementsFromMultiselect = (multiselect: MultiSelectComponent | null) => {
    if (multiselect && (multiselect.value?.length ?? 0) > 0) {
        multiselect.clear();
        multiselect.refresh();
    }
};

import { Label, mergeStyles, mergeStyleSets } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DropDownListComponent, FieldSettingsModel } from '@syncfusion/ej2-react-dropdowns';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCalendarPeriods, useGetCases } from '../../../api/context/context';
import { TaxReturnItemRecord } from '../../../model';
import { defaultDropDownStyles, enabledDropDownStyles } from '../../../utils/CustomStyles';
import Modal, { IModalButtonProps } from '../../common/Modal';
import CustomTextField from '../../common/TextField';
import { ShowableTaxReturnItemRecord } from './AddReturnPanel';
import AdvancedEntityOptions from './AdvancedEntityOptions';

export interface EditReturnModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedReturn: ShowableTaxReturnItemRecord | undefined;
    onSubmit: (value: TaxReturnItemRecord | undefined) => void;
}

interface IReadOnlyTextField {
    label: string;
    value: string;
}

const labelStyles = mergeStyles({
    fontWeight: 'normal',
    marginTop: 5,
});

const ReadOnlyTextField = ({ label, value }: IReadOnlyTextField) => (
    <>
        <Label className={labelStyles}>{label}</Label>
        <CustomTextField
            id={label}
            ariaLabel={label}
            placeholder={label}
            value={value}
            resizable={false}
            readOnly
            multiline
            selectAllOnFocus={false}
        />
    </>
);

interface IReadOnlyDropDown {
    label: string;
    value: any;
    data: any;
    fields: FieldSettingsModel | undefined;
}

const ReadOnlyDropDown = ({ label, value, fields, data }: IReadOnlyDropDown) => (
    <>
        <Label className={labelStyles}>{label}</Label>
        <DropDownListComponent cssClass={defaultDropDownStyles} disabled dataSource={[data]} readonly fields={fields} value={value} />
    </>
);

const REPORT_NAME_MAX_LENGTH = 128;

const EditReturnModal: React.FC<EditReturnModalProps> = ({ isOpen, onClose, selectedReturn, onSubmit }) => {
    const { t } = useTranslation();
    const [taxReturnData, setTaxReturnData] = useState<ShowableTaxReturnItemRecord | undefined>({});
    const { data: cases }: { data: any } = useGetCases();
    const { data: taxYears }: { data: any } = useGetCalendarPeriods();
    const [returnName, setReturnName] = useState<string>('');
    const [hasChanges, { setTrue: setHasChangesTrue, setFalse: setHasChangesFalse }] = useBoolean(false);

    useEffect(() => {
        setReturnName(
            `${taxReturnData?.entityCode} ${taxReturnData?.calculationName} ${taxReturnData?.taxReturnItemDescription} ${t(
                'case'
            ).toString()} ${taxReturnData?.caseCode}, ${taxReturnData?.taxYearName}`
        );
    }, [taxReturnData]);

    useEffect(() => {
        if (selectedReturn) setTaxReturnData(cloneDeep(selectedReturn));
    }, [selectedReturn]);

    const styles = mergeStyleSets({
        flexContainer: {
            display: 'flex',
        },
        columnContainer: {
            flexDirection: 'column',
            flex: 1,
        },
        gap20Container: {
            gap: 20,
        },
        gap40Container: {
            gap: 40,
        },
        mainContentContainer: {
            flex: 0.6,
            minHeight: 552,
        },
        secondaryContentContainer: {
            flex: 0.4,
            marginTop: '5px',
        },
    });

    const onChangeDescription = (value: string | undefined) => {
        const valueWithoutBreakLines = value?.replace(/\n/g, '') ?? '';

        const updatedReturnName = `${taxReturnData?.entityCode} ${taxReturnData?.calculationName} ${value} ${t('case').toString()} ${
            taxReturnData?.caseCode
        }, ${taxReturnData?.taxYearName}`;

        if (updatedReturnName.length > REPORT_NAME_MAX_LENGTH) {
            return;
        }

        setTaxReturnData({ ...taxReturnData, taxReturnItemName: updatedReturnName, taxReturnItemDescription: valueWithoutBreakLines });
        setHasChangesTrue();
    };

    const onChangeCase = (ev: any) => {
        setTaxReturnData({ ...taxReturnData, taxReturnItemCaseKey: ev.target.value, caseCode: ev.target.itemData.code });
        setHasChangesTrue();
    };

    const onChangePeriod = (ev: any) => {
        setTaxReturnData({ ...taxReturnData, taxReturnItemPeriodKey: ev.target.value, taxYearName: ev.target.itemData.name });
        setHasChangesTrue();
    };

    const bodyContent = (
        <div className={`${styles.flexContainer} ${styles.gap40Container}`}>
            <div className={`${styles.flexContainer} ${styles.columnContainer} ${styles.mainContentContainer}`}>
                <ReadOnlyTextField label={t('reportName').toString()} value={returnName || ''} />

                <Label className={labelStyles}>{t('description').toString()}</Label>
                <CustomTextField
                    id='description'
                    ariaLabel={t('description').toString()}
                    placeholder={t('description').toString()}
                    value={taxReturnData?.taxReturnItemDescription || ''}
                    onChange={(_, value) => onChangeDescription(value)}
                    resizable={false}
                    multiline
                    selectAllOnFocus={false}
                />

                <ReadOnlyDropDown
                    label={t('formOrCalculation').toString()}
                    fields={{ text: 'calculationDescription', value: 'calculationKey' }}
                    data={taxReturnData}
                    value={taxReturnData?.calculationKey}
                />

                <ReadOnlyDropDown
                    label={t('entityOrEntityGroup').toString()}
                    fields={{ text: 'entityLabel', value: 'entityKey' }}
                    data={taxReturnData}
                    value={taxReturnData?.entityKey}
                />

                <ReadOnlyDropDown
                    label={t('jurisdiction').toString()}
                    fields={{ text: 'jurisdictionCode', value: 'jurisdictionCode' }}
                    data={taxReturnData}
                    value={taxReturnData?.jurisdictionCode}
                />

                <div className={`${styles.flexContainer} ${styles.gap20Container}`}>
                    <div className={`${styles.flexContainer} ${styles.columnContainer}`}>
                        <Label className={labelStyles}>{t('case').toString()}</Label>
                        <DropDownListComponent
                            value={taxReturnData?.taxReturnItemCaseKey}
                            cssClass={`${defaultDropDownStyles} ${enabledDropDownStyles}`}
                            dataSource={cases}
                            fields={{ text: 'name', value: 'key' }}
                            onChange={onChangeCase}
                        />
                    </div>
                    <div className={`${styles.flexContainer} ${styles.columnContainer}`}>
                        <Label className={labelStyles}>{t('taxYear').toString()}</Label>
                        <DropDownListComponent
                            value={taxReturnData?.taxReturnItemPeriodKey}
                            cssClass={`${defaultDropDownStyles} ${enabledDropDownStyles}`}
                            dataSource={taxYears}
                            fields={{ text: 'name', value: 'key' }}
                            onChange={onChangePeriod}
                        />
                    </div>
                </div>
            </div>
            <div className={`${styles.secondaryContentContainer}`}>
                <AdvancedEntityOptions setHasChanges={setHasChangesTrue} taxReturnData={taxReturnData} setTaxReturn={setTaxReturnData} />
            </div>
        </div>
    );

    const submit = async () => {
        if (taxReturnData) {
            taxReturnData.taxReturnItemName = returnName;

            onSubmit(taxReturnData);
        }

        setHasChangesFalse();
        onClose();
    };

    const clearStateAndClose = () => {
        setTaxReturnData(cloneDeep(selectedReturn));
        setHasChangesFalse();
        onClose();
    };

    const buttonProps: IModalButtonProps[] = [
        {
            text: t('save'),
            primary: true,
            isProcessing: false,
            disabled: !hasChanges,
            isSmall: true,
            onClick: submit,
        },
        {
            text: t('close'),
            primary: false,
            isProcessing: false,
            disabled: false,
            isSmall: true,
            onClick: clearStateAndClose,
        },
    ];

    return (
        <Modal
            headerContent={t(`editCalculation`).toString()}
            bodyContent={bodyContent}
            buttons={buttonProps}
            isOpen={isOpen}
            onClose={clearStateAndClose}
        />
    );
};

export default EditReturnModal;

import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, IButtonStyles, IconButton, ITooltipHostProps, mergeStyles, TooltipHost } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getTooltipHostProps } from '../../../utils/CustomStyles';
import { IconName } from '../../../utils/Icons.constants';
import FormTOCSidebarSearch from './FormTOCSidebarSearch';
import FormTOCTreeView from './FormTOCTreeView';

export interface IFormTOCSidebarProps {}
export const TOC_SIDEBAR_WIDTH = 400;
export const CLOSED_TOC_SIDEBAR_WIDTH = 50;

const focusStyle = {
    ':focus': {
        border: '1px solid black',
    },
};

const FormTOCSidebar: React.FC<IFormTOCSidebarProps> = () => {
    const [isDrawerExpanded, { toggle: toggleExpandDrawer, setFalse: hideDrawer, setTrue: showDrawer }] = useBoolean(true);
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { t } = useTranslation();
    const { tocData, showPdf, currentPage, isDrawerPinned, togglePinDrawer, setNodesExpanded } = useFormPdfView();
    const treeViewRef: any = useRef(null);
    const [filteredFolders, setFilteredFolders] = useState(tocData?.folders || []);

    const handleEscPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && isDrawerExpanded && !isDrawerPinned) {
            hideDrawer();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscPress);
        return () => {
            window.removeEventListener('keydown', handleEscPress);
        };
    }, [isDrawerExpanded, isDrawerPinned]);

    const scrollToCurrentPage = () => {
        if (treeViewRef.current && currentPage.pageId) {
            const node = treeViewRef.current?.element?.querySelector(`[data-uid="${currentPage.pageId}${currentPage.pageName}"]`);
            if (node) {
                node.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    const defaultIconStyles: IButtonStyles = {
        root: {
            width: '30px',
            color: customPalette.themePrimary,
            ...focusStyle,
        },
    };

    const togglePinDrawerStyles: IButtonStyles = {
        root: {
            width: '30px',
            color: customPalette.themePrimary,
            borderRadius: 5,
            marginRight: 5,
            backgroundColor: isDrawerPinned ? customPalette.disabledGrey : corptaxCustomLightTheme.colorNeutralBackground1,
            ...focusStyle,
        },
    };

    const expandedHeaderContentClass = mergeStyles({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    });

    const expandedHeaderContainerClass = mergeStyles({
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'column',
    });

    useEffect(() => {
        if (isDrawerExpanded) scrollToCurrentPage();
    }, [isDrawerExpanded, currentPage.pageId]);

    useEffect(() => {
        if (showPdf) {
            showDrawer();
        } else {
            hideDrawer();
        }
    }, [showPdf]);

    const collapsedContentClass = mergeStyles({ display: 'flex', flexDirection: 'column', alignItems: 'center' });

    const titleClass = mergeStyles({ marginLeft: '20px', textAlign: 'center' });

    const collapsedButtonStyles: IButtonStyles = {
        root: {
            width: '40px',
            height: '50px',
            marginBottom: 10,
            color: customPalette.themePrimary,
            ...focusStyle,
        },
        icon: {
            fontSize: '20px',
        },
    };

    const formsTextClass = mergeStyles({
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
        padding: '0px',
        textAlign: 'center',
        color: customPalette.neutralDark,
        fontSize: '12px',
        fontWeight: '600',
    });

    const toggleDrawerButtonIconName: IconName = isDrawerExpanded ? 'ChevronDoubleRightRegular' : 'ChevronDoubleLeftRegular';
    const toggleDrawerAriaLabel = isDrawerExpanded ? t('collapseToc') : t('expandToc');
    const togglePinDrawerAriaLabel = isDrawerPinned ? t('unpinToc') : t('pinToc');
    const pinButtonIconName: IconName = isDrawerPinned ? 'Pin16Filled' : 'Pin16Regular';

    interface IIconTooltipProps {
        ariaLabel: string;
        style?: IButtonStyles;
        iconName: string;
        action: () => void;
        testId: string;
    }

    const IconTooltip = ({ ariaLabel, style = defaultIconStyles, iconName, action, testId }: IIconTooltipProps) => {
        const tooltipHostProps: ITooltipHostProps = {
            ...getTooltipHostProps(DirectionalHint.topCenter, undefined, customPalette),
            content: ariaLabel,
        };

        return (
            <TooltipHost {...tooltipHostProps}>
                <IconButton data-testid={testId} styles={style} ariaLabel={ariaLabel} iconProps={{ iconName }} onClick={action} />
            </TooltipHost>
        );
    };

    const expandAll = () => {
        treeViewRef.current?.expandAll();
        setNodesExpanded(true);
    };

    const collapseAll = () => {
        treeViewRef.current?.collapseAll();
        setNodesExpanded(false);
    };

    return (
        <SidebarComponent
            id='dockSidebar'
            data-testid='dockSidebar'
            animate={false}
            enableDock={true}
            closeOnDocumentClick={!isDrawerPinned}
            style={{ backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1 }}
            dockSize={CLOSED_TOC_SIDEBAR_WIDTH}
            width={TOC_SIDEBAR_WIDTH}
            close={hideDrawer}
            open={showDrawer}
            isOpen={isDrawerExpanded}
            target='.pdf-container'
            type='Over'
            position='Right'
        >
            {isDrawerExpanded ? (
                <div style={{ width: '100%' }}>
                    <div className={expandedHeaderContainerClass}>
                        <div className={expandedHeaderContentClass}>
                            <h3 className={titleClass}>{t('forms')}</h3>
                            <div>
                                <IconTooltip
                                    ariaLabel={t('expandAllForms')}
                                    iconName='AddSquare20Regular'
                                    style={defaultIconStyles}
                                    action={expandAll}
                                    testId='expand-folder-button'
                                />
                                <IconTooltip
                                    ariaLabel={t('collapseAllForms')}
                                    iconName='SubtractSquare20Regular'
                                    style={defaultIconStyles}
                                    action={collapseAll}
                                    testId='collapse-folder-button'
                                />
                                <IconTooltip
                                    ariaLabel={togglePinDrawerAriaLabel}
                                    iconName={pinButtonIconName}
                                    style={togglePinDrawerStyles}
                                    action={togglePinDrawer}
                                    testId='pin-button'
                                />
                                <IconButton
                                    data-testid='collapse-button'
                                    disabled={isDrawerPinned}
                                    styles={defaultIconStyles}
                                    ariaLabel={toggleDrawerAriaLabel}
                                    iconProps={{ iconName: toggleDrawerButtonIconName }}
                                    onClick={toggleExpandDrawer}
                                />
                            </div>
                        </div>
                        <FormTOCSidebarSearch setFilteredData={setFilteredFolders} total={filteredFolders?.length} expandAll={expandAll} />
                    </div>

                    <FormTOCTreeView filteredFolders={filteredFolders} noRecordsMessage={t('noRecordsToDisplay')} />
                </div>
            ) : (
                <div className={collapsedContentClass}>
                    <IconButton
                        data-testid='expand-button'
                        styles={collapsedButtonStyles}
                        ariaLabel={toggleDrawerAriaLabel}
                        iconProps={{ iconName: toggleDrawerButtonIconName }}
                        onClick={toggleExpandDrawer}
                    />
                    <span className={formsTextClass}>{t('forms')}</span>
                </div>
            )}
        </SidebarComponent>
    );
};

export default FormTOCSidebar;

import { MessageBarType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { cloneDeep } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetFormTOC, useViewerFormPDF } from '../api/report/report';
import { useFeedbackMessages } from '../hooks/useFeedbackMessages';
import { FormFolder, FormPage, FormTOC, ReviewFormDataRequest } from '../model';
import { getFormPagesAndParentId } from '../utils/PdfViewerUtils';
import { convertBlobToBase64 } from '../utils/ReportUtils';
import { PINNED_SEARCH_PARAM_NAME, VIEW_REPORT_URL } from '../utils/Urls.constant';

interface SelectedEntity {
    id: number;
    index: number;
}

export interface IFormPdfViewerContextProps {
    showPdf: boolean;
    tocData: FormTreeItem | null | undefined;
    pdfData: string | null;
    isLoading: boolean;
    isLoadingToc: boolean;
    loadPdfPage: (pages: FormPage[], parentId: string, pageUniqueId: string, entity?: SelectedEntity | undefined) => void;
    loadTocData: () => void;
    openForm: () => void;
    navigateToReport: () => void;
    clearFormData: () => void;
    currentPage: CurrentPageState;
    currentFolder: FormFolder | null;
    onPageChange: (page: any) => any;
    currentFormTotalPages: number;
    isDrawerPinned: boolean;
    togglePinDrawer: () => void;
    hasForms: boolean;
    pdfSettings: PdfSettings;
    setPdfSetting: (key: string, value: any) => void;
    setNodesExpanded: (expanded: boolean, node?: any) => void;
    getCurrentFormPageUrl: (pageId?: string, pageName?: string) => string;
    setCurrentPage: (page: CurrentPageState) => void;
}

export const FormPdfViewContext = createContext<IFormPdfViewerContextProps | null>(null);

interface IFormPdfProviderProps {
    children: any;
    returnKey: number;
    pageId?: string | undefined;
    pageName?: string | undefined;
    value?: IFormPdfViewerContextProps;
    initialTocData?: FormTreeItem | null;
}
export interface FormTreePage extends FormPage {
    selected?: boolean;
}

export interface FormTreeFolder extends FormFolder {
    expanded?: boolean;
    pages?: FormTreePage[] | null | undefined;
}
export interface FormTreeItem extends FormTOC {
    folders?: FormTreeFolder[] | null | undefined;
}
export interface CurrentPageState {
    parentId?: string | null;
    pageIndex?: number | null;
    pageId?: string | null;
    pageName?: string | null;
    entity?: SelectedEntity | undefined;
    pageTitle?: string | null;
}

export interface PdfSettings {
    currentZoom: number;
}

const initialPdfSettings: PdfSettings = {
    currentZoom: 100,
};

export const getSelectedEntityFromPath = (searchParams: URLSearchParams): SelectedEntity | undefined => {
    const id = searchParams.get('entity');
    const index = searchParams.get('index');

    if (id !== null && index !== null) {
        return {
            id: parseInt(id),
            index: parseInt(index),
        };
    }
    return undefined;
};

export function FormPdfViewProvider({ children, returnKey, pageId, pageName, value, initialTocData = null }: IFormPdfProviderProps) {
    const [showPdf, setShowPdf] = useState(pageId && pageName ? true : false);
    const [pdfData, setPdfData] = useState<string | null>(null);
    const [tocData, setTocData] = useState<FormTreeItem | null>(initialTocData);
    const [currentFolder, setCurrentFolder] = useState<FormFolder | null>(null);
    const [currentPage, setCurrentPage] = useState<CurrentPageState>({});
    const [currentFormTotalPages, setCurrentFormTotalPages] = useState<number>(1);
    const [pdfSettings, setPdfSettings] = useState<PdfSettings>(initialPdfSettings);
    const navigate = useNavigate();
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const isDrawerPinned = searchParams.get(PINNED_SEARCH_PARAM_NAME) === 'true';
    const [hasForms, { setTrue: setHasFormsTrue, setFalse: setHasFormsFalse }] = useBoolean(false);
    const { t } = useTranslation();
    const { displayFeedbackMessage } = useFeedbackMessages();

    const { mutateAsync: viewFormPdfMutation, isLoading: isLoadingPdf } = useViewerFormPDF({
        mutation: {
            onSuccess: (data) => {
                convertBlobToBase64(data)
                    .then((data: any) => {
                        setPdfData(data);
                    })
                    .catch(() => {
                        handleError();
                    });
            },
            onError: () => {
                handleError();
            },
        },
    });

    const handleError = () => {
        displayFeedbackMessage({
            type: MessageBarType.error,
            message: t('pdfDisplayError'),
        });
        navigateToReport();
    };

    const clearFormData = () => {
        setShowPdf(false);
        setPdfData(null);
        setTocData(null);
        setCurrentPage({});
    };

    useEffect(() => {
        const formRouteRegex = new RegExp(`^${VIEW_REPORT_URL}\\d+/forms/?(.*)$`);
        const isFormRoute = formRouteRegex.test(location.pathname);

        if (isFormRoute) {
            setShowPdf(true);
        } else {
            setShowPdf(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (currentPage.pageId && currentPage.pageName && showPdf) {
            navigateToPage(currentPage.pageId, currentPage.pageName, currentPage.entity);
        }
    }, [currentPage, showPdf]);

    const getCurrentFormPageUrl = (pageId?: string, pageName?: string): string => {
        if (pageId && pageName) {
            return `${VIEW_REPORT_URL}${returnKey}/forms/${pageId}/${pageName}`;
        }
        if (currentPage?.pageId && currentPage.pageName) {
            return `${VIEW_REPORT_URL}${returnKey}/forms/${currentPage.pageId}/${currentPage.pageName}`;
        }
        return `${VIEW_REPORT_URL}${returnKey}/forms`;
    };

    const navigateToPage = (pageId: string | undefined, pageName: string | null | undefined, entity?: SelectedEntity | undefined) => {
        let selectedEntity = entity?.id ? entity : currentPage.entity;

        if (selectedEntity?.id) {
            searchParams.set('entity', selectedEntity.id.toString());
            searchParams.set('index', selectedEntity.index?.toString());
        }

        navigate(
            { pathname: `${VIEW_REPORT_URL}${returnKey}/forms/${pageId}/${pageName}`, search: searchParams.toString() },
            { replace: true }
        );
    };

    const openForm = () => {
        searchParams = new URLSearchParams();
        if (currentPage?.pageId && currentPage.pageName) {
            navigateToPage(currentPage.pageId, currentPage.pageName, currentPage.entity);
        } else {
            navigate(`${VIEW_REPORT_URL}${returnKey}/forms`);
        }
    };

    const navigateToReport = () => {
        navigate(`${VIEW_REPORT_URL}${returnKey}`);
    };

    const { mutateAsync: getFormTOC, isLoading: isLoadingToc } = useGetFormTOC({
        mutation: {
            onSuccess: (data, request) => {
                const page = pageId && pageName ? { id: pageId, name: pageName, uniqueId: `${pageId}${pageName}` } : data.defaultPage;
                const entity = getSelectedEntityFromPath(searchParams);

                updateCurrentPageOnTree(data, page?.uniqueId, entity);
                const returnKey = request.data.taxReturnItemTOCKey;

                if ((data.defaultPage || (pageId && pageName)) && returnKey) {
                    setHasFormsTrue();

                    const { formPages } = getFormPagesAndParentId(data, pageId || data.defaultPage?.id);
                    setCurrentFormTotalPages(formPages.length);

                    viewFormPdfMutation({
                        data: getFormRequestData(formPages, entity),
                    });
                }
            },
        },
    });

    const clearCurrentPageOnTree = (toc: FormTreeItem) => {
        toc.folders?.forEach((folder) => {
            folder?.pages?.forEach((page) => {
                page.selected = false;
            });
        });
    };

    const updateCurrentPage = (page: FormPage, folder: FormFolder, entity?: SelectedEntity) => {
        const pathEntity = getSelectedEntityFromPath(searchParams);

        const updatedPage = {
            ...currentPage,
            pageIndex: page.index || 0,
            parentId: folder.id,
            pageId: page.id,
            pageName: page.name,
            pageTitle: page.title,
        };

        if (entity || pathEntity) updatedPage.entity = entity || pathEntity;

        setCurrentPage(updatedPage);
    };

    const setNodesExpanded = (expanded: boolean, node: any) => {
        if (node) {
            const folderNode = tocData?.folders?.find((folder) => folder.id === node.id);
            if (folderNode) folderNode.expanded = node.expanded;
        } else {
            tocData?.folders?.forEach((folder) => {
                folder.expanded = expanded;
            });
        }

        setTocData({ ...tocData });
    };

    const updateCurrentPageOnTree = (
        tocTreeItem: FormTreeItem | null,
        uniqueId: string | null | undefined,
        entity?: SelectedEntity | undefined
    ) => {
        if (tocTreeItem) {
            const toc: FormTreeItem = cloneDeep(tocTreeItem);

            clearCurrentPageOnTree(toc);

            if (uniqueId) {
                const folder = toc?.folders?.find((folder) => folder.pages?.some((page) => page.uniqueId === uniqueId));

                if (folder) {
                    setCurrentFolder(folder);
                    const page = folder.pages?.find((page) => page.uniqueId === uniqueId);

                    if (page) {
                        page.selected = true;
                        folder.expanded = true;
                        updateCurrentPage(page, folder, entity);
                    }
                }
            }

            setTocData(toc);
        }
    };

    const getFormRequestData = (formPages: any[], entity?: SelectedEntity) => {
        const requestData: ReviewFormDataRequest = {
            returnKey: returnKey,
            formPageKeys: formPages,
        };

        if (entity) {
            requestData.columnId = entity.id;
            requestData.columnIndex = entity.index;
        }

        return requestData;
    };

    const loadPdfPage = (pages: FormPage[], parentId: string, pageUniqueId: string, entity?: SelectedEntity | undefined) => {
        entity = entity || currentPage.entity;

        updateCurrentPageOnTree(tocData, pageUniqueId, entity);

        const { index, id, name, title } = pages.find((page) => page.uniqueId === pageUniqueId)!;

        if (parentId === currentPage.parentId && entity === currentPage.entity) {
            setCurrentPage({ ...currentPage, pageIndex: index || 0, pageId: id, pageName: name, pageTitle: title });
            return;
        }

        const formPages = pages.map((page) => {
            return {
                formPageId: page.id,
                formPageName: page.name,
            };
        });
        setCurrentFormTotalPages(formPages.length);

        viewFormPdfMutation({
            data: getFormRequestData(formPages, entity),
        });
    };

    const loadTocData = () => {
        setHasFormsFalse();
        getFormTOC({ data: { taxReturnItemTOCKey: returnKey } });
    };

    const onPageChange = (page: any) => {
        const selectedPage: any = tocData?.folders?.find((folder) => folder.id === currentPage.parentId)?.pages?.[
            page.currentPageNumber - 1
        ];

        if (selectedPage) {
            updateCurrentPageOnTree(tocData, selectedPage.uniqueId, currentPage.entity);
        }
    };

    const togglePinDrawer = () => {
        if (isDrawerPinned) {
            searchParams.delete(PINNED_SEARCH_PARAM_NAME);
        } else {
            searchParams.set(PINNED_SEARCH_PARAM_NAME, 'true');
        }

        navigate({ ...location, search: searchParams.toString() }, { replace: true });
    };

    const setPdfSetting = (key: string, value: any) => {
        setPdfSettings({ ...pdfSettings, [key]: value });
    };

    return (
        <FormPdfViewContext.Provider
            value={
                value ?? {
                    showPdf,
                    pdfData,
                    tocData,
                    isLoading: isLoadingPdf || isLoadingToc,
                    isLoadingToc,
                    loadTocData,
                    loadPdfPage,
                    openForm,
                    navigateToReport,
                    clearFormData,
                    currentPage,
                    currentFolder,
                    onPageChange,
                    currentFormTotalPages,
                    isDrawerPinned,
                    togglePinDrawer,
                    hasForms,
                    pdfSettings,
                    setPdfSetting,
                    setNodesExpanded,
                    getCurrentFormPageUrl,
                    setCurrentPage,
                }
            }
        >
            {children}
        </FormPdfViewContext.Provider>
    );
}

export default FormPdfViewProvider;

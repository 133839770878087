import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import {
    Announced,
    DirectionalHint,
    FocusTrapZone,
    IButton,
    IButtonStyles,
    IconButton,
    mergeStyleSets,
    MessageBarType,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Inject, MultiSelectComponent, SelectEventArgs, VirtualScroll } from '@syncfusion/ej2-react-dropdowns';

import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { EventArgs, SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { debounce, sortBy } from 'lodash';
import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCalendarPeriods, useGetCases } from '../../../api/context/context';
import { useGetCalculations, useGetEntitiesAndEntityGroups, useSaveTaxReturns } from '../../../api/report/report';
import { IGroupedTaxReturnsToCreate } from '../../../data-types/IGroupedTaxReturnsToCreate';
import { ITaxReturnToCreate } from '../../../data-types/ITaxReturnToCreate';
import { useFeedbackMessages } from '../../../hooks/useFeedbackMessages';
import {
    Calculation,
    EntityGroupRecord,
    TaxReturnEntity,
    TaxReturnItemRecord,
    TaxReturnSaveRecordsRequest,
    TaxReturnSaveRecordsResponse,
} from '../../../model';
import { IAppTheme } from '../../../theme/IAppTheme';
import {
    unselectAllElementsFromMultiselect,
    unselectCalculationIfNotPresent,
    unselectElementFromMultiselect,
} from '../../../utils/AddReturnUtils';
import { getTooltipHostProps } from '../../../utils/CustomStyles';
import ConditionalVisibility from '../../common/ConditionalVisibility';
import CustomIconButton from '../../common/IconButton';
import IconTabButton from '../../common/IconTabButton';
import { SpinnerButton } from '../../common/SpinnerButton';
import AddReturnCardGroup from './AddReturnCardGroup';
import EditReturnModal from './EditReturnModal';

export interface IAddReturnPanelProps {
    isPanelOpen: boolean;
    handleClose: () => void;
    onSuccess?: () => void;
}

export interface ShowableTaxReturnItemRecord extends TaxReturnItemRecord {
    jurisdictionCode?: string | null | undefined;
    taxYearName?: string | null | undefined;
    entityCode?: string | null | undefined;
    entityLabel?: string | null | undefined;
    calculationName?: string | null | undefined;
    calculationDescription?: string | null | undefined;
    caseCode?: string | null | undefined;
    updateColumnFileKey?: number | null | undefined;
}

interface IEntitiesOrEntityGroupsToCreate {
    entities: TaxReturnEntity[];
    entityGroups: EntityGroupRecord[];
}
const AddReturnPanel: React.FC<IAddReturnPanelProps> = ({ isPanelOpen, handleClose, onSuccess }) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { mutateAsync: saveTaxReturns, isLoading: isLoadingSaveTaxReturn } = useSaveTaxReturns();
    const { displayFeedbackMessage } = useFeedbackMessages();
    const closeButton = React.useRef<IButton>(null);
    const { data: calculationData } = useGetCalculations();
    const { data: entityOrEntityGroupData } = useGetEntitiesAndEntityGroups();
    const [calculations, setCalculations] = React.useState<Calculation[]>([]);
    const [selectedEntitiesOrEntityGroup, setSelectedEntitiesOrEntityGroups] = React.useState<IEntitiesOrEntityGroupsToCreate>({
        entities: [],
        entityGroups: [],
    });
    const [groupedTaxReturnsToCreate, setGroupedTaxReturnsToCreate] = React.useState<IGroupedTaxReturnsToCreate[]>([]);

    const hasCards = groupedTaxReturnsToCreate.length > 0 && groupedTaxReturnsToCreate.some((item) => item.taxReturnsToCreate.length > 0);
    const [isEntitiesTabSelected, { setTrue: setIsEntitiesTabSelected, setFalse: clearIsEntitiesTabSelected }] = useBoolean(true);
    const [isEditReturnModalOpen, { setTrue: openEditReturnModal, setFalse: closeEditReturnModal }] = useBoolean(false);
    const [editingReturn, setEditingReturn] = useState<ShowableTaxReturnItemRecord | undefined>(undefined);
    const { data: cases } = useGetCases();
    const { data: taxYears } = useGetCalendarPeriods();

    const defaultCase = cases && cases.length > 0 ? cases[0] : undefined;

    const multiSelectCalculation = React.useRef<MultiSelectComponent>(null);
    const multiSelectEntity = React.useRef<MultiSelectComponent>(null);
    const multiSelectEntityGroup = React.useRef<MultiSelectComponent>(null);
    const entityFields = { text: 'entityName', value: 'entityId' };
    const entityGroupsFields = { text: 'groupName', value: 'groupId' };
    const calculationsDataManager = new DataManager(calculationData);
    const entityDataManager = new DataManager(entityOrEntityGroupData?.entities!);
    const entityGroupDataManager = new DataManager(entityOrEntityGroupData?.entityGroups!);

    const [announced, setAnnounced] = React.useState<JSX.Element | undefined>(undefined);
    const [announcedForEntityCard, setAnnouncedForEntityCard] = React.useState<JSX.Element | undefined>(undefined);

    const getRequestData = (): TaxReturnSaveRecordsRequest => {
        const taxReturnItemRecords: TaxReturnItemRecord[] = [];
        groupedTaxReturnsToCreate.forEach((groupedTaxReturn) => {
            groupedTaxReturn.taxReturnsToCreate.forEach((taxReturnToCreate) => {
                taxReturnItemRecords.push({
                    taxReturnItemName: taxReturnToCreate.taxReturnItemName,
                    taxReturnItemDescription: taxReturnToCreate.taxReturnItemDescription,
                    entityKey: groupedTaxReturn.entityKey,
                    calculationKey: taxReturnToCreate.calculation.calculationKey,
                    taxReturnItemCaseKey: taxReturnToCreate.caseKey,
                    taxReturnItemPeriodKey: taxReturnToCreate.periodKey,
                    jurisdictionKey: taxReturnToCreate.calculation.jurisdictionKey,
                    columnFileKey: taxReturnToCreate.columnFileKey,
                    rangeFileKey: taxReturnToCreate.rangeFileKey,
                    includeAllBU: taxReturnToCreate.includeAllBU,
                    ignoreEntityGroup: taxReturnToCreate.ignoreEntityGroup,
                    updateColumnFileKey: taxReturnToCreate.updateColumnFileKey,
                    updateBack: taxReturnToCreate.updateBack,
                    updateBackReport: taxReturnToCreate.updateBackReport,
                });
            });
        });

        return {
            taxReturnItemRecords: taxReturnItemRecords,
        };
    };

    const onSave = () => {
        saveTaxReturns({ data: getRequestData() }).then((response: TaxReturnSaveRecordsResponse) => {
            if (!response.hasErrors) {
                displayFeedbackMessage({ type: MessageBarType.success, message: t('calcAdded') });
                onSuccess && onSuccess();
            }
            if (response.hasErrors) {
                displayFeedbackMessage({ type: MessageBarType.error, message: t('calcNotAdded') });
            }
        });
    };

    const handleCleanAndClose = () => {
        clearSelectionEntityGroup();
        clearSelectionCalculation();
        clearSelectionEntities();
        setGroupedTaxReturnsToCreate([]);
        setIsEntitiesTabSelected();
        handleClose();
    };

    const getReportsTableStyles = () =>
        mergeStyleSets({
            sidebarContainerStyles: {
                height: '-webkit-fill-available',
                backgroundColor: corptaxCustomLightTheme.colorNeutralBackground1,
                padding: '28px 24px 20px 24px',
            },
            sidebarCloseButtonStyles: {
                border: 'none',
                color: customPalette.greenDark,
                cursor: 'pointer',
                selectors: {
                    ':hover': {
                        color: '#000',
                    },
                    ':focus': {
                        outline: '-webkit-focus-ring-color auto 1px !important',
                    },
                },
            },
            sidebarHeaderContentStyles: {
                justifyContent: 'space-between',
            },
            sidebarTitleStyles: {
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '28px',
                margin: '0 0 8px 0',
            },
            sidebarsubTitleStyles: {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px',
                margin: '0 0 8px 0',
            },
            sidebarFooterContainerStyles: {
                justifyContent: 'flex-end',
                padding: '10px 0',
                marginTop: 'auto',
            },
            sidebarFormItemStyles: {
                '.e-multi-select-wrapper': {
                    maxHeight: '150px',
                    overflow: 'auto',
                },
            },
            itemsTemplateContainerStyles: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            },
            itemsHeaderTemplateContainerStyles: {
                padding: '7px 0px',
            },
            itemTemplateStyles: {
                paddingRight: '2px',
                flex: 1,
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                fontWeight: '400',
            },
            itemTemplateStretchStyles: {
                flexGrow: 2,
            },
            itemHeaderTemplateStyles: {
                padding: '0 10px',
                fontSize: '12px',
                lineHeight: '16px',
                fontWeight: '600',
                boxSizing: 'border-box',
            },
            nameColumnStyles: {
                maxWidth: '5rem',
            },
            jurisdictionColumnStyles: {
                maxWidth: '5.2rem',
            },
            scrollableSection: {
                overflow: 'auto',
            },
        });

    const {
        sidebarCloseButtonStyles,
        sidebarHeaderContentStyles,
        sidebarTitleStyles,
        sidebarContainerStyles,
        sidebarFooterContainerStyles,
        sidebarsubTitleStyles,
        sidebarFormItemStyles,
        itemsTemplateContainerStyles,
        itemsHeaderTemplateContainerStyles,
        itemHeaderTemplateStyles,
        itemTemplateStyles,
        itemTemplateStretchStyles,
        nameColumnStyles,
        jurisdictionColumnStyles,
        scrollableSection,
    } = getReportsTableStyles();

    const sidebarFooterButtonsStyles: IButtonStyles = {
        root: {
            padding: '10px',
            margin: '0 10px 0 0',
            ':focus': {
                outline: '-webkit-focus-ring-color auto 1px !important',
            },
            backgroundCololor: customPalette.themePrimary,
        },
    };

    const headerTemplate = () => (
        <span className={`${itemsHeaderTemplateContainerStyles} ${itemsTemplateContainerStyles}`}>
            <span className={`${itemTemplateStyles} ${nameColumnStyles} ${itemHeaderTemplateStyles}`}>{t('name')}</span>
            <span className={`${itemTemplateStyles} ${itemTemplateStretchStyles} ${itemHeaderTemplateStyles}`}>{t('description')}</span>
            <span className={`${itemTemplateStyles} ${itemHeaderTemplateStyles}`}>{t('filingType')}</span>
            <span className={`${itemTemplateStyles} ${jurisdictionColumnStyles} ${itemHeaderTemplateStyles}`}>{t('jurisdiction')}</span>
            <span style={{ width: '26px' }}></span>
        </span>
    );

    const itemTemplate = (item: Calculation) => {
        return (
            <span className={`multiselect-template ${itemsTemplateContainerStyles}`}>
                <span className={`${itemTemplateStyles} ${nameColumnStyles}`}>
                    <TooltipHost content={item.name!} {...getTooltipHostProps(DirectionalHint.leftCenter, undefined, customPalette)}>
                        {item.name}
                    </TooltipHost>
                </span>
                <span className={`${itemTemplateStyles} ${itemTemplateStretchStyles}`}>
                    <TooltipHost content={item.description!} {...getTooltipHostProps(DirectionalHint.leftCenter, undefined, customPalette)}>
                        {item.description}
                    </TooltipHost>
                </span>
                <span className={itemTemplateStyles}>
                    <TooltipHost
                        content={item.filingTypeDescription!}
                        {...getTooltipHostProps(DirectionalHint.leftCenter, undefined, customPalette)}
                    >
                        {item.filingTypeDescription}
                    </TooltipHost>
                </span>
                <span className={`${itemTemplateStyles} ${jurisdictionColumnStyles}`}>
                    <TooltipHost
                        content={item.jurisdictionCode!}
                        {...getTooltipHostProps(DirectionalHint.leftCenter, undefined, customPalette)}
                    >
                        {item.jurisdictionCode}
                    </TooltipHost>
                </span>
            </span>
        );
    };

    const EntityOrEntityGroupsTabs = () => {
        return (
            <span style={{ display: 'flex', marginBottom: '10px' }}>
                <IconTabButton
                    buttonKey={'entityTab'}
                    label={t('entity')}
                    isActive={isEntitiesTabSelected}
                    disabled={selectedEntitiesOrEntityGroup.entityGroups.length > 0}
                    onButtonClick={() => {
                        setIsEntitiesTabSelected();
                    }}
                    iconName={'wrapIcon'}
                    role='button'
                ></IconTabButton>
                <IconTabButton
                    buttonKey={'entityGroupsTab'}
                    label={t('entityGroups')}
                    isActive={!isEntitiesTabSelected}
                    disabled={selectedEntitiesOrEntityGroup.entities.length > 0}
                    onButtonClick={() => {
                        clearIsEntitiesTabSelected();
                    }}
                    iconName={'wrapIcon'}
                    role='button'
                ></IconTabButton>
            </span>
        );
    };

    const itemTemplateEntity = useCallback((item: any) => {
        const name = item.entityName ?? item.groupDescription;
        const code = item.entityCode ?? item.groupName;
        return `${code} - ${name}`;
    }, []);

    const destroyFocusTrapZoneOnEscape: KeyboardEventHandler<HTMLDivElement> = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            handleCleanAndClose();
        }
    };

    const calculationFields = { text: 'description', value: 'name' };

    const onFiltering = debounce((e: any) => {
        let query: Query = new Query();
        const predicates: Predicate[] = [
            new Predicate('name', 'contains', e.text, true),
            new Predicate('description', 'contains', e.text, true),
            new Predicate('jurisdictionCode', 'contains', e.text, true),
            new Predicate('filingTypeDescription', 'contains', e.text, true),
        ];

        query = e.text !== '' ? query.where(Predicate.or(predicates)) : query;
        e.updateData(calculationsDataManager, query);
    }, 400);

    const onFilteringEntity = debounce((e: any) => {
        let query = new Query();
        query = e.text !== '' ? query.where('entityName', 'contains', e.text, true) : query;
        e.updateData(entityDataManager, query);
    }, 400);

    const onFilteringEntityGroup = debounce((e: any) => {
        let query: Query = new Query();
        query = e.text !== '' ? query.where('groupName', 'contains', e.text, true) : query;
        e.updateData(entityGroupDataManager, query);
    }, 400);

    const createTaxReturnToCreate = (calculation: Calculation, entityCode: string): ITaxReturnToCreate => {
        const defaultTaxYear = taxYears?.find((taxYear) => taxYear.periodYear === calculation.taxYear);

        return {
            calculation: calculation,
            jurisdictionCode: 'US',
            caseKey: defaultCase?.key ?? 0,
            caseCode: defaultCase?.code ?? '',
            periodKey: defaultTaxYear?.key ?? 0,
            taxYearName: defaultTaxYear?.name ?? '',
            taxReturnItemName: `${entityCode} ${calculation.name} ${calculation.description} ${t('case').toString()} ${
                defaultCase?.code
            }, ${defaultTaxYear?.name}`,
            taxReturnItemDescription: `${calculation.description}`,
        };
    };

    const addCalculationCardToAllEntities = (
        currentState: IGroupedTaxReturnsToCreate[],
        newCalculation: Calculation
    ): IGroupedTaxReturnsToCreate[] => {
        currentState.forEach((item) => {
            const newArray = [...item.taxReturnsToCreate];
            newArray.push(createTaxReturnToCreate(newCalculation, item.entityCode));

            item.taxReturnsToCreate = sortBy(newArray, (item) => item.taxReturnItemName);
        });

        return currentState;
    };

    const removeCalculationCardFromAllEntities = (
        currentState: IGroupedTaxReturnsToCreate[],
        removedCalculation: Calculation
    ): IGroupedTaxReturnsToCreate[] => {
        const entityIdsToRemove: string[] = [];
        const existingCalculations = getAllCalculationsInUse();

        currentState.forEach((item) => {
            item.taxReturnsToCreate = item.taxReturnsToCreate.filter(
                (taxReturnToCreate) => taxReturnToCreate.calculation.id !== removedCalculation.id
            );

            if (existingCalculations.length > 1 && item.taxReturnsToCreate.length === 0) {
                if (isEntitiesTabSelected) {
                    updateEntitySelection(item.entityId);
                } else {
                    clearSelectionEntityGroup();
                }
                entityIdsToRemove.push(item.entityId);
            }
        });

        currentState = currentState.filter((item) => !entityIdsToRemove.includes(item.entityId));

        return currentState;
    };

    const updateCalculations = (selectedCalculations: Calculation[]) => {
        let stateCopy = [...groupedTaxReturnsToCreate];
        const calculationsToRemove = calculations.filter((calculation) => !selectedCalculations.includes(calculation));
        const calculationsToAdd = selectedCalculations.filter((calculation) => !calculations.includes(calculation));

        calculationsToRemove.forEach((removedCalculation) => {
            stateCopy = removeCalculationCardFromAllEntities(stateCopy, removedCalculation);
        });

        calculationsToAdd.forEach((newCalculation) => {
            stateCopy = addCalculationCardToAllEntities(stateCopy, newCalculation);
        });

        setGroupedTaxReturnsToCreate(stateCopy);
        setCalculations(selectedCalculations);
    };

    const getAllCalculationsInUse = (discardedEntity?: TaxReturnEntity) => {
        const allCalculationsInCards: string[] = groupedTaxReturnsToCreate
            .filter((groupedTaxReturn) => discardedEntity === undefined || groupedTaxReturn.entityName !== discardedEntity.entityName)
            .flatMap((groupedTaxReturn) => groupedTaxReturn.taxReturnsToCreate.map((taxReturn) => taxReturn.calculation.name!));

        return allCalculationsInCards;
    };

    const removeCalculationChipIfNecessary = (args: SelectEventArgs) => {
        if (selectedEntitiesOrEntityGroup.entities.length > 1) {
            const discardedEntity = args.itemData as TaxReturnEntity;
            const calculationsInUseForOtherEntities: string[] = getAllCalculationsInUse(discardedEntity);

            const currentlySelectedCalculations = (multiSelectCalculation.current?.value as Calculation[]) ?? [];

            currentlySelectedCalculations.forEach((selectedCalculation) => {
                if (!calculationsInUseForOtherEntities.includes(selectedCalculation.name!)) {
                    updateCalculationSelection(selectedCalculation.name!);
                }
            });
        }
    };

    const updateEntities = (selectedEntities: TaxReturnEntity[]) => {
        let stateCopy = [...groupedTaxReturnsToCreate];
        stateCopy = stateCopy.filter((item) => selectedEntities.some((selectedEntity) => selectedEntity.entityId === item.entityId));

        selectedEntities.forEach((entity) => {
            if (!stateCopy.some((item) => item.entityId === entity.entityId)) {
                stateCopy.push({
                    entityId: entity.entityId ?? '',
                    entityName: entity.entityName ?? '',
                    entityCode: entity.entityCode ?? '',
                    entityKey: entity.entityKey ?? 0,
                    taxReturnsToCreate: calculations.map((calculation) => createTaxReturnToCreate(calculation, entity.entityCode!)),
                });
            }
        });

        setGroupedTaxReturnsToCreate(sortBy(stateCopy, (item) => item.entityName));
        setSelectedEntitiesOrEntityGroups({
            entities: selectedEntities,
            entityGroups: [],
        });
    };

    const updateEntityGroups = (selectedEntityGroups: EntityGroupRecord[]) => {
        let stateCopy = [...groupedTaxReturnsToCreate];
        stateCopy = stateCopy.filter((item) =>
            selectedEntityGroups.some((selectedEntityGroup) => selectedEntityGroup.groupId === item.entityId)
        );

        selectedEntityGroups.forEach((entityGroup) => {
            if (!stateCopy.some((item) => item.entityId === entityGroup.groupId)) {
                stateCopy.push({
                    entityId: entityGroup.groupId ?? '',
                    entityName: entityGroup.groupDescription ?? '',
                    entityCode: entityGroup.groupName ?? '',
                    entityKey: entityGroup.groupKey ?? 0,
                    taxReturnsToCreate: sortBy(
                        calculations.map((calculation) => createTaxReturnToCreate(calculation, entityGroup.groupName!)),
                        (item) => item.taxReturnItemName
                    ),
                });
            }
        });

        setGroupedTaxReturnsToCreate(sortBy(stateCopy, (item) => item.entityName));
        setSelectedEntitiesOrEntityGroups({
            entities: [],
            entityGroups: selectedEntityGroups,
        });
    };

    const shouldPreventClose = (args: EventArgs) => {
        if (args.event && args.event.target) {
            const targetAsElement = args.event.target as HTMLElement;
            return targetAsElement.closest('.e-multi-select-list-wrapper') || targetAsElement.classList.contains('e-chips-close');
        }

        return false;
    };

    const clearGlobalEventListenerAndClose = (args: EventArgs) => {
        if (shouldPreventClose(args)) {
            args.cancel = true;
        } else {
            removeGlobalEscapeEventListener();
            handleClose();
        }
    };

    // Using callback here so we can remove this event listener when the component closes
    // This way, we keep the reference to the function and allows us to remove it.
    const globalEscapeEventListener = React.useCallback((ev: KeyboardEvent) => {
        if (ev.key === 'Escape') {
            handleCleanAndClose();
        }
    }, []);

    const setupGlobalEscapeEventListener = () => {
        document.addEventListener('keydown', globalEscapeEventListener);
    };

    const removeGlobalEscapeEventListener = () => {
        document.removeEventListener('keydown', globalEscapeEventListener);
    };

    const getUpdateTaxReturnToCreateObject = (
        previousValue: ITaxReturnToCreate,
        taxReturn: ShowableTaxReturnItemRecord
    ): ITaxReturnToCreate => {
        return {
            ...previousValue,
            taxReturnItemName: taxReturn.taxReturnItemName ?? '',
            taxReturnItemDescription: taxReturn.taxReturnItemDescription ?? '',
            caseKey: taxReturn.taxReturnItemCaseKey ?? 0,
            caseCode: taxReturn.caseCode ?? '',
            periodKey: taxReturn.taxReturnItemPeriodKey ?? 0,
            taxYearName: taxReturn.taxYearName ?? '',
            columnFileKey: taxReturn.columnFileKey,
            rangeFileKey: taxReturn.rangeFileKey,
            includeAllBU: taxReturn.includeAllBU,
            ignoreEntityGroup: taxReturn.ignoreEntityGroup,
            updateColumnFileKey: taxReturn.updateColumnFileKey,
            updateBack: taxReturn.updateBack,
            updateBackReport: taxReturn.updateBackReport,
        };
    };

    const onSubmitEditReturn = (editedData: ShowableTaxReturnItemRecord | undefined) => {
        if (!editedData) {
            return;
        }

        const stateCopy = [...groupedTaxReturnsToCreate];
        const editedEntityIndex = stateCopy.findIndex((groupedTaxReturn) => groupedTaxReturn.entityKey === editedData.entityKey);

        if (editedEntityIndex !== -1) {
            const returnIndex = stateCopy[editedEntityIndex]?.taxReturnsToCreate.findIndex(
                (returnToCreate) => returnToCreate.calculation.calculationKey === editedData.calculationKey
            );

            if (returnIndex !== -1) {
                stateCopy[editedEntityIndex].taxReturnsToCreate[returnIndex] = getUpdateTaxReturnToCreateObject(
                    stateCopy[editedEntityIndex].taxReturnsToCreate[returnIndex],
                    editedData
                );

                setGroupedTaxReturnsToCreate(stateCopy);
            }
        }
    };

    const onEditReturn = (taxReturn: ShowableTaxReturnItemRecord) => {
        setEditingReturn(taxReturn);
        openEditReturnModal();
    };

    const panelAccessibleName = `${t('addNewCalculation')} panel`;

    const updateCalculationSelection = (calculationName: string) => {
        unselectElementFromMultiselect<Calculation>(
            multiSelectCalculation.current,
            (item) => item.name !== calculationName,
            setCalculations
        );
    };

    const updateEntitySelection = (entityId: string) => {
        unselectElementFromMultiselect<TaxReturnEntity>(
            multiSelectEntity.current,
            (item) => item.entityId !== entityId,
            (newValue) => {
                setSelectedEntitiesOrEntityGroups({
                    entities: newValue,
                    entityGroups: [],
                });
            }
        );
    };

    const clearSelectionEntityGroup = () => {
        unselectAllElementsFromMultiselect(multiSelectEntityGroup.current);
        setSelectedEntitiesOrEntityGroups({
            entities: [],
            entityGroups: [],
        });
    };

    const clearEntityOrEntityGroupSelection = (entityId: string) => {
        if (isEntitiesTabSelected) {
            updateEntitySelection(entityId);
        } else {
            clearSelectionEntityGroup();
        }
    };

    const clearSelectionCalculation = () => {
        unselectAllElementsFromMultiselect(multiSelectCalculation.current);
        setCalculations([]);
    };

    const clearSelectionEntities = () => {
        unselectAllElementsFromMultiselect(multiSelectEntity.current);
        setSelectedEntitiesOrEntityGroups({
            entities: [],
            entityGroups: [],
        });
    };

    const getTaxReturnItemName = (entityIndex: number, calculationId: string): string | undefined => {
        return groupedTaxReturnsToCreate[entityIndex].taxReturnsToCreate.find((item) => item.calculation.id === calculationId)
            ?.taxReturnItemName;
    };

    const removeCard = (entityId: string, calculationId: string) => {
        const stateCopy = [...groupedTaxReturnsToCreate];
        const entityIndex = stateCopy.findIndex((item) => item.entityId === entityId);

        if (entityIndex > -1) {
            stateCopy[entityIndex].taxReturnsToCreate = stateCopy[entityIndex].taxReturnsToCreate.filter(
                (item) => item.calculation.id !== calculationId
            );

            if (stateCopy[entityIndex].taxReturnsToCreate.length === 0) {
                clearEntityOrEntityGroupSelection(entityId);

                stateCopy.splice(entityIndex, 1);
                setAnnouncedForEntityCard(<Announced message={`${stateCopy[entityIndex]?.entityName} Entity Card deleted`} />);
            }

            unselectCalculationIfNotPresent(calculationId, calculations, stateCopy, updateCalculationSelection);

            setGroupedTaxReturnsToCreate(stateCopy);
            setAnnounced(<Announced message={`${getTaxReturnItemName(entityIndex, calculationId)} Calculation Card item deleted`} />);
        }
    };

    return (
        <SidebarComponent
            id='addNewReturnDockSidebar'
            data-testid='addNewReturnDockSidebar'
            close={clearGlobalEventListenerAndClose}
            isOpen={isPanelOpen}
            position='Right'
            width={644}
            type='Over'
            showBackdrop={true}
            open={setupGlobalEscapeEventListener}
            closeOnDocumentClick={isEditReturnModalOpen ? false : true}
        >
            <FocusTrapZone
                disabled={!isPanelOpen}
                isClickableOutsideFocusTrap
                onKeyDownCapture={destroyFocusTrapZoneOnEscape}
                style={{
                    height: '100%',
                }}
            >
                <Stack className={sidebarContainerStyles}>
                    {isPanelOpen && <Announced message={panelAccessibleName} />}
                    <Stack horizontal className={sidebarHeaderContentStyles}>
                        <span className={sidebarTitleStyles}>{t('addNewCalculation')}</span>
                        <IconButton
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel={t('closePanel').toString()}
                            onClick={handleCleanAndClose}
                            className={sidebarCloseButtonStyles}
                            componentRef={closeButton}
                        />
                    </Stack>
                    <Stack className={scrollableSection} tokens={{ childrenGap: '10px' }}>
                        <Stack className={sidebarFormItemStyles}>
                            <span className={sidebarsubTitleStyles}>{t('formsOrCalculations')}</span>
                            <MultiSelectComponent
                                id='multiselect-calculations'
                                ref={multiSelectCalculation}
                                value={calculations}
                                allowObjectBinding={true}
                                onChange={(e: any) => {
                                    updateCalculations(e.value);
                                }}
                                changeOnBlur={false}
                                fields={calculationFields}
                                dataSource={calculationsDataManager}
                                placeholder={t('selectFormsAndCalculations').toString()}
                                showClearButton={true}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemTemplate}
                                filtering={onFiltering.bind(this)}
                                allowFiltering={true}
                                showSelectAll={false}
                                enableVirtualization={true}
                                closePopupOnSelect={false}
                                hideSelectedItem={false}
                                showDropDownIcon={true}
                                mode='Box'
                            >
                                <Inject services={[VirtualScroll]} />
                            </MultiSelectComponent>
                        </Stack>
                        <Stack>
                            <span className={sidebarsubTitleStyles}>{t('entityOrEntityGroups')}</span>
                            {EntityOrEntityGroupsTabs()}
                            <ConditionalVisibility isVisible={isEntitiesTabSelected}>
                                <MultiSelectComponent
                                    id='multiselect-entities'
                                    ref={multiSelectEntity}
                                    value={selectedEntitiesOrEntityGroup.entities}
                                    allowObjectBinding={true}
                                    key={'entityTab'}
                                    onChange={(e: any) => {
                                        updateEntities(e.value);
                                    }}
                                    fields={entityFields}
                                    dataSource={entityDataManager}
                                    placeholder={t('selectEntity').toString()}
                                    showClearButton={true}
                                    itemTemplate={itemTemplateEntity}
                                    filtering={onFilteringEntity.bind(this)}
                                    allowFiltering={true}
                                    showSelectAll={false}
                                    enableVirtualization={true}
                                    closePopupOnSelect={false}
                                    hideSelectedItem={false}
                                    showDropDownIcon={true}
                                    mode='Box'
                                    changeOnBlur={false}
                                    removed={removeCalculationChipIfNecessary}
                                >
                                    <Inject services={[VirtualScroll]} />
                                </MultiSelectComponent>
                            </ConditionalVisibility>
                            <ConditionalVisibility isVisible={!isEntitiesTabSelected}>
                                <MultiSelectComponent
                                    id='multiselect-entity-groups'
                                    ref={multiSelectEntityGroup}
                                    value={selectedEntitiesOrEntityGroup.entityGroups}
                                    allowObjectBinding={true}
                                    key={'entityGroupsTab'}
                                    onChange={(e: any) => {
                                        updateEntityGroups(e.value);
                                    }}
                                    fields={entityGroupsFields}
                                    dataSource={entityGroupDataManager}
                                    placeholder={t('selectEntityGroup').toString()}
                                    showClearButton={true}
                                    itemTemplate={itemTemplateEntity}
                                    filtering={onFilteringEntityGroup.bind(this)}
                                    allowFiltering={true}
                                    showSelectAll={false}
                                    enableVirtualization={true}
                                    closePopupOnSelect={false}
                                    hideSelectedItem={false}
                                    showDropDownIcon={true}
                                    maximumSelectionLength={1}
                                    mode='Box'
                                    changeOnBlur={false}
                                >
                                    <Inject services={[VirtualScroll]} />
                                </MultiSelectComponent>
                            </ConditionalVisibility>
                        </Stack>
                        {announcedForEntityCard}
                        {announced}
                        {groupedTaxReturnsToCreate.map((item: any, index: number) => {
                            return (
                                <AddReturnCardGroup
                                    index={index}
                                    key={item.entityName}
                                    groupedTaxReturnToCreate={item}
                                    onRemoveClicked={removeCard}
                                    onEditReturn={onEditReturn}
                                />
                            );
                        })}
                    </Stack>
                    <Stack horizontal className={sidebarFooterContainerStyles}>
                        <SpinnerButton
                            ariaLabel={t('add').toString()}
                            text={t('add').toString()}
                            onClick={onSave}
                            styles={sidebarFooterButtonsStyles}
                            disabled={!hasCards || isLoadingSaveTaxReturn}
                            isProcessing={isLoadingSaveTaxReturn}
                            processingText={t('add').toString()}
                        ></SpinnerButton>
                        <CustomIconButton
                            ariaLabel={t('close').toString()}
                            label={t('cancel').toString()}
                            styles={sidebarFooterButtonsStyles}
                            onButtonClick={handleCleanAndClose}
                        />
                    </Stack>
                </Stack>
            </FocusTrapZone>

            <EditReturnModal
                isOpen={isEditReturnModalOpen}
                selectedReturn={editingReturn}
                onClose={closeEditReturnModal}
                onSubmit={onSubmitEditReturn}
            />
        </SidebarComponent>
    );
};

export default AddReturnPanel;

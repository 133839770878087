import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { BaseButton, Button, IButtonStyles } from '@fluentui/react';
import React, { MouseEventHandler } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';
import { IconName } from '../../utils/Icons.constants';
import CustomIconButton from './IconButton';
export interface IIconTabButtonProps {
    buttonKey: string;
    buttonId?: string;
    label: string;
    ariaLabel?: string;
    iconName: IconName;
    activeIconName?: IconName;
    onButtonClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement>;
    isActive?: boolean;
    disabled?: boolean;
    url?: string;
    role?: string;
}

const IconTabButton: React.FC<IIconTabButtonProps> = ({
    buttonKey,
    buttonId,
    label,
    ariaLabel,
    iconName,
    activeIconName,
    onButtonClick,
    isActive,
    disabled,
    url,
    role,
}) => {
    const { palette, customPalette } = useCorptaxTheme<IAppTheme>();
    const iconTabButtonStyle: IButtonStyles = {
        root: {
            height: '44px',
            backgroundColor: palette.white,
            border: 'none',
            borderRadius: '0px',
            padding: '0px 10px',
            margin: '0 5px',
            borderBottom: isActive && !disabled ? '3px solid' : 'none',
            borderBottomColor: isActive && !disabled ? customPalette.themePrimary : palette.white,
            '> span > i': {
                margin: '0px',
                padding: '0px',
                color: isActive ? customPalette.themePrimary : customPalette.grey222222,
            },
        },
        rootHovered: {
            color: customPalette.grey222222,
            padding: '0px 10px',
            backgroundColor: palette.white,
            borderBottom: '3px solid',
            borderBottomColor: isActive && !disabled ? customPalette.themePrimary : customPalette.greyC3C5C6,
            '> span > i': {
                margin: '0px',
                padding: '0px',
                color: isActive ? customPalette.themePrimary : customPalette.grey222222,
            },
            '> span > span > span': {
                color: customPalette.grey222222 + '!important',
            },
        },
        rootPressed: {
            backgroundColor: palette.white,
        },
        rootDisabled: {
            backgroundColor: palette.white,
            '> span > i': {
                color: corptaxCustomLightTheme.colorNeutralForegroundDisabled,
            },
            '> span > span > span': {
                color: corptaxCustomLightTheme.colorNeutralForegroundDisabled,
            },
            selectors: {
                ':focus': {
                    border: `1px solid ${customPalette.themePrimary}`,
                },
            },
        },
        rootFocused: {
            border: `1px solid ${customPalette.themePrimary}`,
            borderBottom: '3px solid',
        },
    };

    return (
        <CustomIconButton
            key={buttonKey}
            id={buttonId}
            label={label}
            iconName={isActive ? activeIconName : iconName}
            onButtonClick={onButtonClick}
            isActive={isActive}
            ariaLabel={ariaLabel}
            disabled={disabled}
            styles={iconTabButtonStyle}
            url={url}
            role={role}
        />
    );
};

export default IconTabButton;

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { MutationFunction, QueryFunction, QueryKey, UseMutationOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { IActionResult, NavigationItem, NavigationItemPreference } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetNavigationItemsHook = () => {
    const getNavigationItems = useCustomInstance<NavigationItem[]>();

    return (signal?: AbortSignal) => {
        return getNavigationItems({ url: `/Navigation`, method: 'get', signal });
    };
};

export const getGetNavigationItemsQueryKey = () => [`/Navigation`] as const;

export const useGetNavigationItemsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetNavigationItemsQueryKey();

    const getNavigationItems = useGetNavigationItemsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>> = ({ signal }) =>
        getNavigationItems(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetNavigationItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>>;
export type GetNavigationItemsQueryError = ErrorType<unknown>;

export const useGetNavigationItems = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNavigationItemsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNavigationItemsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePutNavigationPreferenceItemHook = () => {
    const putNavigationPreferenceItem = useCustomInstance<IActionResult>();

    return (navigationItemPreference: NavigationItemPreference) => {
        return putNavigationPreferenceItem({
            url: `/Navigation`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: navigationItemPreference,
        });
    };
};

export const usePutNavigationPreferenceItemMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutNavigationPreferenceItemHook>>>,
        TError,
        { data: NavigationItemPreference },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutNavigationPreferenceItemHook>>>,
    TError,
    { data: NavigationItemPreference },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putNavigationPreferenceItem = usePutNavigationPreferenceItemHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePutNavigationPreferenceItemHook>>>,
        { data: NavigationItemPreference }
    > = (props) => {
        const { data } = props ?? {};

        return putNavigationPreferenceItem(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutNavigationPreferenceItemMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutNavigationPreferenceItemHook>>>
>;
export type PutNavigationPreferenceItemMutationBody = NavigationItemPreference;
export type PutNavigationPreferenceItemMutationError = ErrorType<unknown>;

export const usePutNavigationPreferenceItem = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutNavigationPreferenceItemHook>>>,
        TError,
        { data: NavigationItemPreference },
        TContext
    >;
}) => {
    const mutationOptions = usePutNavigationPreferenceItemMutationOptions(options);

    return useMutation(mutationOptions);
};

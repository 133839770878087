/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Review.Api
 * OpenAPI spec version: 1.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ICalendarPeriod, ICase, IEntity, IJurisdiction, ProblemDetails } from '../../model';
import type { ErrorType } from '../../utils/UseCustomInstance';
import { useCustomInstance } from '../../utils/UseCustomInstance';

export const useGetCasesHook = () => {
    const getCases = useCustomInstance<ICase[]>();

    return (signal?: AbortSignal) => {
        return getCases({ url: `/Context/GetCases`, method: 'get', signal });
    };
};

export const getGetCasesQueryKey = () => [`/Context/GetCases`] as const;

export const useGetCasesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetCasesQueryKey();

    const getCases = useGetCasesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>> = ({ signal }) => getCases(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetCasesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>>;
export type GetCasesQueryError = ErrorType<unknown>;

export const useGetCases = <TData = Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>, TError = ErrorType<unknown>>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCasesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetCalendarPeriodsHook = () => {
    const getCalendarPeriods = useCustomInstance<ICalendarPeriod[]>();

    return (signal?: AbortSignal) => {
        return getCalendarPeriods({ url: `/Context/GetCalendarPeriods`, method: 'get', signal });
    };
};

export const getGetCalendarPeriodsQueryKey = () => [`/Context/GetCalendarPeriods`] as const;

export const useGetCalendarPeriodsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetCalendarPeriodsQueryKey();

    const getCalendarPeriods = useGetCalendarPeriodsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>> = ({ signal }) =>
        getCalendarPeriods(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetCalendarPeriodsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>>;
export type GetCalendarPeriodsQueryError = ErrorType<unknown>;

export const useGetCalendarPeriods = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCalendarPeriodsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCalendarPeriodsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetEntitiesHook = () => {
    const getEntities = useCustomInstance<IEntity[]>();

    return (signal?: AbortSignal) => {
        return getEntities({ url: `/Context/GetEntities`, method: 'get', signal });
    };
};

export const getGetEntitiesQueryKey = () => [`/Context/GetEntities`] as const;

export const useGetEntitiesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>,
    TError = ErrorType<ProblemDetails>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetEntitiesQueryKey();

    const getEntities = useGetEntitiesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>> = ({ signal }) => getEntities(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetEntitiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>>;
export type GetEntitiesQueryError = ErrorType<ProblemDetails>;

export const useGetEntities = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>,
    TError = ErrorType<ProblemDetails>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEntitiesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetEntitiesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetJurisdictionsHook = () => {
    const getJurisdictions = useCustomInstance<IJurisdiction[]>();

    return (signal?: AbortSignal) => {
        return getJurisdictions({ url: `/Context/GetJurisdictions`, method: 'get', signal });
    };
};

export const getGetJurisdictionsQueryKey = () => [`/Context/GetJurisdictions`] as const;

export const useGetJurisdictionsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>,
    TError = ErrorType<ProblemDetails>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetJurisdictionsQueryKey();

    const getJurisdictions = useGetJurisdictionsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>> = ({ signal }) =>
        getJurisdictions(signal);

    return { queryKey, queryFn, ...queryOptions };
};

export type GetJurisdictionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>>;
export type GetJurisdictionsQueryError = ErrorType<ProblemDetails>;

export const useGetJurisdictions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>,
    TError = ErrorType<ProblemDetails>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJurisdictionsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetJurisdictionsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};

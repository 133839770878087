import { useCorptaxTheme } from '@corptax/react-components-common';
import { Announced, DirectionalHint, IconButton, ITooltipHostProps, Stack, TextField, TooltipHost } from '@fluentui/react';
import { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../contexts/useFormPdfView';
import { useCommonIndividualReportTableHeaderStyles } from '../../hooks/useCommonIndividualReportTableHeaderStyles';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import { IconName } from '../../utils/Icons.constants';
import { isValidIntermediateNumberInput } from '../../utils/NumberUtils';
import Icon from '../common/Icon';

export interface IPdfPageNavigationProps {
    pdfViewer?: PdfViewerComponent;
    isFirstForm: boolean;
    isLastForm: boolean;
    onNavigateToPreviousForm: (goToLastPage?: boolean) => void;
    onNavigateToNextForm: () => void;
}

export const PdfPageNavigation: React.FC<IPdfPageNavigationProps> = ({
    pdfViewer,
    isFirstForm,
    isLastForm,
    onNavigateToPreviousForm,
    onNavigateToNextForm,
}) => {
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const { currentPage, currentFormTotalPages, showPdf, currentFolder, isLoading } = useFormPdfView();
    const { iconButton, iconWrapper } = useCommonIndividualReportTableHeaderStyles();
    const currentPageNumber: number = (currentPage?.pageIndex || 0) + 1;

    const isFirstPage: boolean = currentPageNumber === 1;
    const isLastPage: boolean = currentPageNumber === currentFormTotalPages;

    const [currentPageInput, setCurrentPageInput] = useState<string>(currentPageNumber.toString());

    useEffect(() => {
        setCurrentPageInput(currentPageNumber.toString() || '');
    }, [currentPage]);

    const handleInputOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, inputValue?: string) => {
        if (!inputValue) {
            setCurrentPageInput('');
            return;
        }
        if (isValidIntermediateNumberInput(inputValue)) {
            setCurrentPageInput(inputValue);
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleNavigateToPage();
        }
    };

    const handleNavigateToPage = () => {
        const pageNumber: number = parseInt(currentPageInput);
        if (pageNumber >= 1 && pageNumber <= currentFormTotalPages) {
            pdfViewer?.navigation.goToPage(pageNumber);
            return;
        }

        setCurrentPageInput(currentPageNumber.toString());
    };

    const previousPage = () => {
        if (!isFirstPage) {
            pdfViewer?.navigation.goToPreviousPage();
        } else if (!isFirstForm) {
            onNavigateToPreviousForm(true);
        }
    };

    const nextPage = () => {
        if (!isLastPage) {
            pdfViewer?.navigation.goToNextPage();
        } else if (!isLastForm) {
            onNavigateToNextForm();
        }
    };

    const goToFirstPage = () => {
        if (!isFirstPage) {
            pdfViewer?.navigation.goToFirstPage();
        }
    };

    const goToLastPage = () => {
        if (!isLastPage) {
            pdfViewer?.navigation.goToLastPage();
        }
    };

    const getPageNumberAccesibilityText = (): string => {
        return `Page ${currentPageNumber} of ${currentFormTotalPages}`;
    };

    const tooltipHostNavigatePageProps: Partial<ITooltipHostProps> = getTooltipHostProps(
        DirectionalHint.topCenter,
        undefined,
        customPalette
    );
    tooltipHostNavigatePageProps.content = getPageNumberAccesibilityText();
    tooltipHostNavigatePageProps.id = 'goToPage';

    interface ITooltipIcon {
        labelTranslatorKey: string;
        ariaLabelTranslatorKey: string;
        icon: IconName;
        action: () => void;
        disabled: boolean;
    }

    const TooltipIcon = ({ labelTranslatorKey, ariaLabelTranslatorKey, icon, action, disabled }: ITooltipIcon) => {
        const tooltipHostDefaultProps: Partial<ITooltipHostProps> = getTooltipHostProps(
            DirectionalHint.topCenter,
            undefined,
            customPalette
        );

        tooltipHostDefaultProps.content = t(labelTranslatorKey).toString();
        tooltipHostDefaultProps.id = labelTranslatorKey;

        return (
            <TooltipHost {...tooltipHostDefaultProps}>
                <IconButton
                    className={iconButton}
                    ariaLabel={t(ariaLabelTranslatorKey).toString()}
                    disabled={disabled}
                    allowDisabledFocus
                    onClick={action}
                >
                    <Stack className={iconWrapper}>
                        <Icon iconName={icon} />
                    </Stack>
                </IconButton>
            </TooltipHost>
        );
    };

    const shouldDisableNextPageButton: boolean = (isLastPage && isLastForm) || isLoading;

    return (
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 5 }}>
            {showPdf && currentPage && currentPage.pageTitle && (
                <Announced
                    message={'Form: ' + currentFolder!.title + ' page: ' + currentPage.pageTitle}
                    styles={{
                        root: {
                            top: 0,
                            left: -2,
                            width: 1,
                            height: 1,
                            position: 'absolute',
                            overflow: 'hidden',
                        },
                    }}
                />
            )}
            <TooltipIcon
                labelTranslatorKey='firstPage'
                ariaLabelTranslatorKey='goToFirstPageForm'
                icon='ArrowPrevious20Regular'
                action={goToFirstPage}
                disabled={isFirstPage}
            />
            <TooltipIcon
                labelTranslatorKey='previousPage'
                ariaLabelTranslatorKey='previousPage'
                icon='ChevronLeft20Regular'
                action={previousPage}
                disabled={isFirstPage && isFirstForm}
            />
            <TooltipIcon
                labelTranslatorKey='nextPage'
                ariaLabelTranslatorKey='nextPage'
                icon='ChevronRight20Regular'
                action={nextPage}
                disabled={shouldDisableNextPageButton}
            />
            <TooltipIcon
                labelTranslatorKey='lastPage'
                ariaLabelTranslatorKey='goToLastPageForm'
                icon='ArrowNext20Regular'
                action={goToLastPage}
                disabled={isLastPage}
            />
            <TooltipHost {...tooltipHostNavigatePageProps}>
                <Stack horizontal verticalAlign='center'>
                    <TextField
                        value={currentPageInput}
                        onChange={handleInputOnChange}
                        ariaLabel={getPageNumberAccesibilityText()}
                        onKeyUp={handleKeyUp}
                        onBlur={handleNavigateToPage}
                        style={{ width: 50, textAlign: 'center' }}
                        disabled={isLoading}
                        role='input'
                    />
                    <Stack style={{ marginLeft: 8 }}>of {currentFormTotalPages}</Stack>
                </Stack>
            </TooltipHost>
        </Stack>
    );
};

import { ICommonIndividualReportTableContextMenuProps } from '../components/tables/common-individual-report-table/CommonIndividualReportTable';
import { IWhitePaperRow } from '../model';
import { SyncfusionContextMenu, updateMenuItemsUsability } from '../utils/SyncfusionContextMenuUtils';
import { IContextMenuItemOptions } from './IContextMenuItemOptions';
import { useCommentsContextMenuItem } from './useCommentsContextMenuItem';
import { useDrillDownContextMenuItem } from './useDrillDownContextMenuItem';
import { useEditCellContextMenuItem } from './useEditCellContextMenuItem';
import { useEnterAmountsContextMenuItem } from './useEnterAmountsContextMenuItem';

export const useCommonIndividualReportTableContextMenu: (
    contextMenuProps?: ICommonIndividualReportTableContextMenuProps
) => SyncfusionContextMenu<IWhitePaperRow> = (contextMenuProps?: ICommonIndividualReportTableContextMenuProps) => {
    const commonIndividualReportTableContextMenu: SyncfusionContextMenu<IWhitePaperRow> = {
        menuItemOptions: [
            useDrillDownContextMenuItem(contextMenuProps?.drillDown?.onMenuItemClick, contextMenuProps?.drillDown?.getVisibilityState),
            useEnterAmountsContextMenuItem(
                contextMenuProps?.enterAmounts?.onMenuItemClick,
                contextMenuProps?.enterAmounts?.getVisibilityState
            ),
            useCommentsContextMenuItem(contextMenuProps?.comments?.onMenuItemClick, contextMenuProps?.comments?.getVisibilityState),
            useEditCellContextMenuItem(contextMenuProps?.editCell?.onMenuItemClick, contextMenuProps?.editCell?.getVisibilityState),
        ] as IContextMenuItemOptions<IWhitePaperRow>[],
        updateMenuItemsUsability: updateMenuItemsUsability,
    };
    return commonIndividualReportTableContextMenu;
};

import { useCorptaxTheme } from '@corptax/react-components-common';
import { ITextFieldStyles, mergeStyles, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import React, { PropsWithChildren } from 'react';
import { IAppTheme } from '../../theme/IAppTheme';

interface ITextFieldProps {
    id: string;
    className?: string;
    value: string;
    ariaLabel?: string;
    isProcessing?: boolean;
    isErrorInput?: boolean;
    processingLabel?: string;
    onChange?: (event: any, value: any) => void;
    onBlur?: (event: any) => void;
    onEnter?: () => void;
    readOnly?: boolean;
    multiline?: boolean;
    resizable?: boolean;
    placeholder?: string;
    selectAllOnFocus?: boolean;
}

const CustomTextField: React.FC<PropsWithChildren<ITextFieldProps>> = ({
    id,
    className,
    ariaLabel,
    isProcessing,
    isErrorInput,
    processingLabel,
    onChange,
    onBlur,
    onEnter,
    value,
    readOnly,
    multiline,
    resizable,
    placeholder,
    selectAllOnFocus = true,
}) => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const defaultTextFieldStyle = mergeStyles({
        padding: '4px',
        outline: 'none',
        borderRadius: '4px',
        borderColor: isErrorInput ? customPalette.red : customPalette.greyD1D3D4,
        '& .ms-TextField-suffix': {
            backgroundColor: 'unset',
        },
        boxShadow: readOnly ? 'none' : '0px 2px 1px lightgray',
        '::after': {
            outline: 'none',
            borderRadius: '4px',
            border: '1px solid',
            borderColor: isErrorInput ? customPalette.red : customPalette.greyD1D3D4,
            borderBottom: '2px solid',
            borderBottomColor: isErrorInput ? customPalette.red : customPalette.blue075A92,
        },
    });

    const getCustomInputStyles = (): Partial<ITextFieldStyles> => {
        return {
            fieldGroup: [defaultTextFieldStyle],
        };
    };

    const handleInputOnChange = (event: any, value: any) => {
        if (onChange) onChange(event, value);
    };

    const handleSelectAll = (ev: any) => {
        if (ev.currentTarget && selectAllOnFocus) {
            ev.currentTarget.select();
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && onEnter) {
            onEnter();
        }
    };

    const renderSpinnerSuffix = () => {
        return (
            <Spinner
                size={SpinnerSize.xSmall}
                ariaLabel={processingLabel}
                styles={{
                    root: {
                        paddingRight: '5px',
                        opacity: 0.5,
                    },
                }}
            />
        );
    };

    return (
        <TextField
            id={id}
            styles={getCustomInputStyles}
            className={className}
            value={value}
            ariaLabel={ariaLabel}
            autoFocus
            onChange={handleInputOnChange}
            onFocus={handleSelectAll}
            onBlur={onBlur}
            onKeyDown={(event) => handleKeyDown(event.nativeEvent)}
            onRenderSuffix={isProcessing ? renderSpinnerSuffix : undefined}
            readOnly={readOnly}
            multiline={multiline}
            resizable={resizable}
            placeholder={placeholder}
        />
    );
};

export default CustomTextField;

import React, { PropsWithChildren } from 'react';

export interface IConditionalVisibilityProps {
    isVisible: boolean;
}

const ConditionalVisibility: React.FC<PropsWithChildren<IConditionalVisibilityProps>> = ({ children, isVisible }) => {
    return <span style={{ display: isVisible ? 'block' : 'none' }}>{children}</span>;
};

export default ConditionalVisibility;

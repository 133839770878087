import { ContextMenuItemModel, GridComponent } from '@syncfusion/ej2-react-grids';
import { IContextMenuArgs } from '../data-types/IContextMenuArgs';
import { ICurrentCellEventArgs } from '../data-types/ICurrentCellEventArgs';
import { IRowInfo } from '../data-types/IRowInfo';
import { IContextMenuItemOptions } from '../hooks/IContextMenuItemOptions';

export interface ContextMenuItemModelExtended extends ContextMenuItemModel {
    visibilityAccessor?: ContextMenuVisibilityAccessor;
}

export interface ISyncfusionContextMenuItemProps<T> {
    getVisibilityState: ContextMenuVisibilityAccessor;
    onMenuItemClick?: (args?: ICurrentCellEventArgs<T> | null) => void;
}

export declare type ContextMenuVisibilityAccessor = <T>(rowInfo: IRowInfo<T>) => ContextMenuItemDisplayState;

export enum ContextMenuItemDisplayState {
    Invisible = 'Invisible',
    Disabled = 'Disabled',
    Enabled = 'Enabled',
}

export const updateMenuItemsUsability = <T>(args: IContextMenuArgs<T>, grid: GridComponent) => {
    let shouldCancelMenuShowEvent: boolean = true;
    if (grid.contextMenuModule.contextMenu.items?.length > 0) {
        for (const menuItem of grid.contextMenuModule.contextMenu.items) {
            const menuItemExtended = menuItem as ContextMenuItemModelExtended;
            const visibilityState = menuItemExtended.visibilityAccessor!(args.rowInfo);
            switch (visibilityState) {
                case ContextMenuItemDisplayState.Disabled:
                    shouldCancelMenuShowEvent = false;
                    grid.contextMenuModule.contextMenu.enableItems([menuItem.id!], false, true);
                    break;
                case ContextMenuItemDisplayState.Enabled:
                    grid.contextMenuModule.contextMenu.enableItems([menuItem.id!], true, true);
                    grid.contextMenuModule.contextMenu.showItems([menuItem.id!], true);
                    shouldCancelMenuShowEvent = false;
                    break;
                case ContextMenuItemDisplayState.Invisible:
                    grid.contextMenuModule.contextMenu.hideItems([menuItem.id!], true);
                    shouldCancelMenuShowEvent = shouldCancelMenuShowEvent && true;
                    break;
            }
        }
    }

    args.cancel = shouldCancelMenuShowEvent;
};

export type SyncfusionContextMenu<T> = {
    menuItemOptions: IContextMenuItemOptions<T>[];
    updateMenuItemsUsability: (args: IContextMenuArgs<T>, grid: GridComponent) => void;
};

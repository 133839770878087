import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    CheckboxOnChangeData,
    Label,
    makeStyles,
    tokens,
} from '@fluentui/react-components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-multicolumn-combobox';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCustomGroups, useGetCustomRanges } from '../../../api/report/report';
import { TaxReturnItemRecord } from '../../../model';
import { ShowableTaxReturnItemRecord } from './AddReturnPanel';
import EditReturnAdvancedEntitySection from './EditReturnAdvancedEntitySection';
import EditReturnAdvancedOptionsSection from './EditReturnAdvancedOptionsSection';

export interface IAdvancedEntityOptions {
    taxReturnData: ShowableTaxReturnItemRecord | undefined;
    setTaxReturn: (value: TaxReturnItemRecord | undefined) => void;
    setHasChanges: () => void;
}

const useStyles = makeStyles({
    accordionContentStyles: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0',
        gap: '10px',
        width: '300px',
    },
    accordionHeaderStyles: {
        '& button': {
            backgroundColor: 'white',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            borderBottom: '1px solid lightgray',
        },
    },
    accordionItemStyles: {
        borderRadius: '6px',
        backgroundColor: tokens.colorNeutralBackground3,
        minWidth: '344px',
        border: '1px solid lightgray',
    },
    detailViewLabelStyles: {
        display: 'inline-block',
        padding: '5px 0',
    },
});

const AdvancedEntityOptions: React.FC<IAdvancedEntityOptions> = ({ setHasChanges, taxReturnData, setTaxReturn }) => {
    const { t } = useTranslation();
    const { accordionContentStyles, accordionHeaderStyles, accordionItemStyles, detailViewLabelStyles } = useStyles();
    const { data: customGroups }: { data: any } = useGetCustomGroups();
    const { data: customRanges }: { data: any } = useGetCustomRanges();

    const onChangeDropdown = (data: any, key: string) => {
        setTaxReturn({ ...taxReturnData, [key]: data });
        setHasChanges();
    };

    const onChangeCheckbox = (data: CheckboxOnChangeData, key: string) => {
        setTaxReturn({ ...taxReturnData, [key]: data.checked });
        setHasChanges();
    };

    const updateTaxReturnAndSetHasChanges = (data: ShowableTaxReturnItemRecord) => {
        setTaxReturn(data);
        setHasChanges();
    };

    const headerColumns = () => (
        <ColumnsDirective>
            <ColumnDirective field='name' headerTemplate={t('name').toString()} width={90}></ColumnDirective>
            <ColumnDirective field='description' headerTemplate={t('description').toString()} width={160}></ColumnDirective>
        </ColumnsDirective>
    );

    const handleFiltering = (text: string, dataSource: any) => {
        const query = text?.toLowerCase();

        if (!query) {
            return dataSource;
        }

        const filteredData = dataSource.filter(
            (item: any) =>
                item.name?.toLowerCase().includes(query) ||
                item.description?.toLowerCase().includes(query) ||
                item.nameAndDescription?.toLowerCase().includes(query)
        );

        return filteredData;
    };

    return (
        <>
            <Label className={detailViewLabelStyles}>{t('detailView')}</Label>
            <Accordion collapsible multiple>
                <AccordionItem value='1' className={accordionItemStyles}>
                    <AccordionHeader className={accordionHeaderStyles}>{t('advancedEntity').toString()}</AccordionHeader>
                    <AccordionPanel>
                        <div className={accordionContentStyles}>
                            <EditReturnAdvancedEntitySection
                                taxReturnData={taxReturnData}
                                customGroups={customGroups}
                                customRanges={customRanges}
                                headerColumns={headerColumns}
                                filterFunction={handleFiltering}
                                onChangeCheckbox={onChangeCheckbox}
                                onChangeDropdown={onChangeDropdown}
                            />
                        </div>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem value='2' className={accordionItemStyles} style={{ marginTop: 10 }}>
                    <AccordionHeader className={accordionHeaderStyles}>{t('advancedOptions').toString()}</AccordionHeader>
                    <AccordionPanel>
                        <div className={accordionContentStyles}>
                            <EditReturnAdvancedOptionsSection
                                taxReturnData={taxReturnData}
                                customGroups={customGroups}
                                headerColumns={headerColumns}
                                filterFunction={handleFiltering}
                                onChangePostAmountGroup={(data) => onChangeDropdown(data, 'updateColumnFileKey')}
                                onChangePostAmountOption={updateTaxReturnAndSetHasChanges}
                            />
                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default AdvancedEntityOptions;

import { mergeStyles } from '@fluentui/react';
import { useFocusFinders } from '@fluentui/react-tabster';
import { EmitType, getValue, KeyboardEventArgs } from '@syncfusion/ej2-base';
import {
    CellEditArgs,
    CellSaveArgs,
    ContextMenuClickEventArgs,
    ContextMenuItemModel,
    GridComponent,
    ICancel,
    RowDataBoundEventArgs,
    RowInfo,
} from '@syncfusion/ej2-react-grids';
import { BeforeOpenCloseMenuEventArgs } from '@syncfusion/ej2-react-navigations';
import { Dispatch, RefObject, useCallback } from 'react';
import { accountAmountRowPropertyNames } from '../components/enter-amounts-dialog/AccountAmountRowPropertyNames';
import { contextMenuIconStyles } from '../components/tables/common-individual-report-table/StyledIndividualReportGrid';
import { IContextMenuArgs } from '../data-types/IContextMenuArgs';
import { ICurrentCellEventArgs } from '../data-types/ICurrentCellEventArgs';
import { IRowInfo } from '../data-types/IRowInfo';
import { FunctionalAccessDefinition, IAccountAmountRow } from '../model';
import { EnterAmountsDialogReducerAction, EnterAmountsModalActionActionType } from '../reducers/enterAmountsDialogReducer';
import { ISyncfusionGridPopupMenuDismissHack } from '../utils/DialogUtilsV9';
import {
    ContextMenuItemDisplayState,
    ISyncfusionContextMenuItemProps,
    SyncfusionContextMenu,
    updateMenuItemsUsability,
} from '../utils/SyncfusionContextMenuUtils';
import { IContextMenuItemOptions } from './IContextMenuItemOptions';
import { useJournalEntryContextMenuItem } from './useJournalEntryContextMenuItem';

export interface IEnterAmountsGridComponentHook {
    onCellSave: (args: CellSaveArgs) => void;
    onGridCreated: EmitType<Object>;
    onCellEdit: (args: CellEditArgs) => void;
    onRowDataBound: (args: RowDataBoundEventArgs) => void;
    onGridDataBound: (event: any) => void;
    onKeyPressed: EmitType<KeyboardEventArgs>;
    contextMenuItems: ContextMenuItemModel[];
    handleContextMenuItemClick: EmitType<ContextMenuClickEventArgs>;
    handleContextMenuOpen: EmitType<BeforeOpenCloseMenuEventArgs>;
}

export interface IEnterAmountsGridRowData extends IAccountAmountRow {
    isModified?: boolean;
}
export interface IEnterAmountsTableContextMenuProps {
    journalEntry?: ISyncfusionContextMenuItemProps<IAccountAmountRow>;
}

const useEnterAmountsContextMenu: (contextMenuProps?: IEnterAmountsTableContextMenuProps) => SyncfusionContextMenu<IAccountAmountRow> = (
    contextMenuProps?: IEnterAmountsTableContextMenuProps
) => {
    return {
        menuItemOptions: [
            useJournalEntryContextMenuItem(
                contextMenuProps?.journalEntry?.onMenuItemClick,
                contextMenuProps?.journalEntry?.getVisibilityState
            ),
        ] as IContextMenuItemOptions<IAccountAmountRow>[],
        updateMenuItemsUsability: updateMenuItemsUsability,
    } as SyncfusionContextMenu<IAccountAmountRow>;
};

export const useEnterAmountsGridComponentProps: (
    gridRef: RefObject<GridComponent>,
    updateState: Dispatch<EnterAmountsDialogReducerAction>,
    saveIsDisabled: boolean,
    journalEntryGridRef: RefObject<GridComponent>,
    journalEntryGridPopupMenuDismissHack: ISyncfusionGridPopupMenuDismissHack,
    functionalAccess: FunctionalAccessDefinition
) => IEnterAmountsGridComponentHook = (
    gridRef: RefObject<GridComponent>,
    updateState: Dispatch<EnterAmountsDialogReducerAction>,
    saveIsDisabled: boolean,
    journalEntryGridRef: RefObject<GridComponent>,
    journalEntryGridPopupMenuDismissHack: ISyncfusionGridPopupMenuDismissHack,
    functionalAccess: FunctionalAccessDefinition
) => {
    const { findNextFocusable, findPrevFocusable } = useFocusFinders();

    const onCellSave = useCallback(
        (args: CellSaveArgs) => {
            if (!args || args.value === args.previousValue) {
                return;
            }

            const enterAmountsGrid: GridComponent = gridRef.current!;
            const rowInfo: RowInfo = gridRef.current!.getRowInfo(args.cell!);
            const originalRowData: IEnterAmountsGridRowData = rowInfo.rowData as IEnterAmountsGridRowData;
            const currentRowData: IAccountAmountRow = args.rowData! as IAccountAmountRow;
            const rowIndex: number = rowInfo.rowIndex!;
            const isDebitAccount: boolean = currentRowData.accountBalanceType === 'Debit';
            const currentDebitTotal: number | null =
                currentRowData.debitTotal === null || currentRowData.debitTotal === undefined ? null : currentRowData.debitTotal;
            const currentCreditTotal: number | null =
                currentRowData.creditTotal === null || currentRowData.creditTotal === undefined ? null : currentRowData.creditTotal;
            const currentAdjustedTotal: number | null =
                currentRowData.adjustedTotal === null || currentRowData.adjustedTotal === undefined ? null : currentRowData.adjustedTotal;
            const currentCombinedTotal: number | null =
                currentRowData.combinedTotal === null || currentRowData.combinedTotal === undefined ? null : currentRowData.combinedTotal;
            const newTotal: number = args.value === null || args.value === undefined ? 0 : parseInt(args.value);
            const [newDebitTotal, newCreditTotal, newCombinedTotal]: [number | null, number | null, number | null] =
                calculateNewTotalColumnValues(
                    newTotal,
                    currentDebitTotal,
                    currentCreditTotal,
                    currentCombinedTotal,
                    args.columnName!,
                    isDebitAccount
                );
            const newAdjustedTotal: number | null = calculateNewAdjustedTotal(
                currentAdjustedTotal,
                currentDebitTotal,
                currentCreditTotal,
                newDebitTotal,
                newCreditTotal,
                isDebitAccount
            );

            if (args.columnName === accountAmountRowPropertyNames.combinedTotal) {
                args.value = newCombinedTotal as unknown as string;
                enterAmountsGrid.updateCell(rowIndex, accountAmountRowPropertyNames.debitTotal, newDebitTotal as unknown as number);
                enterAmountsGrid.updateCell(rowIndex, accountAmountRowPropertyNames.creditTotal, newCreditTotal as unknown as number);
            } else {
                if (args.columnName === accountAmountRowPropertyNames.debitTotal) {
                    args.value = newDebitTotal as unknown as string;
                    enterAmountsGrid.updateCell(rowIndex, accountAmountRowPropertyNames.creditTotal, newCreditTotal as unknown as number);
                } else {
                    args.value = newCreditTotal as unknown as string;
                    enterAmountsGrid.updateCell(
                        rowInfo.rowIndex!,
                        accountAmountRowPropertyNames.debitTotal,
                        newDebitTotal as unknown as number
                    );
                }
                enterAmountsGrid.updateCell(
                    rowInfo.rowIndex!,
                    accountAmountRowPropertyNames.combinedTotal,
                    newCombinedTotal as unknown as number
                );
            }

            enterAmountsGrid.updateCell(
                rowInfo.rowIndex!,
                accountAmountRowPropertyNames.adjustedTotal,
                newAdjustedTotal as unknown as number
            );

            updateOriginalRowData(originalRowData, newDebitTotal, newCreditTotal, newAdjustedTotal, newCombinedTotal);

            if (saveIsDisabled && !(newDebitTotal === null && newCreditTotal === null && newCombinedTotal === null)) {
                updateState({ type: EnterAmountsModalActionActionType.CellEdited });
            }
        },
        [updateState, gridRef, gridRef.current, parseInt]
    );
    const editCell = (args: HTMLElement) => {
        gridRef?.current!.editModule.editCell(
            parseInt(args!.getAttribute('index')!),
            gridRef?.current!.getColumnByIndex(parseInt(args!.getAttribute('data-colindex')!)).field
        );
    };

    const onKeyPressed: EmitType<KeyboardEventArgs> = (e: KeyboardEventArgs) => {
        const eventTarget: HTMLElement = e.target as HTMLElement;
        if (e.code === 'Tab' && eventTarget.closest('.e-grid')) {
            // KeyboardEventArgs doesn't extend this interface in the typings, but the syncfusion code example
            // where I grabbed this from sets this to true
            (e as unknown as ICancel).cancel = true;
            updateTabIndexForSyncfusionTable(gridRef);
            if (e.shiftKey) {
                findPrevFocusable(e.target as HTMLElement)?.focus();
            } else {
                findNextFocusable(e.target as HTMLElement)?.focus();
            }
        }
    };

    const contextMenuItemClassName = mergeStyles(contextMenuIconStyles);
    const onGridCreated: EmitType<Object> = (_: Object) => {
        const accountAmountGrid: GridComponent | null = gridRef?.current;
        if (accountAmountGrid) {
            accountAmountGrid.getContentTable().addEventListener('click', (args: Event) => {
                const targetElement = args.target as HTMLElement;
                if (targetElement.classList?.contains('e-rowcell') && targetElement !== targetElement.parentElement?.lastElementChild) {
                    editCell(targetElement);
                }
            });
            accountAmountGrid.element.addEventListener('keydown', (e: KeyboardEvent) => {
                const eventTarget = e.target as HTMLElement;
                const closestTd: HTMLTableCellElement | null = eventTarget.closest('td');
                if (e.code === 'Enter') {
                    if (eventTarget.tagName !== 'INPUT' && closestTd !== closestTd?.parentElement?.lastElementChild) {
                        e.preventDefault();
                        editCell(closestTd!);
                    }
                }
                if (e.code === 'Escape') {
                    if (eventTarget.tagName === 'INPUT') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
                // this allows users to use arrow keys to immediately save the current cell and move to the next cell in the column
                // that corresponds with the key code.
                // if you are in either the last or first row, focus should move to the header
                if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
                    if (eventTarget.tagName === 'INPUT') {
                        accountAmountGrid!.editModule.saveCell();
                        const currentRowUid: string | undefined = accountAmountGrid.focusModule.currentInfo.uid;
                        const currentCellElement: HTMLElement | undefined = accountAmountGrid.focusModule.currentInfo.element;
                        const currentColumnIndex: number = parseInt(currentCellElement?.getAttribute('data-colindex') ?? '');
                        const currentRowElement: Element | undefined = accountAmountGrid.getRowElementByUID(currentRowUid!);
                        const currentRowIndex: number = parseInt(currentRowElement?.getAttribute('data-rowindex') ?? '');
                        const allRows = accountAmountGrid.getDataRows();
                        let newRow;
                        if (e.code === 'ArrowDown') {
                            newRow = allRows[currentRowIndex + 1];
                        } else {
                            newRow = allRows[currentRowIndex - 1];
                        }
                        let newCell = newRow?.children[currentColumnIndex!];
                        if (!newCell) {
                            newCell = accountAmountGrid?.getColumnHeaderByIndex(currentColumnIndex);
                        }
                        if (newCell) {
                            accountAmountGrid.focusModule.onClick({ target: newCell, type: 'click' });
                        }

                        e.preventDefault();
                    }
                }
            });
            accountAmountGrid.contextMenuModule.contextMenu.cssClass += ` ${contextMenuItemClassName}`;
        }
    };

    function onGridDataBound(event: any) {
        updateTabIndexForSyncfusionTable(gridRef);
    }

    function onCellEdit(args: CellEditArgs) {
        const rowData = args.rowData as IAccountAmountRow;
        if (rowData.disabled === true) {
            args.cancel = true;
        }
    }

    function calculateNewTotalColumnValues(
        newAmount: number,
        currentDebitTotal: number | null,
        currentCreditTotal: number | null,
        currentCombinedTotal: number | null,
        columnName: string,
        isDebitAccount: boolean
    ): [newDebitTotal: number | null, newCreditTotal: number | null, newCombinedTotal: number | null] {
        let newDebitTotal: number | null = null;
        let newCreditTotal: number | null = null;
        let newCombinedTotal: number | null = null;

        if (!(newAmount === 0 && currentDebitTotal === null && currentCreditTotal === null && currentCombinedTotal === null)) {
            if (columnName === accountAmountRowPropertyNames.debitTotal) {
                newDebitTotal = newAmount;

                if (newDebitTotal < 0 || (newDebitTotal === 0 && !isDebitAccount)) {
                    newCreditTotal = Math.abs(newDebitTotal);
                    newDebitTotal = null;
                }

                newCombinedTotal = calculateNewCombinedTotal(newDebitTotal, newCreditTotal, isDebitAccount);
            } else if (columnName === accountAmountRowPropertyNames.creditTotal) {
                newCreditTotal = newAmount;

                if (newCreditTotal < 0 || (newCreditTotal === 0 && isDebitAccount)) {
                    newDebitTotal = Math.abs(newCreditTotal);
                    newCreditTotal = null;
                }
                newCombinedTotal = calculateNewCombinedTotal(newDebitTotal, newCreditTotal, isDebitAccount);
            } else if (columnName === accountAmountRowPropertyNames.combinedTotal) {
                newCombinedTotal = newAmount;
                if (isDebitAccount) {
                    if (newAmount < 0) {
                        newCreditTotal = Math.abs(newAmount);
                        newDebitTotal = null;
                    } else {
                        newDebitTotal = newAmount;
                        newCreditTotal = null;
                    }
                } else {
                    if (newAmount < 0) {
                        newDebitTotal = Math.abs(newAmount);
                        newCreditTotal = null;
                    } else {
                        newCreditTotal = newAmount;
                        newDebitTotal = null;
                    }
                }
            }
        }

        return [newDebitTotal, newCreditTotal, newCombinedTotal];
    }

    function calculateNewAdjustedTotal(
        currentAdjustedAmount: number | null,
        currentDebitAmount: number | null,
        currentCreditAmount: number | null,
        newDebitAmount: number | null,
        newCreditAmount: number | null,
        isDebitAccount: boolean
    ): number | null {
        let newAdjustedAmount: number | null;

        if (isDebitAccount) {
            newAdjustedAmount =
                (currentAdjustedAmount ?? 0) -
                (currentDebitAmount ?? 0) +
                (currentCreditAmount ?? 0) +
                (newDebitAmount ?? 0) -
                (newCreditAmount ?? 0);
        } else {
            newAdjustedAmount =
                (currentAdjustedAmount ?? 0) +
                (currentDebitAmount ?? 0) -
                (currentCreditAmount ?? 0) -
                (newDebitAmount ?? 0) +
                (newCreditAmount ?? 0);
        }

        if (newAdjustedAmount === 0 && newDebitAmount === null && newCreditAmount === null) {
            newAdjustedAmount = null;
        }

        return newAdjustedAmount;
    }

    function calculateNewCombinedTotal(
        newDebitTotal: number | null,
        newCreditTotal: number | null,
        isDebitAccount: boolean
    ): number | null {
        if (isDebitAccount) {
            if (newDebitTotal === null) {
                return newCreditTotal! * -1;
            }
            return newDebitTotal;
        } else {
            if (newCreditTotal === null) {
                return newDebitTotal! * -1;
            }
            return newCreditTotal;
        }
    }

    function updateOriginalRowData(
        originalRowData: IEnterAmountsGridRowData,
        newDebitAmount: number | null,
        newCreditAmount: number | null,
        newAdjustedAmount: number | null,
        newCombinedTotal: number | null
    ) {
        originalRowData.adjustedAmount = newAdjustedAmount?.toString() ?? null;
        originalRowData.adjustedTotal = newAdjustedAmount;
        originalRowData.debitAmount = newDebitAmount?.toString() ?? null;
        originalRowData.debitTotal = newDebitAmount;
        originalRowData.creditAmount = newCreditAmount?.toString() ?? null;
        originalRowData.creditTotal = newCreditAmount;
        originalRowData.combinedTotal = newCombinedTotal;
        originalRowData.isModified = true;
    }

    const onRowDataBound = (args: RowDataBoundEventArgs) => {
        if (args.row) {
            if (!functionalAccess.amountDataEntryModifyEnabled) {
                args.row.classList.add('disabledRow');
                return;
            }
            if (getValue('disabled', args.data) === true) {
                args.row.classList.add('disabledRow');
            }
        }
    };
    function openJournalEntryTable(args?: ICurrentCellEventArgs<IAccountAmountRow> | null | undefined): void {
        if (args) {
            updateState({
                type: EnterAmountsModalActionActionType.JournalEntryOpened,
                accountCode: args.data.accountCode!,
                gridActionComplete: journalEntryGridPopupMenuDismissHack.gridActionComplete,
                gridRef: journalEntryGridRef,
            });
        }
    }
    const menuItemProps: IEnterAmountsTableContextMenuProps = {
        journalEntry: {
            onMenuItemClick: openJournalEntryTable,
            getVisibilityState: (rowInfo: IRowInfo<IAccountAmountRow>) => {
                if (!functionalAccess.journalEntryAccessEnabled) {
                    return ContextMenuItemDisplayState.Disabled;
                }
                if (rowInfo.column!.field === accountAmountRowPropertyNames.combinedTotal) {
                    return ContextMenuItemDisplayState.Enabled;
                }
                if (rowInfo.column!.field === accountAmountRowPropertyNames.debitTotal) {
                    return ContextMenuItemDisplayState.Enabled;
                }
                if (rowInfo.column!.field === accountAmountRowPropertyNames.creditTotal) {
                    return ContextMenuItemDisplayState.Enabled;
                }
                if (rowInfo.column!.field === accountAmountRowPropertyNames.adjustedTotal) {
                    return ContextMenuItemDisplayState.Enabled;
                }
                return ContextMenuItemDisplayState.Invisible;
            },
        } as ISyncfusionContextMenuItemProps<IAccountAmountRow>,
    };
    const { menuItemOptions, updateMenuItemsUsability } = useEnterAmountsContextMenu(menuItemProps);
    const contextMenuItems: Array<ContextMenuItemModel> = menuItemOptions.map((menuItem) => menuItem.contextMenuItem);

    const handleContextMenuItemClick: EmitType<ContextMenuClickEventArgs> = (args: ContextMenuClickEventArgs) => {
        if (!args.rowInfo) {
            return;
        }
        if (!args.column) {
            return;
        }
        const currentCellEventArgs: ICurrentCellEventArgs<IAccountAmountRow> = {
            cellIndex: { rowIndex: args.rowInfo?.rowIndex, cellIndex: args.rowInfo?.cellIndex },
            fieldName: args.column.field,
            data: args.rowInfo.rowData as IAccountAmountRow,
        };
        // iterate over the collection of menu items and call its click handler.
        // if it returns true, break out of the loop, else keep going
        for (const menuItem of menuItemOptions) {
            // to avoid magic strings, each click handler checks that the args id matches and return false if not
            // so we invoke each in turn, because god forbid syncfusion has click handlers for each item, no, it's for the entire context menu like animals
            if (menuItem!.handleContextMenuItemClick(args, currentCellEventArgs)) {
                break;
            }
        }
    };
    const handleContextMenuOpen: EmitType<BeforeOpenCloseMenuEventArgs> = (args: IContextMenuArgs<IAccountAmountRow>) => {
        // const cellIndex: IIndex = {
        //     cellIndex: args.rowInfo.cellIndex,
        //     rowIndex: args.rowInfo.rowIndex,
        // };

        if (gridRef && gridRef.current) {
            if (gridRef.current.isEdit) {
                args.cancel = true;
                return;
            }

            // gridRef.current.contextMenuModule.contextMenu.onClose = () => {
            //     setFocusOnSyncfusionGridCell(args.rowInfo.cell);
            // };
            updateMenuItemsUsability(args, gridRef.current);
        }
        //TODO: this comes a prop in commonindividualreporttable. the concrete components that use _that_ component are keeping track of it in state
        // but i'm not sure if we actually need to keep that stuff in state
        // if (setCurrentCellArgs && args.column) {
        //     setCurrentCellArgs({
        //         cellIndex: cellIndex,
        //         data: args.rowInfo.rowData as IAccountAmountRow,
        //         fieldName: args.column.field,
        //     });
        // }

        // setTimeout(() => {
        //     const clickedElement = getClickedTdElement(args.event.target as HTMLElement);
        //     if (clickedElement) {
        //         setCurrentlySelectedCell(clickedElement);
        //         clickedElement.classList.add('e-focused');
        //     }
        // }, 400);
    };

    return {
        onCellSave,
        onGridCreated,
        onCellEdit,
        onRowDataBound,
        onGridDataBound,
        onKeyPressed,
        contextMenuItems,
        handleContextMenuItemClick,
        handleContextMenuOpen,
    } as IEnterAmountsGridComponentHook;
};

/*
Syncfusion grid components throw a tabindex of 0 on the first header cell and last content cell by default.
This function overrides that behavior so when you hit tab inside the table, focus always exits the table.
*/
function updateTabIndexForSyncfusionTable(gridRef: RefObject<GridComponent>) {
    const gridComponent = gridRef.current;
    if (gridComponent) {
        gridComponent.element.tabIndex = 0;
    }
    const headerTable: HTMLTableElement | undefined = gridComponent?.getHeaderTable() as HTMLTableElement;
    if (headerTable) {
        headerTable.rows[0].cells[0].tabIndex = -1;
    }
    const contentRow = (gridComponent?.getContentTable() as HTMLTableElement).rows;

    contentRow[contentRow.length - 1].cells[contentRow[contentRow.length - 1].cells.length - 1].tabIndex = -1;
}

import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, ITooltipHostProps, TooltipHost, TooltipOverflowMode } from '@fluentui/react';
import {
    Link,
    makeStyles,
    Tree,
    TreeItem,
    TreeItemLayout,
    TreeItemOpenChangeData,
    TreeItemOpenChangeEvent,
} from '@fluentui/react-components';
import React from 'react';
import { FormTreeFolder, FormTreePage } from '../../../contexts/formPdfViewContext';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IAppTheme } from '../../../theme/IAppTheme';
import { getTooltipHostProps } from '../../../utils/CustomStyles';
import { PINNED_SEARCH_PARAM_NAME } from '../../../utils/Urls.constant';
import Icon from '../../common/Icon';

export interface IFormTOCSidebarProps {
    filteredFolders: FormTreeFolder[];
    noRecordsMessage: string;
}

const useStyles = makeStyles({
    noRecordsLabel: {
        margin: '20px',
        fontSize: '14px',
    },
    rowTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '300px',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        height: '38px',
        textDecoration: 'none',
        color: 'inherit',
    },
    textNoWrap: {
        textWrap: 'nowrap',
    },
    treeBranch: {
        paddingLeft: '12px',
    },
});

const FormTOCTreeView: React.FC<IFormTOCSidebarProps> = ({ filteredFolders, noRecordsMessage }) => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const styles = useStyles();
    const { tocData, currentPage, isDrawerPinned, isLoading, loadPdfPage, setNodesExpanded, getCurrentFormPageUrl } = useFormPdfView();

    const tooltipHostProps: ITooltipHostProps = {
        ...getTooltipHostProps(DirectionalHint.leftCenter, TooltipOverflowMode.Self, customPalette),
    };

    const onSelectTreeViewNode = (id: string, parentId?: string | null) => {
        const isParent = parentId === null || parentId === undefined;
        const folderId = isParent ? id : parentId;

        const pagesReceived = tocData?.folders?.find((folder) => folder.id === folderId)?.pages || [];
        if (pagesReceived && pagesReceived.length) {
            const firstPageUniqueId = isParent ? pagesReceived[0]?.uniqueId : id;
            loadPdfPage(pagesReceived, folderId, firstPageUniqueId ?? '');
        }
    };

    const getNodeExpandedChangeHandler = (folder: FormTreeFolder) => {
        return (_: TreeItemOpenChangeEvent, data: TreeItemOpenChangeData) => {
            setNodesExpanded(data.open, { ...folder, expanded: data.open });
        };
    };

    const nodeElementTemplate = (url: string, elementTitle: string, elementUniqueId: string, parentUniqueId?: string) => {
        return (
            <Link
                appearance='subtle'
                href={url}
                tabIndex={-1}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onSelectTreeViewNode(elementUniqueId, parentUniqueId);
                }}
                disabled={isLoading}
            >
                <span className={styles.row}>
                    {parentUniqueId === undefined && (
                        <Icon
                            iconName='Form20Regular'
                            style={{ color: isLoading ? corptaxCustomLightTheme.colorNeutralForegroundDisabled : customPalette.blue075A92 }}
                        />
                    )}
                    <TooltipHost {...tooltipHostProps} content={elementTitle} hostClassName={styles.rowTitle}>
                        <span className={styles.textNoWrap}>{elementTitle}</span>
                    </TooltipHost>
                </span>
            </Link>
        );
    };

    const folderTemplate = (folder: FormTreeFolder) => {
        let url: string = '';

        if (folder && folder.pages && folder.pages.length > 0) {
            url = getCurrentFormPageUrl(folder.pages[0].id, folder.pages[0].name ?? '');
            url = isDrawerPinned ? `${url}?${PINNED_SEARCH_PARAM_NAME}=true` : url;
        }

        return nodeElementTemplate(url, folder.title ?? '', folder.uniqueId ?? '');
    };

    const pageTemplate = (page: FormTreePage, parentFolder: FormTreeFolder) => {
        let url = getCurrentFormPageUrl(page.id, page.name ?? '');

        url = isDrawerPinned ? `${url}?${PINNED_SEARCH_PARAM_NAME}=true` : url;

        return nodeElementTemplate(url, page.title ?? '', page.uniqueId ?? '', parentFolder.id ?? '');
    };

    const getPageTreeItem = (folder: FormTreeFolder, page: FormTreePage) => {
        const isSelected = currentPage.pageId === page.id;

        return (
            <TreeItem
                style={{
                    backgroundColor: isSelected ? customPalette.statusCoolLightGrey : 'inherit',
                }}
                key={page.id}
                itemType='leaf'
                onClick={() => onSelectTreeViewNode(page.uniqueId ?? '', folder.id)}
            >
                <TreeItemLayout className='tree-level-2'>{pageTemplate(page, folder)}</TreeItemLayout>
            </TreeItem>
        );
    };

    const getFolderTreeItem = (folder: FormTreeFolder) => {
        const hasPages = (folder.pages?.length ?? 0) > 0;

        return (
            <TreeItem
                key={folder.id}
                itemType={hasPages ? 'branch' : 'leaf'}
                open={folder.expanded}
                onOpenChange={getNodeExpandedChangeHandler(folder)}
            >
                <TreeItemLayout className={`tree-level-1 ${styles.treeBranch}`}>{folderTemplate(folder)}</TreeItemLayout>
                <Tree>{folder.pages?.map((page) => getPageTreeItem(folder, page))}</Tree>
            </TreeItem>
        );
    };

    if (filteredFolders.length === 0) {
        return <span className={styles.noRecordsLabel}>{noRecordsMessage}</span>;
    }

    return <Tree aria-label='Forms table of contents'>{filteredFolders.map(getFolderTreeItem)}</Tree>;
};

export default FormTOCTreeView;

import { EmitType } from '@syncfusion/ej2-base';
import { CheckBoxFilterBase, FilterMenuRendererArgs, GridActionEventArgs, GridComponent } from '@syncfusion/ej2-react-grids';
import React, { KeyboardEventHandler, MouseEventHandler, MutableRefObject, RefObject } from 'react';
import { AllowNull } from '../data-types/AllowUndefinedAndNull';

export interface ISyncfusionGridPopupMenuDismissHack {
    dialogSurfaceClick: MouseEventHandler<HTMLDivElement>;
    gridActionComplete: EmitType<GridActionEventArgs>;
}

/*
        This function serves as a way to work around an issue where if a user hits the Escape key while focused anywhere inside a syncfusion grid,
        syncfusion will call preventDefault() on that keydown event. 

        The FocusTrapZone in Fluent 8 does not care about this fact, but tabster (the library that Fluent 9 uses for keyboard navigation and focus management)
        will not fire the onOpenChange event if the native event has defaultPrevented === true.

        As a result of this, the FocusTrapZone that is still used by the ReportDrillDown component will receive the Escape keydown event,
        which will close the entire drilldown panel instead of just the EnterAmountsDialog.
    */
export const getSyncfusionFocusEventHandlerHack: (cancelButtonRef: RefObject<HTMLButtonElement>) => KeyboardEventHandler<HTMLDivElement> =
    (cancelButtonRef: RefObject<HTMLButtonElement>) => (event: React.KeyboardEvent<HTMLDivElement>) => {
        const eventTarget: HTMLElement = event.target as HTMLElement;
        if (event.key === 'Escape' && eventTarget.closest('.e-grid')) {
            event.stopPropagation();
            cancelButtonRef?.current?.click();
        }
    };

/*
    This function serves as a workaround to esnure that Sync Fusion grid popup menus are always dismissed as it does not work correctly 
    in all scenarios when used within a Fluent UI dialog.

    The function returns an object containing handlers which must be applied to the SyncFusion Grid and the Fluent UI Dialog Surface.
*/
export const getSyncfusionPopupMenuDismissHack: (
    gridRef: RefObject<GridComponent>,
    gridFilterMenuRef: MutableRefObject<AllowNull<CheckBoxFilterBase>>
) => ISyncfusionGridPopupMenuDismissHack = (
    gridRef: RefObject<GridComponent>,
    gridFilterMenuRef: MutableRefObject<AllowNull<CheckBoxFilterBase>>
) => {
    const closeFilterMenu: Function = () => {
        gridFilterMenuRef.current?.closeDialog();
        gridFilterMenuRef.current = null;
    };

    const filterMenuKeyDown: EmitType<KeyboardEvent> = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            closeFilterMenu();
            event.stopPropagation();
        }
    };

    return {
        dialogSurfaceClick: (event: React.MouseEvent<HTMLDivElement>) => {
            const target: HTMLElement = event.target as HTMLElement;
            const filterMenu: AllowNull<CheckBoxFilterBase> = gridFilterMenuRef.current;

            if (filterMenu && !target.closest('.e-checkboxfilter')) {
                closeFilterMenu();
                event.stopPropagation();
            }

            if (gridRef.current?.isContextMenuOpen()) {
                gridRef.current.contextMenuModule.contextMenu.close();
            }
        },
        gridActionComplete: (event: GridActionEventArgs) => {
            if (event.requestType! === 'filterAfterOpen') {
                const filterMenu: CheckBoxFilterBase | null = (event as FilterMenuRendererArgs)
                    .filterModel! as unknown as CheckBoxFilterBase;
                const filterMenuElement: HTMLDivElement = (filterMenu as any).dlg as HTMLDivElement;

                filterMenuElement.addEventListener('keydown', filterMenuKeyDown);
                gridFilterMenuRef.current = filterMenu;
            }
        },
    };
};

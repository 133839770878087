// import * as React from 'react';

import { createContext, useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { useGetAppVersionCalculationReviewBuildNumber } from '../api/app-version/app-version';
import { useGetEnterprise } from '../api/report/report';
import { useGetClaimsForCurrentUser } from '../api/user-claims/user-claims';
import { CalculationExecutionStatusCode } from '../model';
import { OPENED_REPORTS_NAME } from '../utils/BrowserStorageKeys.constants';
export interface IUserSettingsProviderProps {
    children?: React.ReactNode;
    userSettings?: IUserSettingsData;
}
export interface IUserSettingsData {
    mostRecentlyOpenedTabs?: ITaxReturnTab[];
}

export interface IUserSettings {
    mostRecentlyOpenedTabs?: ITaxReturnTab[];
    updateReturnTabs: (tab: ITaxReturnTab, isAdd: boolean) => void;
    clearReturnTabs: () => void;
    removeReturn: (key: string) => void;
    environmentName: string;
    buildVersion: string;
}

export interface ITaxReturnTab {
    returnKey: number;
    returnName?: string;
    statusCode?: CalculationExecutionStatusCode;
}

const userSettings: IUserSettings = {
    mostRecentlyOpenedTabs: [],
    updateReturnTabs: (tab?: ITaxReturnTab, isAdd?: boolean) => {},
    clearReturnTabs: () => {},
    removeReturn: (key: string) => {},
    environmentName: '',
    buildVersion: '',
};

export const updateTaxReturnTabs = (
    currentTabs: ITaxReturnTab[],
    tab?: ITaxReturnTab,
    isAdd?: boolean,
    clearTabs = false
): ITaxReturnTab[] => {
    const maxTabs = 40;
    let copyTabs = [...currentTabs];
    const index = copyTabs.findIndex((item) => item.returnKey === tab?.returnKey);
    if (index >= 0) {
        const oldTabObject = copyTabs.splice(index, 1);
        tab = { ...oldTabObject[0], ...tab };
    }
    if (isAdd) {
        if (tab) copyTabs.unshift(tab);
    }
    if (copyTabs.length > maxTabs) {
        copyTabs.pop();
    }
    if (clearTabs) {
        copyTabs = [];
    }
    return copyTabs;
};

export const UserSettingsContext = createContext(userSettings);

export function UserSettingsProvider(props: IUserSettingsProviderProps) {
    const userClaims = useGetClaimsForCurrentUser();
    const { data: buildData } = useGetAppVersionCalculationReviewBuildNumber();
    const environmentId = userClaims?.data?.environmentId || '';
    const userId = userClaims?.data?.userId || '';
    const { data: enterpriseId, isSuccess } = useGetEnterprise();
    const openedReportStorageName = `${environmentId}-${userId}-${enterpriseId?.enterpriseId ?? ''}_${OPENED_REPORTS_NAME}`;
    const [openedReportsStorageValue, setOpenedReportsStorageValue, { removeItem: clearOpenedReportsStorageValues }] = useLocalStorageState(
        openedReportStorageName,
        {
            defaultValue: [] as ITaxReturnTab[],
            storageSync: isSuccess,
        }
    );

    const [environmentName, setEnvironmentName] = useState('');
    const [buildVersion, setBuildVersion] = useState('');

    useEffect(() => {
        setEnvironmentName(userClaims?.data?.environmentName || '');
        setBuildVersion(buildData || '');
    }, [userClaims?.data?.environmentName, buildData]);

    const updateReturnTabs = (tab: ITaxReturnTab, isAdd: boolean, clearAll = false): void => {
        const latestTabs = updateTaxReturnTabs(openedReportsStorageValue || [], tab, isAdd, clearAll);
        setOpenedReportsStorageValue(latestTabs);
    };
    const clearReturnTabs = (): void => {
        clearOpenedReportsStorageValues();
    };

    const removeReturn = (key: string) => {
        const filteredEntities = openedReportsStorageValue.filter((item) => item.returnKey !== parseInt(key));
        setOpenedReportsStorageValue(filteredEntities);
    };

    return (
        <UserSettingsContext.Provider
            value={{
                mostRecentlyOpenedTabs: openedReportsStorageValue,
                updateReturnTabs,
                clearReturnTabs,
                removeReturn,
                environmentName,
                buildVersion,
            }}
        >
            {props.children}
        </UserSettingsContext.Provider>
    );
}

export default UserSettingsProvider;

import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogOpenChangeData,
    DialogOpenChangeEvent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    tokens,
} from '@fluentui/react-components';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { getComponent, NumberFormatOptions } from '@syncfusion/ej2-base';
import {
    Aggregate,
    AggregateColumnDirective,
    AggregateColumnsDirective,
    AggregateDirective,
    AggregatesDirective,
    CheckBoxFilterBase,
    ColumnDirective,
    ColumnsDirective,
    ContextMenu,
    Edit,
    EditSettingsModel,
    Filter,
    FilterSettingsModel,
    GridComponent,
    IEditCell,
    Inject,
    Page,
    Sort,
} from '@syncfusion/ej2-react-grids';
import { NumericTextBox, NumericTextBoxComponent, NumericTextBoxModel } from '@syncfusion/ej2-react-inputs';
import React, { FC, KeyboardEventHandler, MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GridStateContextProvider } from '../../contexts/gridStateContext';
import { useEnterAmountsData } from '../../hooks/useEnterAmountsData';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { IEnterAmountsGridRowData, useEnterAmountsGridComponentProps } from '../../hooks/useEnterAmountsGridComponentProps';
import { useFunctionalAccess } from '../../hooks/useFunctionalAccess';

import { AllowNull } from '../../data-types/AllowUndefinedAndNull';
import {
    IEnterAmountsDialogFilterChangeTrackingInformation,
    IEnterAmountsDialogInitialLoadTrackingInformation,
    IEnterAmountsDialogSaveAmountsTrackingInformation,
    usePerformanceMetricsForEnterAmountsDialog,
} from '../../hooks/usePerformanceMetricsForEnterAmountsDialog';
import {
    ExtractionDetailsAccountSummary,
    ExtractionDetailsAdjustmentInfo,
    IAccountAmountRow,
    IReportColumnContext,
    IWhitePaperReportChanges,
    ProblemDetails,
    SaveAmountsRequest,
    SaveAmountsResponse,
} from '../../model';
import { EnterAmountsModalActionActionType } from '../../reducers/enterAmountsDialogReducer';
import { addEnterAmountsUpdatedAccountsToTrackingData, addReportCellToChangeTrackingData } from '../../utils/ChangeTrackingDataUtils';
import {
    getSyncfusionFocusEventHandlerHack,
    getSyncfusionPopupMenuDismissHack,
    ISyncfusionGridPopupMenuDismissHack,
} from '../../utils/DialogUtilsV9';
import { deFormatNumber } from '../../utils/NumberUtils';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import { ErrorType } from '../../utils/UseCustomInstance';
import LoadingSpinner from '../common/LoadingSpinner';
import { JournalEntryGrid } from '../sidebar/JournalEntryGrid';
import { accountAmountRowPropertyNames } from './AccountAmountRowPropertyNames';
import EnterAmountsDialogFilters from './EnterAmountsDialogFilters';

export interface IEnterAmountsDialogProps {
    adjustmentCode: string;
    case: string;
    jurisdiction: string;
    entity: string;
    period: string;
    location: string;
    drillDownRowNumber: number;
    definingRowNumber?: number;
    columnId: number;
    changeTrackingData: IWhitePaperReportChanges;
    taxReturnKey: number;
    rowDescription: string;
    onClose?: (amountsChangedOnServer: boolean, changeTrackingData: IWhitePaperReportChanges) => void;
    updateChangeTrackingData?: (changeTrackingData: IWhitePaperReportChanges) => void;
    buttonTrigger?: HTMLElement;
}

export function getUpdatedAccountAmountRow(
    originalRow: IAccountAmountRow,
    batchChanges: { changedRecords: IAccountAmountRow[] }
): IAccountAmountRow | undefined {
    const updatedRow: IAccountAmountRow | undefined = batchChanges.changedRecords.find(
        (changedRecord) => changedRecord.accountCode === originalRow.accountCode
    );
    return updatedRow;
}

export const enterAmountsModalId: string = 'enterAmountsDialog';
const EnterAmountsDialog: FC<IEnterAmountsDialogProps> = (props: IEnterAmountsDialogProps) => {
    const {
        getEnterAmountsDialogFilterChangeTrackingInformation,
        getEnterAmountsDialogInitialLoadTrackingInformation,
        getEnterAmountsDialogSaveAmountsTrackingInformation,
        trackEnterAmountsDialogFilterChangeBegin,
        trackEnterAmountsDialogFilterChangeEnd,
        trackEnterAmountsDialogInitialLoadBegin,
        trackEnterAmountsDialogInitialLoadEnd,
        trackEnterAmountsDialogSaveAmountsBegin,
        trackEnterAmountsDialogSaveAmountsEnd,
    } = usePerformanceMetricsForEnterAmountsDialog();
    const { t } = useTranslation();
    const gridRef: RefObject<GridComponent> = useRef<GridComponent>(null);
    const gridFilterMenuRef: MutableRefObject<AllowNull<CheckBoxFilterBase>> = useRef<CheckBoxFilterBase>(null);
    const {
        accountAmountRows,
        cases,
        selectedCase,
        entities,
        selectedEntity,
        jurisdictions,
        selectedJurisdiction,
        years,
        selectedYear,
        adjustments,
        selectedAdjustment,
        saveAmounts,
        updateDialogState,
        isLoading,
        saveButtonIsDisabled,
        changeTrackingData,
        onFilterUpdate,
        onFilterReset,
        amountsOnly,
        combineDebitCreditColumns,
        loadStateChangeEvent,
        journalEntryGridProps,
        journalEntryGridRef,
        journalEntryGridPopupMenuDismissHack,
    } = useEnterAmountsData(props);

    if (isLoading && loadStateChangeEvent) {
        if (loadStateChangeEvent === 'Initialize') {
            const createInitialLoadTrackingInformation: () => IEnterAmountsDialogInitialLoadTrackingInformation = () => {
                return {
                    adjustment: selectedAdjustment?.adjustmentCode! ?? 'N/A',
                    case: selectedCase?.code! ?? 'N/A',
                    columnId: props.columnId.toString(),
                    entity: selectedEntity?.code! ?? 'N/A',
                    jurisdiction: selectedJurisdiction?.code! ?? 'N/A',
                    numberOfAccounts: 0,
                    reportId: props.taxReturnKey.toString(),
                    rowNumber: props.definingRowNumber?.toString() ?? props.drillDownRowNumber.toString(),
                    success: false,
                    year: selectedYear?.periodYear?.toString() ?? 'N/A',
                };
            };

            trackEnterAmountsDialogInitialLoadBegin(createInitialLoadTrackingInformation);
        } else if (loadStateChangeEvent === 'FilterChange') {
            const createFilterChangeTrackingInformation: () => IEnterAmountsDialogFilterChangeTrackingInformation = () => {
                return {
                    adjustment: selectedAdjustment?.adjustmentCode! ?? 'N/A',
                    case: selectedCase?.code! ?? 'N/A',
                    columnId: props.columnId.toString(),
                    entity: selectedEntity?.code! ?? 'N/A',
                    jurisdiction: selectedJurisdiction?.code! ?? 'N/A',
                    numberOfAccounts: 0,
                    reportId: props.taxReturnKey.toString(),
                    rowNumber: props.definingRowNumber?.toString() ?? props.drillDownRowNumber.toString(),
                    success: false,
                    year: selectedYear?.periodYear?.toString() ?? 'N/A',
                };
            };

            trackEnterAmountsDialogFilterChangeBegin(createFilterChangeTrackingInformation);
        } else if (loadStateChangeEvent === 'SaveAmounts') {
            const createSaveAmountsTrackingInformation: () => IEnterAmountsDialogSaveAmountsTrackingInformation = () => {
                return {
                    columnId: props.columnId.toString(),
                    numberOfAccountsSaved: 0,
                    numberOfAmountsSaved: 0,
                    reportId: props.taxReturnKey.toString(),
                    rowNumber: props.definingRowNumber?.toString() ?? props.drillDownRowNumber.toString(),
                    success: false,
                };
            };

            trackEnterAmountsDialogSaveAmountsBegin(createSaveAmountsTrackingInformation);
        }
    }

    const { functionalAccess } = useFunctionalAccess();
    const {
        onCellSave,
        onGridCreated,
        onCellEdit,
        onRowDataBound,
        onGridDataBound,
        onKeyPressed,
        contextMenuItems,
        handleContextMenuItemClick,
        handleContextMenuOpen,
    } = useEnterAmountsGridComponentProps(
        gridRef,
        updateDialogState,
        saveButtonIsDisabled,
        journalEntryGridRef,
        journalEntryGridPopupMenuDismissHack,
        functionalAccess!
    );
    const { modalStyles, dialogContentStyles, gridComponentCSSClass, totalRowStyle, spinnerClassName, buttonOverrides, dialogBodyStyles } =
        useEnterAmountsDialogStyles();
    const closeLabel = useMemo(() => t('close'), [t]);
    const cancelLabel = useMemo(() => t('cancel'), [t]);
    const confirmLabel = useMemo(() => t('save'), [t]);
    const enterAmountsHeaderText = useMemo(() => t('enterAmountsHeader'), [t]);
    const journalEntryHeaderText = useMemo(() => t('journalEntry'), [t]);

    const primaryHandler = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            function retrieveNormalizedRowValue(row: IAccountAmountRow): string {
                let amount: string = '0';

                if (row.accountBalanceType === 'Debit') {
                    if (row.debitTotal) {
                        amount = row.debitTotal.toString();
                    } else if (row.creditTotal) {
                        amount = (row.creditTotal * -1).toString();
                    }
                } else if (row.accountBalanceType === 'Credit') {
                    if (row.creditTotal) {
                        amount = row.creditTotal.toString();
                    } else if (row.debitTotal) {
                        amount = (row.debitTotal * -1).toString();
                    }
                } else {
                    throw new Error(`Invalid account balance type: ${row.accountBalanceType}`);
                }

                return amount;
            }

            function convertIAccountAmountRowToExtractionDetailsAccountSummary(row: IAccountAmountRow): ExtractionDetailsAccountSummary {
                let amount: string = retrieveNormalizedRowValue(row);

                return {
                    accountNumber: row.accountCode,
                    adjustmentInfo: [
                        {
                            adjustmentCode: selectedAdjustment?.adjustmentCode,
                            case: selectedCase?.code,
                            corporation: selectedEntity?.code,
                            jurisdiction: selectedJurisdiction?.code,
                            location: selectedJurisdiction?.code,
                            reportAmount: amount,
                            year: selectedYear?.periodYear?.toString(),
                        },
                    ],
                    reportAmount: amount,
                };
            }

            function createSaveAmountsRequestIfRowsChanged() {
                const updatedRows: IAccountAmountRow[] = findUpdatedRows();

                if (updatedRows.length === 0) {
                    // Nothing to save.
                    return null;
                }

                const accountSummaryUpdates = updatedRows.map(convertIAccountAmountRowToExtractionDetailsAccountSummary);
                let updatedChangeTrackingData: IWhitePaperReportChanges | undefined;

                if (props.definingRowNumber) {
                    const currentContext: IReportColumnContext = {
                        case: selectedCase!.code!,
                        entity: selectedEntity!.code!,
                        jurisdiction: selectedJurisdiction!.code!,
                        year: selectedYear?.periodYear!,
                    };

                    updatedChangeTrackingData = addEnterAmountsUpdatedAccountsToTrackingData(
                        props.drillDownRowNumber,
                        props.columnId,
                        props.definingRowNumber,
                        currentContext,
                        selectedAdjustment!.adjustmentCode!,
                        updatedRows.map((row) => row.accountCode!),
                        changeTrackingData
                    );
                } else {
                    updatedChangeTrackingData = addReportCellToChangeTrackingData(
                        props.drillDownRowNumber,
                        props.columnId,
                        changeTrackingData
                    );
                }

                const saveAmountsRequest: SaveAmountsRequest = {
                    accountSummaryUpdates: accountSummaryUpdates,
                    changesTrackingData: updatedChangeTrackingData,
                    taxReturnKey: props.taxReturnKey,
                };

                return saveAmountsRequest;
            }

            function findUpdatedRows(): IAccountAmountRow[] {
                const enterAmountsGrid: GridComponent = gridRef.current!;
                const rowElements: HTMLCollectionOf<HTMLTableRowElement> = (enterAmountsGrid.getContentTable() as HTMLTableElement).rows;
                const updatedRowData: IAccountAmountRow[] = [];

                for (const rowElement of rowElements) {
                    const rowData: IEnterAmountsGridRowData = enterAmountsGrid.getRowInfo(rowElement)?.rowData as IEnterAmountsGridRowData;

                    if (rowData?.isModified) {
                        updatedRowData.push(rowData);
                    }
                }

                return updatedRowData;
            }

            const saveAmountsRequest: SaveAmountsRequest | null = createSaveAmountsRequestIfRowsChanged();

            if (saveAmountsRequest) {
                saveAmounts(
                    {
                        data: saveAmountsRequest,
                    },
                    {
                        onSettled: (
                            data: SaveAmountsResponse | undefined,
                            error: ErrorType<ProblemDetails> | null,
                            variables: { data: SaveAmountsRequest },
                            context: unknown | undefined
                        ) => {
                            if (!error) {
                                const numberOfAccountsSaved: number = variables.data.accountSummaryUpdates!.length;
                                const numberOfAmountsSaved: number = variables.data
                                    .accountSummaryUpdates!.filter((x: ExtractionDetailsAccountSummary) => x.adjustmentInfo)
                                    .flatMap((x: ExtractionDetailsAccountSummary) => x.adjustmentInfo!)
                                    .flatMap((x: ExtractionDetailsAdjustmentInfo) => x.entries).length;
                                const saveAmountsTrackingInformation: AllowNull<IEnterAmountsDialogSaveAmountsTrackingInformation> =
                                    getEnterAmountsDialogSaveAmountsTrackingInformation()!;

                                if (saveAmountsTrackingInformation) {
                                    saveAmountsTrackingInformation.numberOfAccountsSaved = numberOfAccountsSaved;
                                    saveAmountsTrackingInformation.numberOfAmountsSaved = numberOfAmountsSaved;
                                }
                            }
                        },
                        onSuccess: (
                            data: SaveAmountsResponse | undefined,
                            variables: { data: SaveAmountsRequest },
                            context: unknown | undefined
                        ) => {
                            updateDialogState({
                                type: EnterAmountsModalActionActionType.AmountsSaved,
                                changeTrackingData: variables.data.changesTrackingData!,
                            });
                        },
                    }
                );
            }
        },
        [
            updateDialogState,
            gridRef,
            gridRef.current,
            saveAmounts,
            selectedAdjustment,
            selectedCase,
            selectedEntity,
            selectedJurisdiction,
            selectedYear,
            changeTrackingData,
            props,
        ]
    );

    const editSettings: EditSettingsModel = {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        mode: 'Batch',
    };

    const numericEditParams: IEditCell = {
        params: {
            format: 'N',
            decimals: 0,
            showSpinButton: false,
            htmlAttributes: { maxlength: '15' },
            pasteHandler(e: ClipboardEvent) {
                // _this.setElementValue doesnt work when object is declared as NumericTextBox because functions are marked as private.
                //This code was given to us by Syncfusion in order to intercept paste values and change them before completing the paste event.
                const textBoxComponent = getComponent<NumericTextBoxComponent>(e.target! as HTMLElement, NumericTextBox) as any;
                const defaultValue = '';
                if (!textBoxComponent.enabled || textBoxComponent.readonly) {
                    return;
                }
                setTimeout(function () {
                    const number = deFormatNumber(textBoxComponent.element.value).trim();
                    textBoxComponent.setElementValue(number);
                    if (!textBoxComponent.numericRegex().test(textBoxComponent.element.value)) {
                        textBoxComponent.setElementValue(defaultValue);
                    }
                });
            },
            // this overrides the default keydown handler for the NumericTextBox, which increments and decrements the value by 1 on arrow up/down respectively
            // instead, we want to persist the value currently in the cell, stop editing, and navigate to the next cell
            keyDownHandler(e: KeyboardEvent) {
                if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
                    // all of this code comes directly from the Syncfusion NumericTextBox source code, which
                    // is why it is using deprecated function calls
                    var evt = document.createEvent('HTMLEvents');
                    evt.initEvent('change', false, true);
                    const textBoxComponent = getComponent<NumericTextBoxComponent>(e.target! as HTMLElement, NumericTextBox) as any;
                    textBoxComponent.element.dispatchEvent(evt);
                }
            },
            // this is the only way we are able to intercept the focusOut event on the NumericTextBox when a user hits the tab key
            // without this, the textbox will not go away, and keyboard focus will move outside of the grid
            focusOutHandler(e: FocusEvent) {
                gridRef?.current?.editModule?.saveCell();
            },
        } as NumericTextBoxModel,
    };

    const numberFormatOptions = {
        format: 'N',
        useGrouping: true,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    } as NumberFormatOptions;

    const totalRowTemplate = useCallback(
        (props: any) => {
            const className = mergeStylesOnDemand(totalRowStyle)();
            return <span className={className}>{t('total')}</span>;
        },
        [mergeStylesOnDemand, t, totalRowStyle]
    );

    const onDialogOpenChange: DialogOpenChangeEventHandler = (event: DialogOpenChangeEvent, data: DialogOpenChangeData) => {
        if (!data.open) {
            if (data.type === 'escapeKeyDown') {
                event.preventDefault();
                event.stopPropagation();
            }
            if (props.buttonTrigger) {
                props.buttonTrigger.focus();
            }
            props.onClose && props.onClose(changeTrackingData !== props.changeTrackingData, changeTrackingData);
        }
    };

    const cancelButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    const syncfusionFocusHack: KeyboardEventHandler<HTMLDivElement> = getSyncfusionFocusEventHandlerHack(cancelButtonRef);
    const syncfusionGridFilterDismissHack: ISyncfusionGridPopupMenuDismissHack = getSyncfusionPopupMenuDismissHack(
        gridRef,
        gridFilterMenuRef
    );

    const adjustedAmountFormat = '#,###;(#,###);0';
    const filterOptions: FilterSettingsModel = {
        type: 'CheckBox',
    };

    useEffect(() => {
        const enterAmountsGrid: GridComponent | null = gridRef.current;

        if (enterAmountsGrid) {
            const amountFilterApplied: boolean = !!((enterAmountsGrid.filterModule as any)?.actualPredicate as any)[
                accountAmountRowPropertyNames.adjustedTotal
            ];

            if (amountsOnly && !amountFilterApplied) {
                enterAmountsGrid.filterByColumn(accountAmountRowPropertyNames.adjustedTotal, 'notequal', null);
            } else if (!amountsOnly && amountFilterApplied) {
                enterAmountsGrid.clearFiltering([accountAmountRowPropertyNames.adjustedTotal]);
            }
        }
    }, [gridRef, gridRef?.current, amountsOnly]);

    useEffect(() => {
        const enterAmountsGrid: GridComponent | null = gridRef.current;
        if (enterAmountsGrid) {
            const creditDebitColumnsAreCombined: boolean = combineDebitCreditColumns;
            if (creditDebitColumnsAreCombined) {
                enterAmountsGrid.showColumns(accountAmountRowPropertyNames.combinedTotal, 'field');
                enterAmountsGrid.hideColumns(
                    [accountAmountRowPropertyNames.debitTotal, accountAmountRowPropertyNames.creditTotal],
                    'field'
                );
            } else {
                enterAmountsGrid.hideColumns(accountAmountRowPropertyNames.combinedTotal, 'field');
                enterAmountsGrid.showColumns(
                    [accountAmountRowPropertyNames.debitTotal, accountAmountRowPropertyNames.creditTotal],
                    'field'
                );
            }
        }
    }, [gridRef, gridRef?.current, combineDebitCreditColumns]);

    const onDialogSurfaceClick = (event: React.MouseEvent<HTMLDivElement>) => {
        syncfusionGridFilterDismissHack.dialogSurfaceClick(event);
        journalEntryGridPopupMenuDismissHack.dialogSurfaceClick(event);
    };

    const renderedComppnent: JSX.Element = (
        <Dialog open={true} onOpenChange={onDialogOpenChange}>
            <DialogSurface className={`${modalStyles}`} aria-modal='false' onKeyDown={syncfusionFocusHack} onClick={onDialogSurfaceClick}>
                <DialogBody className={dialogBodyStyles}>
                    <DialogTitle
                        action={
                            <DialogTrigger action='close'>
                                <Button ref={cancelButtonRef} appearance='subtle' aria-label={closeLabel} icon={<Dismiss24Regular />} />
                            </DialogTrigger>
                        }
                    >
                        {!journalEntryGridProps && (
                            <>
                                {enterAmountsHeaderText}
                                <div style={{ fontFamily: 'Segoe UI', fontSize: 14, fontStyle: 'normal', fontWeight: 400 }}>
                                    {props.rowDescription}
                                </div>
                            </>
                        )}
                        {journalEntryGridProps && (
                            <>
                                {journalEntryHeaderText}
                                <div style={{ fontFamily: 'Segoe UI', fontSize: 14, fontStyle: 'normal', fontWeight: 400 }}>
                                    {`${t('entity')}: ${selectedEntity?.code} ${t('case')}: ${selectedCase?.code} ${t('jurisdiction')}: ${
                                        selectedJurisdiction?.code
                                    } ${t('year')}: ${selectedYear?.periodYear}`}
                                </div>
                            </>
                        )}
                    </DialogTitle>
                    <DialogContent className={dialogContentStyles}>
                        {!isLoading && !journalEntryGridProps && (
                            <div
                                id={enterAmountsModalId}
                                style={{
                                    display: isLoading ? 'none' : 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    gap: '24px',
                                    height: 'inherit',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                        gap: '12',
                                        height: 'inherit',
                                    }}
                                >
                                    <EnterAmountsDialogFilters
                                        entityFilter={{
                                            options: entities,
                                            selectedOption: selectedEntity,
                                        }}
                                        caseFilter={{
                                            options: cases,
                                            selectedOption: selectedCase,
                                        }}
                                        jurisdictionFilter={{
                                            options: jurisdictions,
                                            selectedOption: selectedJurisdiction,
                                        }}
                                        yearFilter={{
                                            options: years,
                                            selectedOption: selectedYear,
                                        }}
                                        adjustmentFilter={{
                                            options: adjustments,
                                            selectedOption: selectedAdjustment,
                                            clearable: props.adjustmentCode ? false : true,
                                        }}
                                        onFilterUpdate={onFilterUpdate}
                                        onFilterReset={onFilterReset}
                                        amountsOnly={amountsOnly}
                                        combineDebitCreditColumns={combineDebitCreditColumns}
                                    />
                                </div>

                                <div>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='7' height='412' viewBox='0 0 7 412' fill='none'>
                                        <path
                                            d='M1.00002 -2.18556e-07L1.00001 186.736C1.00001 187.058 1.07786 187.375 1.22692 187.661L5.54771 195.941C5.8331 196.488 5.85015 197.136 5.59393 197.697L1.1807 207.362C1.06163 207.623 1.00001 207.906 1.00001 208.193L1 412'
                                            stroke={tokens.colorNeutralStroke1}
                                        />
                                    </svg>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        gap: '12px',
                                        height: 'inherit',
                                    }}
                                >
                                    <GridStateContextProvider grid={gridRef}>
                                        <GridComponent
                                            className={gridComponentCSSClass}
                                            dataSource={accountAmountRows}
                                            editSettings={editSettings}
                                            cellEdit={onCellEdit}
                                            ref={gridRef}
                                            cellSave={onCellSave}
                                            rowDataBound={onRowDataBound}
                                            created={onGridCreated}
                                            keyPressed={onKeyPressed}
                                            rowHeight={28}
                                            dataBound={onGridDataBound}
                                            allowPaging={true}
                                            pageSettings={{ pageSize: 1000 }}
                                            filterSettings={filterOptions}
                                            allowSorting={true}
                                            allowFiltering={true}
                                            actionComplete={syncfusionGridFilterDismissHack.gridActionComplete}
                                            height={'100%'}
                                            contextMenuItems={contextMenuItems}
                                            contextMenuClick={handleContextMenuItemClick}
                                            contextMenuOpen={handleContextMenuOpen}
                                        >
                                            <ColumnsDirective>
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.accountCode}
                                                    headerText={t('account').toString()}
                                                    textAlign='Left'
                                                    type='string'
                                                    width='114'
                                                    allowEditing={false}
                                                    // if one of the columns is not designated as the primary key, aggregation will not work.
                                                    // without this, the sum will be calculated as the amount in the first cell multiplied
                                                    // by the number of rows in the table
                                                    isPrimaryKey={true}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.accountCode }}
                                                />
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.accountDescription}
                                                    headerText={t('description').toString()}
                                                    textAlign='Left'
                                                    type='string'
                                                    width='206'
                                                    allowEditing={false}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.accountDescription }}
                                                />
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.accountBalanceType}
                                                    headerText={t('type').toString()}
                                                    textAlign='Left'
                                                    type='string'
                                                    width='96'
                                                    allowEditing={false}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.accountBalanceType }}
                                                />
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.debitTotal}
                                                    headerText={t('debit').toString()}
                                                    textAlign='Right'
                                                    type='number'
                                                    width='140'
                                                    format={numberFormatOptions}
                                                    edit={numericEditParams}
                                                    editType='numericedit'
                                                    allowFiltering={false}
                                                    allowEditing={functionalAccess?.amountDataEntryModifyEnabled}
                                                    allowSorting={false}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.debitTotal }}
                                                />
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.creditTotal}
                                                    headerText={t('credit').toString()}
                                                    textAlign='Right'
                                                    type='number'
                                                    width='140'
                                                    format={numberFormatOptions}
                                                    edit={numericEditParams}
                                                    editType='numericedit'
                                                    allowFiltering={false}
                                                    allowEditing={functionalAccess?.amountDataEntryModifyEnabled}
                                                    allowSorting={false}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.creditTotal }}
                                                />
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.combinedTotal}
                                                    headerText={t('combinedDebitCreditHeader').toString()}
                                                    textAlign='Right'
                                                    type='number'
                                                    width='280'
                                                    format={adjustedAmountFormat}
                                                    edit={numericEditParams}
                                                    editType='numericedit'
                                                    allowFiltering={false}
                                                    allowEditing={functionalAccess?.amountDataEntryModifyEnabled}
                                                    allowSorting={false}
                                                    visible={false}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.combinedTotal }}
                                                />
                                                <ColumnDirective
                                                    field={accountAmountRowPropertyNames.adjustedTotal}
                                                    headerText={t('adjustedTotal').toString()}
                                                    textAlign='Right'
                                                    type='number'
                                                    width='150'
                                                    format={adjustedAmountFormat}
                                                    edit={numericEditParams}
                                                    editType='numericedit'
                                                    allowFiltering={true}
                                                    allowSorting={false}
                                                    customAttributes={{ class: accountAmountRowPropertyNames.adjustedTotal }}
                                                />
                                            </ColumnsDirective>
                                            <Inject services={[Edit, Aggregate, Page, Filter, Sort, ContextMenu]} />
                                            <AggregatesDirective>
                                                <AggregateDirective>
                                                    <AggregateColumnsDirective>
                                                        <AggregateColumnDirective
                                                            footerTemplate={totalRowTemplate}
                                                            // the aggregation type doesn't matter. you just have to supply something so the row will render
                                                            type='Min'
                                                            // by picking the accountCode field, we make sure the template will render in the correct position within the grid
                                                            field={accountAmountRowPropertyNames.accountCode}
                                                        />
                                                        <AggregateColumnDirective
                                                            type='Sum'
                                                            field={accountAmountRowPropertyNames.debitTotal}
                                                            format={numberFormatOptions}
                                                        />
                                                        <AggregateColumnDirective
                                                            type='Sum'
                                                            field={accountAmountRowPropertyNames.creditTotal}
                                                            format={numberFormatOptions}
                                                        />
                                                        <AggregateColumnDirective
                                                            type='Sum'
                                                            field={accountAmountRowPropertyNames.combinedTotal}
                                                            format={adjustedAmountFormat}
                                                        />
                                                        <AggregateColumnDirective
                                                            type='Sum'
                                                            field={accountAmountRowPropertyNames.adjustedTotal}
                                                            format={adjustedAmountFormat}
                                                        />
                                                    </AggregateColumnsDirective>
                                                </AggregateDirective>
                                            </AggregatesDirective>
                                        </GridComponent>
                                    </GridStateContextProvider>
                                </div>
                            </div>
                        )}
                        {!isLoading && journalEntryGridProps && <JournalEntryGrid {...journalEntryGridProps} />}

                        {isLoading && (
                            <LoadingSpinner
                                id='loadingSpinnerEnterAmountsGrid'
                                containerStyle={spinnerClassName}
                                label={t('loadingPleaseWait').toString()}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        {!journalEntryGridProps && (
                            <>
                                <Button
                                    appearance='primary'
                                    onClick={primaryHandler}
                                    disabled={saveButtonIsDisabled}
                                    className={buttonOverrides}
                                >
                                    {confirmLabel}
                                </Button>
                                <DialogTrigger>
                                    <Button className={buttonOverrides}>{cancelLabel}</Button>
                                </DialogTrigger>
                            </>
                        )}
                        {journalEntryGridProps && (
                            <Button
                                onClick={() => updateDialogState({ type: EnterAmountsModalActionActionType.JournalEntryClosed })}
                                className={buttonOverrides}
                            >
                                Back
                            </Button>
                        )}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );

    if (!isLoading && loadStateChangeEvent) {
        if (loadStateChangeEvent === 'Initialize') {
            const trackingInformation: IEnterAmountsDialogInitialLoadTrackingInformation =
                getEnterAmountsDialogInitialLoadTrackingInformation()!;

            trackingInformation.numberOfAccounts = accountAmountRows?.length ?? 0;
            trackingInformation.success = true;
            trackEnterAmountsDialogInitialLoadEnd();
        } else if (loadStateChangeEvent === 'FilterChange') {
            const trackingInformation: IEnterAmountsDialogFilterChangeTrackingInformation =
                getEnterAmountsDialogFilterChangeTrackingInformation()!;

            trackingInformation.numberOfAccounts = accountAmountRows?.length ?? 0;
            trackingInformation.success = true;
            trackEnterAmountsDialogFilterChangeEnd();
        } else if (loadStateChangeEvent === 'SaveAmounts') {
            trackEnterAmountsDialogSaveAmountsEnd();
        }
    }

    return renderedComppnent;
};

export default EnterAmountsDialog;

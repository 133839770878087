import { useCorptaxTheme } from '@corptax/react-components-common';
import { DirectionalHint, IconButton, ITooltipHostProps, Stack, TooltipHost } from '@fluentui/react';
import { Combobox, makeStyles, Option, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';
import { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormPdfView } from '../../contexts/useFormPdfView';
import { useCommonIndividualReportTableHeaderStyles } from '../../hooks/useCommonIndividualReportTableHeaderStyles';
import { IAppTheme } from '../../theme/IAppTheme';
import { getTooltipHostProps } from '../../utils/CustomStyles';
import Icon from '../common/Icon';

export interface IPdfZoomControlsProps {
    pdfViewer?: PdfViewerComponent;
}

const useStyles = makeStyles({
    root: {
        border: 'none',
        minWidth: '2.2rem',
        '& input': {
            width: '2.2rem',
            paddingLeft: 0,
        },
    },
});

export const PdfZoomControls: React.FC<IPdfZoomControlsProps> = ({ pdfViewer }) => {
    const { t } = useTranslation();

    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { iconButton, iconWrapper } = useCommonIndividualReportTableHeaderStyles();
    const comboBoxStyles = useStyles();

    const [selectedKey, setSelectedKey] = React.useState<string>('1');
    const { pdfSettings } = useFormPdfView();
    const fitToPageOption = { key: 'fitToPage', text: t('fitPage') };
    const fitToWidthOption = { key: 'fitToWidth', text: t('fitWidth') };
    const fitAutoOption = { key: 'auto', text: t('automatic') };
    const options = [
        { key: '0.1', text: '10%' },
        { key: '0.25', text: '25%' },
        { key: '0.5', text: '50%' },
        { key: '0.75', text: '75%' },
        { key: '1', text: '100%' },
        { key: '1.25', text: '125%' },
        { key: '1.5', text: '150%' },
        { key: '2', text: '200%' },
        { key: '4', text: '400%' },
        fitToPageOption,
        fitToWidthOption,
        fitAutoOption,
    ];
    const zoomPercentage = `${Math.floor(pdfSettings.currentZoom)}%`;

    const isMinimumZoomFactor = selectedKey === '0.1';
    const isMaximumZoomFactor = selectedKey === '4';

    const zoomOut = () => {
        if (pdfViewer) {
            pdfViewer.magnification.zoomOut();
            setSelectedKey(`${pdfViewer.magnification.zoomFactor}`);
        }
    };

    const zoomIn = () => {
        if (pdfViewer) {
            pdfViewer.magnification.zoomIn();
            setSelectedKey(`${pdfViewer.magnification.zoomFactor}`);
        }
    };

    useEffect(() => {
        const selectedIndex = options.findIndex((option) => option.text === zoomPercentage);
        if (pdfViewer?.magnification.fitType === 'fitToPage' || pdfViewer?.magnification.fitType === 'fitToWidth') {
            setSelectedKey(pdfViewer.magnification.fitType);
        } else if (selectedIndex !== -1) {
            setSelectedKey(options[selectedIndex].key);
        } else {
            setSelectedKey('');
        }
    }, [pdfSettings.currentZoom]);

    const selectZoomFactor = (_: SelectionEvents, data: OptionOnSelectData): void => {
        if (data && data.optionValue) {
            const key: string = data.optionValue;
            setSelectedKey(key);

            if (pdfViewer) {
                if (key === fitToPageOption.key) {
                    pdfViewer.magnification.fitToPage();
                } else if (key === fitToWidthOption.key) {
                    pdfViewer.magnification.fitToWidth();
                } else if (key === fitAutoOption.key) {
                    pdfViewer.magnification.fitToAuto();
                } else {
                    pdfViewer.magnification.zoomTo(parseFloat(key) * 100);
                }
            }
        }
    };

    const tooltipHostZoomOutProps: Partial<ITooltipHostProps> = getTooltipHostProps(DirectionalHint.topCenter, undefined, customPalette);
    tooltipHostZoomOutProps.content = t('zoomOut').toString();
    tooltipHostZoomOutProps.id = 'zoomOut';

    const tooltipHostZoomInProps: Partial<ITooltipHostProps> = getTooltipHostProps(DirectionalHint.topCenter, undefined, customPalette);
    tooltipHostZoomInProps.content = t('zoomIn').toString();
    tooltipHostZoomInProps.id = 'zoomIn';

    return (
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 5 }}>
            <TooltipHost {...tooltipHostZoomOutProps}>
                <IconButton
                    className={iconButton}
                    ariaLabel={t('zoomOut').toString()}
                    disabled={isMinimumZoomFactor}
                    allowDisabledFocus
                    onClick={zoomOut}
                >
                    <Stack className={iconWrapper}>
                        <Icon iconName='SubtractCircle20Regular' />
                    </Stack>
                </IconButton>
            </TooltipHost>
            <TooltipHost {...tooltipHostZoomInProps}>
                <IconButton
                    className={iconButton}
                    ariaLabel={t('zoomIn').toString()}
                    disabled={isMaximumZoomFactor}
                    allowDisabledFocus
                    onClick={zoomIn}
                >
                    <Stack className={iconWrapper}>
                        <Icon iconName='AddCircle20Regular' />
                    </Stack>
                </IconButton>
            </TooltipHost>
            <Combobox
                data-testid='zoom-combobox'
                value={zoomPercentage}
                selectedOptions={[selectedKey]}
                className={comboBoxStyles.root}
                onOptionSelect={selectZoomFactor}
            >
                {options.map((option) => (
                    <Option key={option.key} value={option.key} text={option.text}>
                        {option.text}
                    </Option>
                ))}
            </Combobox>
        </Stack>
    );
};

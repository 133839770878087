import { ColumnDirective, ColumnsDirective, Filter, FilterSettingsModel, GridComponent, Inject, Sort } from '@syncfusion/ej2-react-grids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetJournalEntries } from '../../api/amount/amount';
import { GridStateContextProvider } from '../../contexts/gridStateContext';
import { useEnterAmountsDialogStyles } from '../../hooks/useEnterAmountsDialogStyles';
import { useJournalEntryDialogStyles } from '../../hooks/useJournalEntryDialogStyles';
import { GetJournalEntriesResponse, IJournalEntry } from '../../model';
import LoadingSpinner from '../common/LoadingSpinner';
import { IJournalEntryGridProps } from './IJournalEntryGridProps';

export const JournalEntryGrid: FC<IJournalEntryGridProps> = (props: IJournalEntryGridProps) => {
    const numberFormat: string = '#,###;(#,###);0';
    const { t } = useTranslation();
    const { gridComponentCSSClass } = useJournalEntryDialogStyles();
    const { spinnerClassName } = useEnterAmountsDialogStyles();
    const { data: response, isFetching } = useGetJournalEntries<GetJournalEntriesResponse>({
        AccountCode: props.accountCode,
        AdjustmentCode: props.adjustmentCode,
        CaseCode: props.caseCode,
        Jurisdiction: props.jurisdiction,
        EntityCode: props.entityCode,
        Period: props.period,
    });
    const dataSource: IJournalEntry[] | undefined = response?.journalEntries ? response.journalEntries : undefined;
    const filterOptions: FilterSettingsModel = {
        type: 'CheckBox',
    };
    return (
        <>
            {!isFetching && dataSource && (
                <GridStateContextProvider grid={props.gridRef}>
                    <GridComponent
                        dataSource={dataSource}
                        ref={props.gridRef}
                        className={gridComponentCSSClass}
                        rowHeight={28}
                        filterSettings={filterOptions}
                        allowSorting={true}
                        allowFiltering={true}
                        actionComplete={props.gridActionComplete}
                        height={'100%'}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='accountCode' headerText='Account code' type='string' width={136} />
                            <ColumnDirective field='adjustmentCode' headerText='Adjustment code' type='string' width={155} />
                            <ColumnDirective
                                field='changeInBalance'
                                headerText='Change in balance'
                                type='number'
                                headerTextAlign='Left'
                                textAlign='Right'
                                format={numberFormat}
                                width={163}
                                allowFiltering={false}
                            />
                            <ColumnDirective field='accountType' headerText='Account type' type='string' width={136} />
                            <ColumnDirective field='description' headerText='Journal entry description' type='string' width={205} />
                            <ColumnDirective field='postedDate' headerText='Posted date' type='string' width={138} />
                            <ColumnDirective field='postedBy' headerText='Posted by' type='string' width={150} />
                            <ColumnDirective field='source' headerText='Source' type='string' width={138} />
                        </ColumnsDirective>
                        <Inject services={[Filter, Sort]} />
                    </GridComponent>
                </GridStateContextProvider>
            )}
            {isFetching && (
                <LoadingSpinner
                    id='loadingSpinnerJournalEntryGrid'
                    containerStyle={spinnerClassName}
                    label={t('loadingPleaseWait').toString()}
                />
            )}
        </>
    );
};

import { Checkbox } from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDropdown from '../../dropdown/DefaultDropdown';
import { ShowableTaxReturnItemRecord } from './AddReturnPanel';

export interface IEditReturnAdvancedEntitySectionProps {
    taxReturnData: ShowableTaxReturnItemRecord | undefined;
    customGroups: any;
    customRanges: any;
    headerColumns: any;
    filterFunction: (text: string, dataSource: any) => any[];
    onChangeCheckbox: (data: any, key: string) => void;
    onChangeDropdown: (data: any, key: string) => void;
}

const EditReturnAdvancedEntitySection: React.FC<IEditReturnAdvancedEntitySectionProps> = ({
    taxReturnData,
    customGroups,
    customRanges,
    headerColumns,
    filterFunction,
    onChangeCheckbox,
    onChangeDropdown,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <DefaultDropdown
                id='customGroup'
                value={taxReturnData?.columnFileKey?.toString()}
                dataSource={customGroups}
                filterFunction={filterFunction}
                fields={{ text: 'nameAndDescription', value: 'customGroupKey' }}
                headerColumns={headerColumns}
                label={t('customGroup').toString()}
                placeholder={t('selectCustomGroup').toString()}
                onChange={(value) => onChangeDropdown(parseInt(value), 'columnFileKey')}
            />

            <Checkbox
                checked={taxReturnData?.ignoreEntityGroup}
                onChange={(_, checked) => onChangeCheckbox(checked, 'ignoreEntityGroup')}
                label={t('useGroupOnly').toString()}
            />
            <Checkbox
                checked={taxReturnData?.includeAllBU}
                onChange={(_, checked) => onChangeCheckbox(checked, 'includeAllBU')}
                label={t('includeAllBu').toString()}
            />

            <DefaultDropdown
                id='customRanges'
                value={taxReturnData?.rangeFileKey?.toString()}
                dataSource={customRanges}
                filterFunction={filterFunction}
                fields={{ text: 'nameAndDescription', value: 'rangeKey' }}
                headerColumns={headerColumns}
                label={t('customRange').toString()}
                placeholder={t('selectCustomRange').toString()}
                onChange={(value) => onChangeDropdown(parseInt(value), 'rangeFileKey')}
            />
        </>
    );
};

export default EditReturnAdvancedEntitySection;

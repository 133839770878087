import { corptaxCustomLightTheme, useCorptaxTheme } from '@corptax/react-components-common';
import { BaseButton, Button, DefaultButton, IPivot, IPivotItemProps, mergeStyleSets, Pivot, PivotItem, Stack } from '@fluentui/react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import FileSaver from 'file-saver';
import { Attributes, CSSProperties, FC, MouseEventHandler, ReactNode, RefObject, useCallback, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFeatureEnabled } from '../../api/features/features';
import {
    useExportReportCellAuditToExcel,
    useExportReportRowTraceToExcel,
    useGetReportCellAudit,
    useSaveAmounts,
} from '../../api/report/report';
import { applicationInsightsReactPlugin } from '../../ApplicationInsightsService';
import { IDrillDownValues } from '../../contexts/drillDownContext';
import { AllowUndefined } from '../../data-types/AllowUndefinedAndNull';
import { IReportDrillDownStyles } from '../../data-types/IReportDrillDownStyles';
import { IUnitTestProps } from '../../data-types/IUnitTestProps';
import { SelectedWhitePaperCell } from '../../data-types/selectedWhitePaperCell';
import { useDrillDown } from '../../hooks/useDrillDown';
import { useDrillDownStyles } from '../../hooks/useDrillDownStyles';
import { useFunctionalAccess } from '../../hooks/useFunctionalAccess';
import {
    getNumberOfAccountGroupsWithAdjustments,
    getNumberOfUniqueAccounts,
    IReportDrillDownLoadedTrackingInformation,
    IReportDrilldownSavedTrackingInformation,
    usePerformanceMetricsForDrillDown,
} from '../../hooks/usePerformanceMetricsForDrillDown';
import {
    ExtractionDetailsAccountSummary,
    ExtractionDetailsAdjustmentInfo,
    ExtractionDetailsRequest,
    IReportColumnContext,
    IWhitePaperColumn,
    IWhitePaperMappedRow,
    IWhitePaperMappedRowDictionary,
    IWhitePaperReportCellChange,
    IWhitePaperReportCellChangesSummary,
    IWhitePaperReportChanges,
    ProblemDetails,
    Report,
    ReportCellAuditExportRequest,
    ReportCellAuditRequest,
    ReportRowTraceExportRequest,
    SaveAmountsRequest,
    SaveAmountsResponse,
} from '../../model';

import {
    DrillDownStateReducer,
    drillDownStateReducer,
    IDrillDownState,
    IErrorMessage,
    IExpandCollapseAllButtonState,
    IExportButtonState,
} from '../../reducers/drillDownStateReducer';
import { IAppTheme } from '../../theme/IAppTheme';
import { findDrillDownAmountCellChanges, mergeChangeTrackingData } from '../../utils/ChangeTrackingDataUtils';
import { mergeStylesOnDemand } from '../../utils/StyleUtils';
import { ErrorType } from '../../utils/UseCustomInstance';
import { ICardGridList, ICardGridListRowData, IGroupExtended } from '../card-grid-list/CardGridListInterfaces';
import { CardGridListExpandCollapseState, OnExpandCollapseStateChangeCallback } from '../card-grid-list/CardGridListTypes';
import Icon from '../common/Icon';
import { NarrowIconButton } from '../common/NarrowIconButton';
import { ToggleWidthPanel } from '../common/ToggleWidthPanel';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import EnterAmountsDialog from '../enter-amounts-dialog/EnterAmountsDialog';
import AccountAmountSection from './AccountAmountSection';
import AccountAmountTabPanel from './AccountAmountTabPanel';
import { disabledClass, recalcNeededClass } from './AccountAmountTabPanel.constants';
import { JournalEntryDialog } from './JournalEntryDialog';
import ReportColumnContext from './ReportColumnContext';
import { DrillDownActionType } from './ReportDrillDownActions';
import {
    buildExtractionRequest,
    consolidateAmountChangesForSave,
    getDefiningRowsFromDataSource,
    getUniqueExtractionDetailRequests,
    IAccountAmountWhitePaperRow,
    ITrackedExtractionDetailsAccountSummary,
    IWhitePaperRowTrace,
} from './ReportDrillDownUtils';
import TraceCalculationTabPanel from './TraceCalculationTabPanel';

export interface IReportDrillDownProps extends IUnitTestProps {
    onClose: (changeTrackingData: { amountsChangedOnServer: boolean; reportChanges: IWhitePaperReportChanges }) => void;
    showPanel: boolean;
    report: Report;
    whitePaperReportMap: IWhitePaperMappedRowDictionary;
    whitePaperReportColumn: IWhitePaperColumn | undefined;
    changeTrackingData: IWhitePaperReportChanges;
    isMultiColumnWhitePaperReport?: boolean;
}

declare type ExpandCollapseAllOperation = 'Expand' | 'Collapse';

enum PostSaveChangesBehavior {
    SaveOnly = 0,
    SaveAndClose = 1,
}

export enum ElementPosition {
    Beginning,
    End,
}

function assignAccountSummaryEphemeralIds(summaries: ExtractionDetailsAccountSummary[]): ITrackedExtractionDetailsAccountSummary[] {
    let ephemeralKey: number = 0;

    for (const summary of Object.values<any>(summaries)) {
        summary.ephemeralKey = ++ephemeralKey;
    }

    return summaries as ITrackedExtractionDetailsAccountSummary[];
}

function addCustomPivotTabControls(instance: IPivot, styles: IReportDrillDownStyles) {
    if (instance == null) {
        return;
    }

    let customizedTabListContainer: HTMLElement | null = document.getElementById(customizedTabListId);

    if (customizedTabListContainer) {
        return;
    }

    const collapseButton: HTMLElement | null = document.getElementById(drilldownGridCollapseButtonId);
    const exportButton: HTMLElement | null = document.getElementById(drilldownExportButtonId);
    const pivotRoot: HTMLElement | null = document.getElementById(reportDrillDownPivotId);
    const tabList: HTMLElement | null = (pivotRoot?.firstElementChild as HTMLElement) ?? null;

    if (!(collapseButton && exportButton && pivotRoot && tabList)) {
        return;
    }
    customizedTabListContainer = document.createElement('div');
    customizedTabListContainer.id = customizedTabListId;
    customizedTabListContainer.className = mergeStylesOnDemand(styles.customizedTabList)();
    moveElementToNewParent(tabList, customizedTabListContainer);
    moveElementToNewParent(collapseButton, customizedTabListContainer);
    moveElementToNewParent(exportButton, customizedTabListContainer);
    moveElementToNewParent(customizedTabListContainer, pivotRoot, ElementPosition.Beginning);
    tabList.style.flexGrow = '1';
}

function moveElementToNewParent(element: HTMLElement, newParent: HTMLElement, elementPosition: ElementPosition = ElementPosition.End) {
    element.style.display = '';
    if (element.parentElement) {
        element.parentElement.removeChild(element);
    }

    switch (elementPosition) {
        case ElementPosition.End:
            newParent.appendChild(element);
            break;
        case ElementPosition.Beginning:
            const firstChild = newParent.firstElementChild;
            if (firstChild) {
                newParent.insertBefore(element, firstChild);
            }
            break;
    }
}

function handleExpandCollapseAllButtonClick(
    drillDownCtxData: IDrillDownValues,
    getActiveGridRefObject: (selectedIndex: number) => ICardGridList | null,
    operation: ExpandCollapseAllOperation
) {
    const activeGrid: ICardGridList | null = getActiveGridRefObject(drillDownCtxData.selectedTabKey);

    if (operation === 'Expand') {
        activeGrid?.expandAll();
    } else {
        activeGrid?.collapseAll();
    }
}

function handleExportButtonClick(selectedPivotIndex: number, exportFunctionMap: Map<number, () => void>) {
    if (!exportFunctionMap) {
        return;
    }
    const exportFunction: (() => void) | undefined = exportFunctionMap.get(selectedPivotIndex);

    if (exportFunction) {
        exportFunction();
    }
}

const customizedTabListId: string = 'customizedTabList';
const drilldownGridCollapseButtonId: string = 'drilldownGridCollapseButton';
const drilldownExportButtonId: string = 'drilldownExportButton';
const reportDrillDownPivotId: string = 'reportDrillDownPivot';

const ReportDrillDown: FC<IReportDrillDownProps> = (props: IReportDrillDownProps) => {
    const { trackDrilldownLoading, trackDrilldownLoaded, trackDrilldownSaving, trackDrilldownSaved } = usePerformanceMetricsForDrillDown();
    const { t } = useTranslation();
    const { styles } = useDrillDownStyles();
    const { data: isJournalEntryEnabled } = useIsFeatureEnabled({ featureFlagName: 'JournalEntryEnabled' });
    const { customPalette } = useCorptaxTheme<IAppTheme>();

    const expandText: string = t('expand');
    const collapseText: string = t('collapse');
    const accountAmountTabText: string = t('accountAmount').toString();
    const traceCalculationTabText: string = t('traceCalculation').toString();
    const getExpandCollapseAllTextForOperation = useCallback(
        (operation: ExpandCollapseAllOperation) => (operation === 'Expand' ? expandText : collapseText),
        [expandText, collapseText]
    );
    const headerText: string = t('reportDrillDown').toString();
    const drillDownCtxData: IDrillDownValues = useDrillDown();
    const whitePaperCellDataRowIndex = 1;
    const whitePaperReportMap: IWhitePaperMappedRowDictionary = props.whitePaperReportMap;

    interface IReportDrillDownPivotItem extends Attributes {
        componentRef: RefObject<ICardGridList>;
        value: IPivotItemProps;
    }

    const initialDrillDownState: IDrillDownState = {
        activeTabIndex: drillDownCtxData.selectedTabKey,
        exportButtonStateMap: new Map<number, IExportButtonState>([
            [0, { onClick: exportAccountAmount, isProcessing: false } as IExportButtonState],
            [1, { onClick: exportTraceCalculationTab, isProcessing: false } as IExportButtonState],
        ]),
        expandCollapseAllButtonState: new Map<number, IExpandCollapseAllButtonState>([
            [0, { disabled: true, expandCollapseState: CardGridListExpandCollapseState.AllCollapsed } as IExpandCollapseAllButtonState],
            [1, { disabled: true, expandCollapseState: CardGridListExpandCollapseState.AllCollapsed } as IExpandCollapseAllButtonState],
        ]),
        showConfirmationDialog: false,
        currentAccountAmountSummaries: [] as ITrackedExtractionDetailsAccountSummary[],
        isInitializing: true,
        errorMessages: [] as IErrorMessage[],
        isServerError: false,
        initialAccountAmountSummaries: null,
        isFetchingOrSavingData: false,
        amountsChangedOnServer: false,
        accountAmountGridHasData: false,
        changeTrackingData: props.changeTrackingData,
        updatedChangeTrackingData: {},
    } as IDrillDownState;
    const [drillDownState, updateDrillDownState] = useReducer<DrillDownStateReducer>(drillDownStateReducer, initialDrillDownState);

    // migrated from AccountAmountTabPanel
    const showConfirmationDialog: boolean = drillDownState.showConfirmationDialog;
    const currentAccountAmountSummaries = drillDownState.currentAccountAmountSummaries;
    const isFetchingOrSavingData = drillDownState.isFetchingOrSavingData;
    const errorMessages = drillDownState.errorMessages;
    const isServerError = drillDownState.isServerError;
    const initialAccountAmountSummaries = drillDownState.initialAccountAmountSummaries;
    const { mutate: saveAmounts } = useSaveAmounts();
    const { mutate: getReportCellAudit } = useGetReportCellAudit();
    const { functionalAccess } = useFunctionalAccess();

    const isSaveButtonDisabled = currentAccountAmountSummaries?.length < 1 || isFetchingOrSavingData;
    const isDataLoaded = !!initialAccountAmountSummaries;
    const selectedCell: SelectedWhitePaperCell = drillDownCtxData.traceCalculationData![whitePaperCellDataRowIndex];
    const refAccountAmountTab: RefObject<ICardGridList> = useRef<ICardGridList>(null);
    const refTraceCalculationTab: RefObject<ICardGridList> = useRef<ICardGridList>(null);
    const expandButtonState: CardGridListExpandCollapseState = drillDownState.expandCollapseAllButtonState.get(
        drillDownState.activeTabIndex
    )!.expandCollapseState;
    const accountAmountGridHasData: boolean = initialAccountAmountSummaries === null ? false : initialAccountAmountSummaries.length > 0;
    const expandCollapseAllButtonText: string = useMemo(() => {
        if (drillDownState.activeTabIndex === 0) {
            if (expandButtonState === CardGridListExpandCollapseState.AllCollapsed) {
                return expandText;
            }
            return collapseText;
        }
        return collapseText;
    }, [expandText, collapseText, drillDownState.activeTabIndex, expandButtonState]);
    const expandCollapseAllButtonDisabled: boolean = useMemo(
        () =>
            drillDownState.activeTabIndex === 0
                ? !accountAmountGridHasData
                : expandButtonState === CardGridListExpandCollapseState.AllCollapsed,
        [drillDownState.activeTabIndex, accountAmountGridHasData, expandButtonState]
    );

    const onAccountAmountExpandCollapseStateChange: OnExpandCollapseStateChangeCallback = useCallback(
        (updatedExpandCollapseState: CardGridListExpandCollapseState) =>
            updateDrillDownState({
                type: DrillDownActionType.CardGridListExpandCollapseStateChange,
                eventTargetTabIndex: 0,
                updatedExpandCollapseState,
            }),
        [updateDrillDownState]
    );
    const onTraceCalculationExpandCollapseStateChange: OnExpandCollapseStateChangeCallback = useCallback(
        (updatedExpandCollapseState: CardGridListExpandCollapseState) =>
            updateDrillDownState({
                type: DrillDownActionType.CardGridListExpandCollapseStateChange,
                eventTargetTabIndex: 1,
                updatedExpandCollapseState,
            }),
        [updateDrillDownState]
    );
    const amountsChangedOnServer: boolean = drillDownState.amountsChangedOnServer;
    const reportChanges: IWhitePaperReportChanges = drillDownState.changeTrackingData;

    const onAmountCellValueChange = useCallback(
        (event: any, item: IAccountAmountWhitePaperRow): void => {
            updateDrillDownState({
                type: DrillDownActionType.AmountEdited,
                event,
                item,
                rowNumber: Number.parseInt(selectedCell.displayRowNumber!),
                columnId: props.whitePaperReportColumn!.originalColumnId!,
                report: props.report,
            });
        },
        [updateDrillDownState]
    );

    const renderAdjustmentAccountAssociationDialogButton = useCallback(
        (item?: IAccountAmountWhitePaperRow) => {
            // Only render if row is an adjustment entry or if row is an adjustment summary without child adjustment entry rows
            if (
                !(
                    item &&
                    (item.dataObjectType === 'AdjustmentEntry' ||
                        (item.dataObjectType === 'AdjustmentSummary' && item.adjustmentEntries?.length === 0)) &&
                    item.isComplexAdjustment
                )
            ) {
                return null;
            }

            const onButtonClick: MouseEventHandler<
                HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button
            > = (
                event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button>
            ) => {
                updateDrillDownState({
                    type: DrillDownActionType.EnterAmountsDialogOpened,
                    enterAmountsDialogProps: {
                        buttonTrigger: event.target as HTMLElement,
                        case: item.dataObject.case,
                        jurisdiction: item.dataObject.jurisdiction ?? item.dataObject.location,
                        period: item.dataObject.year,
                        entity: item.dataObject.corporation,
                        adjustmentCode: item.dataObject.adjustmentCode ?? item.parent?.adjustmentCode,
                        location: item.dataObject.location,
                        definingRowNumber: Number.parseInt(item.rowNumber),
                    },
                });
            };
            return (
                <DefaultButton onClick={onButtonClick} styles={styles.adjustmentAccountAssociationDialogButton} tabIndex={0}>
                    <Icon
                        iconName='TextBulletListSquareEdit20Regular'
                        style={{ color: corptaxCustomLightTheme.colorBrandForeground2 }}
                    ></Icon>
                </DefaultButton>
            );
        },
        [updateDrillDownState, styles.adjustmentAccountAssociationDialogButton]
    );

    const renderJournalEntryDialogButton = useCallback(
        (item?: IAccountAmountWhitePaperRow | undefined): JSX.Element | null => {
            if (!isJournalEntryEnabled) {
                return null;
            }
            if (!item) {
                return null;
            }
            if (item.dataObjectType === 'AccountSummary') {
                return null;
            }
            if (item.dataObjectType === 'AdjustmentSummary' && (!item.adjustmentEntries || item.adjustmentEntries.length > 0)) {
                return null;
            }
            if (item?.dataObjectType === 'BookSummary' && (!item.bookEntries || item.bookEntries.length > 0)) {
                return null;
            }
            const onButtonClick: MouseEventHandler<
                HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button
            > = (
                event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton | Button>
            ) => {
                updateDrillDownState({
                    type: DrillDownActionType.JournalEntryDialogOpened,
                    journalEntryDialogProps: {
                        buttonTrigger: event.target as HTMLElement,
                        onClose: () => {
                            updateDrillDownState({ type: DrillDownActionType.JournalEntryDialogClosed });
                        },
                        rowDescription: `${t('entity')}: ${item?.entity ?? item.dataObject.corporation} ${t('case')}: ${
                            item?.dataObject.case
                        } ${t('jurisdiction')}: ${item?.dataObject.jurisdiction ?? item?.dataObject.location} ${t('year')}: ${
                            item?.dataObject.year
                        }`,
                        accountCode: item?.account ?? '',
                        adjustmentCode: item?.parent?.adjustmentCode ?? '',
                        caseCode: item?.dataObject.case,
                        jurisdiction: item?.dataObject.jurisdiction ?? item?.dataObject.location,
                        entityCode: item?.entity ?? item.dataObject.corporation ?? '',
                        period: item?.dataObject.year ?? '',
                    },
                });
            };
            return (
                <DefaultButton
                    onClick={onButtonClick}
                    disabled={!functionalAccess?.journalEntryAccessEnabled}
                    styles={styles.adjustmentAccountAssociationDialogButton}
                    tabIndex={0}
                >
                    <Icon
                        iconName='DocumentBulletListClock20Regular'
                        style={{
                            color: functionalAccess?.journalEntryAccessEnabled
                                ? corptaxCustomLightTheme.colorBrandForeground2
                                : customPalette.neutralTertiary,
                        }}
                    ></Icon>
                </DefaultButton>
            );
        },
        [isJournalEntryEnabled, corptaxCustomLightTheme, styles.adjustmentAccountAssociationDialogButton]
    );

    const accountAmountTabPanel = useMemo(() => {
        return (
            <AccountAmountTabPanel
                isUnitTestExecution={props.isUnitTestExecution}
                cardGridListRef={refAccountAmountTab}
                onExpandCollapseStateChange={onAccountAmountExpandCollapseStateChange}
                key={`AccountAmountTabPanel ${drillDownCtxData.traceCalculationData[whitePaperCellDataRowIndex].displayRowNumber}`}
                currentAccountAmountSummaries={currentAccountAmountSummaries}
                isFetchingOrSavingData={isFetchingOrSavingData}
                isDataLoaded={isDataLoaded}
                initialAccountAmountSummaries={initialAccountAmountSummaries}
                selectedCell={selectedCell}
                onAmountCellValueChange={onAmountCellValueChange}
                updateDrillDownState={updateDrillDownState}
                changeTrackingData={props.changeTrackingData}
                whitePaperReportColumn={props.whitePaperReportColumn!}
                taxReturnKey={props.report.id!}
                renderAdjustmentAccountAssociationDialogButton={renderAdjustmentAccountAssociationDialogButton}
                report={props.report}
                isAmountDataEntryModifyEnabled={functionalAccess?.amountDataEntryModifyEnabled!}
                renderJournalEntryDialogButton={renderJournalEntryDialogButton}
            />
        );
    }, [
        props.onClose,
        amountsChangedOnServer,
        props.isUnitTestExecution,
        currentAccountAmountSummaries,
        isFetchingOrSavingData,
        isDataLoaded,
        initialAccountAmountSummaries,
        selectedCell,
        onAmountCellValueChange,
        onAccountAmountExpandCollapseStateChange,
        updateDrillDownState,
        props.changeTrackingData,
        props.report,
    ]);

    const traceCalculationTabPanel = useMemo(() => {
        return (
            <TraceCalculationTabPanel
                data={drillDownCtxData.traceCalculationData}
                isUnitTestExecution={props.isUnitTestExecution}
                whitePaperCellDataRowIndex={whitePaperCellDataRowIndex}
                whitePaperReportMap={whitePaperReportMap}
                cardGridListRef={refTraceCalculationTab}
                onExpandCollapseStateChange={onTraceCalculationExpandCollapseStateChange}
                key={`TraceCalculationTabPanel ${drillDownCtxData.traceCalculationData[whitePaperCellDataRowIndex].displayRowNumber}`}
                report={props.report}
                whitePaperReportColumn={props.whitePaperReportColumn}
                isFetchingOrSavingData={isFetchingOrSavingData}
            />
        );
    }, [drillDownCtxData.traceCalculationData, whitePaperReportMap, selectedCell, onTraceCalculationExpandCollapseStateChange]);

    const pivotItemContent: IReportDrillDownPivotItem[] = useMemo(
        () => [
            {
                componentRef: refAccountAmountTab,
                value: {
                    itemKey: '0',
                    headerText: accountAmountTabText,
                    alwaysRender: true,
                    children: [accountAmountTabPanel] as ReactNode[],
                } as IPivotItemProps,
                key: 'accountAmountPivotItem',
            } as IReportDrillDownPivotItem,
            {
                componentRef: refTraceCalculationTab,
                value: {
                    itemKey: '1',
                    headerText: traceCalculationTabText,
                    alwaysRender: true,
                    children: [traceCalculationTabPanel] as ReactNode[],
                } as IPivotItemProps,
                key: 'traceCalculationPivotItem',
            } as IReportDrillDownPivotItem,
        ],
        [
            accountAmountTabText,
            accountAmountTabPanel,
            refAccountAmountTab,
            refTraceCalculationTab,
            traceCalculationTabPanel,
            traceCalculationTabText,
        ]
    );

    const pivotItemStyle: CSSProperties = useMemo(() => {
        return { height: '100%' };
    }, []);
    const pivotItems: JSX.Element[] = useMemo(
        () =>
            pivotItemContent.map((item: IReportDrillDownPivotItem) => <PivotItem {...item.value} key={item.key} style={pivotItemStyle} />),
        [pivotItemContent, pivotItemStyle]
    );

    function getActiveGridRefObject(selectedIndex: number) {
        return pivotItemContent[selectedIndex]?.componentRef?.current ?? null;
    }

    const onPivotLinkClicked = useCallback(
        (item: PivotItem | undefined) => {
            if (!item?.props?.itemKey) {
                return;
            }
            const pivotItemIndex = Number(item?.props?.itemKey);
            if (pivotItemIndex < 0) {
                return;
            }

            drillDownCtxData.selectedTabKey = pivotItemIndex;
            updateDrillDownState({
                type: DrillDownActionType.ChangeActiveTabIndex,
                newActiveTab: pivotItemIndex,
                eventTargetTabIndex: pivotItemIndex,
            });
        },
        [getExpandCollapseAllTextForOperation, accountAmountGridHasData, drillDownCtxData]
    );
    const onSaveButtonClick: (postSaveChangesBehavior: PostSaveChangesBehavior) => void = useCallback(
        (postSaveChangesBehavior: PostSaveChangesBehavior) => {
            updateDrillDownState({ type: DrillDownActionType.StartProcessingSaveAmounts });
            trackDrilldownSaving();
            try {
                const consolidateAmountChanges: ExtractionDetailsAccountSummary[] =
                    consolidateAmountChangesForSave(currentAccountAmountSummaries);
                const mergedChangeTrackingData = mergeChangeTrackingData(
                    drillDownState.changeTrackingData,
                    drillDownState.updatedChangeTrackingData
                );
                const realGroupsToSave: SaveAmountsRequest = {
                    accountSummaryUpdates: consolidateAmountChanges,
                    changesTrackingData: mergedChangeTrackingData,
                    taxReturnKey: props.report.id!,
                };
                //set flag here
                saveAmounts(
                    {
                        data: realGroupsToSave,
                    },
                    {
                        onSettled: (
                            data: SaveAmountsResponse | undefined,
                            error: ErrorType<ProblemDetails> | null,
                            variables: { data: SaveAmountsRequest },
                            context: unknown | undefined
                        ) => {
                            const numberOfAccountsSaved: number = variables.data.accountSummaryUpdates!.length;
                            const numberOfAmountsSaved: number = variables.data
                                .accountSummaryUpdates!.filter((x: ExtractionDetailsAccountSummary) => x.adjustmentInfo)
                                .flatMap((x: ExtractionDetailsAccountSummary) => x.adjustmentInfo!)
                                .flatMap((x: ExtractionDetailsAdjustmentInfo) => x.entries).length;
                            const drillDownTrackingSaved: IReportDrilldownSavedTrackingInformation = {
                                numberOfAmountsSaved: numberOfAmountsSaved,
                                reportId: props.report?.id?.toString() ?? 'N/A',
                                isMultiColumnReport: props.isMultiColumnWhitePaperReport || false,
                                reportIsForEntityGroup: props.report.isForEntityGroup!,
                                success: error ? false : true,
                                columnId: props.whitePaperReportColumn?.originalColumnId?.toString() ?? 'N/A',
                                numberOfAccountGroups: numberOfAccountsSaved,
                                rowNumber: selectedCell.displayRowNumber!,
                            };
                            trackDrilldownSaved(drillDownTrackingSaved);

                            if (!error && postSaveChangesBehavior === PostSaveChangesBehavior.SaveAndClose && props.onClose) {
                                // we can't use the state variable directly because it won't be updated until the next re-render,
                                // at which point this component will be closed anyway
                                props.onClose({ amountsChangedOnServer: true, reportChanges: variables.data.changesTrackingData! });
                            } else {
                                updateDrillDownState({ type: DrillDownActionType.SaveAmountsComplete });
                            }
                        },
                        onSuccess: (
                            data: SaveAmountsResponse | undefined,
                            variables: { data: SaveAmountsRequest },
                            context: unknown | undefined
                        ) => {
                            updateDrillDownState({
                                type: DrillDownActionType.SaveAmountsSuccess,
                                updatedChangeTrackingData: variables.data.changesTrackingData!,
                            });
                        },
                        onError: (
                            error: ErrorType<ProblemDetails> | null,
                            variables: { data: SaveAmountsRequest },
                            context: unknown | undefined
                        ) => {
                            updateDrillDownState({ type: DrillDownActionType.SaveAmountsFailure, error });
                        },
                    }
                );
            } catch (error: any) {
                if (error) {
                    throw new Error(error?.request?.response);
                }
            }
        },
        [updateDrillDownState, props.onClose, currentAccountAmountSummaries, saveAmounts]
    );
    const showConfirmationDialogOnExit: boolean = drillDownState.showConfirmationDialogOnExit;
    const onDrillDownClose: () => void = useCallback(() => {
        if (showConfirmationDialogOnExit) {
            updateDrillDownState({ type: DrillDownActionType.ConfirmationDialogOpened });
        } else if (props.onClose) {
            props.onClose({ amountsChangedOnServer, reportChanges });
        }
    }, [
        currentAccountAmountSummaries,
        props.onClose,
        amountsChangedOnServer,
        reportChanges,
        updateDrillDownState,
        showConfirmationDialogOnExit,
    ]);

    const rowDefinition: IWhitePaperMappedRow = props.whitePaperReportMap[selectedCell.displayRowNumber!];
    const traces: IWhitePaperRowTrace[] = getDefiningRowsFromDataSource(
        selectedCell,
        props.whitePaperReportColumn,
        props.whitePaperReportMap
    );

    const requestCellAudit = () => {
        const requests: ExtractionDetailsRequest[] = buildExtractionRequest(
            traces,
            rowDefinition,
            props.whitePaperReportColumn,
            props.report.id!
        );
        updateDrillDownState({ type: DrillDownActionType.RequestCellAuditStart, extractionDetailsRequests: requests });

        if (requests.length === 0) {
            const drillDownTrackingNoAttemptMade: IReportDrillDownLoadedTrackingInformation = {
                reportId: props.report?.id?.toString() ?? 'N/A',
                numberOfAccountGroups: 0,
                numberOfAccountGroupsWithAdjustments: 0,
                rowMapItemCount: Object.keys(props.whitePaperReportMap).length,
                success: 'Not Attempted',
                rowRequestCount: 0,
                isMultiColumnReport: props.isMultiColumnWhitePaperReport || false,
                reportIsForEntityGroup: false,
                numberOfUniqueAccounts: 0,
                columnId: props.whitePaperReportColumn?.originalColumnId?.toString() ?? 'N/A',
                rowNumber: selectedCell.displayRowNumber!,
            };
            trackDrilldownLoaded(drillDownTrackingNoAttemptMade);
            updateDrillDownState({ type: DrillDownActionType.RequestCellAuditEnd });
            return;
        }

        const extractionDetailRequest: ExtractionDetailsRequest[] = getUniqueExtractionDetailRequests(requests);
        const reportCellAuditRequest: ReportCellAuditRequest = {
            context: {
                case: props.report.case,
                entity: props.whitePaperReportColumn?.entityCode,
                jurisdiction: props.report.jurisdiction,
                location: props.report.location,
                year: props.report.year ?? undefined,
            },
            extractionDetailsRequests: extractionDetailRequest,
        };

        try {
            getReportCellAudit(
                {
                    data: reportCellAuditRequest,
                },
                {
                    onSettled: (
                        data: ExtractionDetailsAccountSummary[] | undefined,
                        error: ErrorType<ProblemDetails> | null,
                        variables: { data: ReportCellAuditRequest },
                        context: unknown | undefined
                    ) => {
                        const drilldownLoadedTracking: IReportDrillDownLoadedTrackingInformation = {
                            reportId: props.report?.id?.toString() ?? 'N/A',
                            numberOfAccountGroups: data?.length ?? 0,
                            numberOfAccountGroupsWithAdjustments: getNumberOfAccountGroupsWithAdjustments(data),
                            rowMapItemCount: Object.keys(props.whitePaperReportMap).length,
                            isMultiColumnReport: props.isMultiColumnWhitePaperReport || false,
                            success: error ? false : true,
                            rowRequestCount: extractionDetailRequest.length,
                            reportIsForEntityGroup: props.report!.isForEntityGroup!,
                            numberOfUniqueAccounts: getNumberOfUniqueAccounts(data),
                            columnId: props.whitePaperReportColumn?.originalColumnId?.toString() ?? 'N/A',
                            rowNumber: selectedCell.displayRowNumber!,
                        };
                        trackDrilldownLoaded(drilldownLoadedTracking);
                        updateDrillDownState({ type: DrillDownActionType.RequestCellAuditEnd });
                    },
                    onSuccess: (data: ExtractionDetailsAccountSummary[]) => {
                        updateDrillDownState({
                            type: DrillDownActionType.RequestCellAuditSuccess,
                            initialAccountAmountSummaries: assignAccountSummaryEphemeralIds(data),
                        });
                    },
                    onError: (error: ErrorType<ProblemDetails>) => {
                        updateDrillDownState({ type: DrillDownActionType.RequestCellAuditFailure, error });
                    },
                }
            );
        } catch (error: any) {
            if (error) {
                throw new Error(error?.request?.response);
            }
        }
    };

    const renderErrorMessages = () => {
        return (
            <ul>
                {errorMessages?.map((message) => {
                    const messageContent: string = message.needsTranslation
                        ? t(message.message, message.translationArgs).toString()
                        : message.message;
                    return <li key={messageContent}>{messageContent}</li>;
                })}
            </ul>
        );
    };

    // make sure we only do this once
    if (drillDownState.isInitializing) {
        trackDrilldownLoading();
        requestCellAudit();
    }

    function onConfirmationModalSubmit(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
        onSaveButtonClick(PostSaveChangesBehavior.SaveAndClose);
    }

    const exportTraceCalcHook = useExportReportRowTraceToExcel();
    const exportAccountAmountHook = useExportReportCellAuditToExcel();
    function exportTraceCalculationTab() {
        updateDrillDownState({ type: DrillDownActionType.StartProcessingExport, eventTargetTabIndex: 1 });
        const reportRowTraceRequest: ReportRowTraceExportRequest = {
            columnFieldName: props.whitePaperReportColumn?.fieldName,
            rowNumber: selectedCell.displayRowNumber,
            reportId: props.report.id,
            reportName: props.report.name,
            period: props.report.period,
            executedDate: props.report.executed,
        } as ReportRowTraceExportRequest;

        exportTraceCalcHook.mutate(
            { data: reportRowTraceRequest },
            {
                onSuccess: (data: Blob) => {
                    FileSaver.saveAs(
                        data,
                        `TraceCalculation - ${props.report.name} - Row ${rowDefinition.rowNumber} - ${new Date().toISOString()}.xlsx`
                    );
                },
                onError: (error: ErrorType<ProblemDetails>) => {
                    updateDrillDownState({ type: DrillDownActionType.ExportFailure, eventTargetTabIndex: 1 });
                },
                onSettled: (
                    data: Blob | undefined,
                    error: ErrorType<ProblemDetails> | null,
                    variables: { data: ReportRowTraceExportRequest },
                    context: unknown | undefined
                ) => {
                    updateDrillDownState({ type: DrillDownActionType.StopProcessingExport, eventTargetTabIndex: 1 });
                },
            }
        );
    }
    function exportAccountAmount() {
        updateDrillDownState({ type: DrillDownActionType.StartProcessingExport, eventTargetTabIndex: 0 });
        const reportCellAuditRequest = {
            extractionDetailsRequests: buildExtractionRequest(traces, rowDefinition, props.whitePaperReportColumn, props.report.id!),
            entityName: props.whitePaperReportColumn?.entityName,
            reportName: props.report.name,
            executedAt: props.report.executed,
            period: props.report.period,
        } as ReportCellAuditExportRequest;
        exportAccountAmountHook.mutate(
            { data: reportCellAuditRequest },
            {
                onSuccess: (data: Blob) => {
                    FileSaver.saveAs(
                        data,
                        `AccountAmountExport - ${props.report.name} - Row ${rowDefinition.rowNumber} - ${new Date().toISOString()}.xlsx`
                    );
                },
                onError: (error: ErrorType<ProblemDetails>) => {
                    updateDrillDownState({ type: DrillDownActionType.ExportFailure, eventTargetTabIndex: 0 });
                },
                onSettled: (
                    data: Blob | undefined,
                    error: ErrorType<ProblemDetails> | null,
                    variables: { data: ReportCellAuditRequest },
                    context: unknown | undefined
                ) => {
                    updateDrillDownState({ type: DrillDownActionType.StopProcessingExport, eventTargetTabIndex: 0 });
                },
            }
        );
    }

    const exportFunctionMap = useMemo(
        () =>
            new Map<number, () => void>([
                [0, exportAccountAmount],
                [1, exportTraceCalculationTab],
            ]),
        [exportAccountAmount, exportTraceCalculationTab]
    );

    const expandAllClickHandler = useCallback(() => {
        const operation = expandCollapseAllButtonText === expandText ? 'Expand' : 'Collapse';
        return handleExpandCollapseAllButtonClick(drillDownCtxData, getActiveGridRefObject, operation);
    }, [drillDownCtxData, getActiveGridRefObject, expandCollapseAllButtonText, handleExpandCollapseAllButtonClick, expandText]);
    const exportClickHandler = useCallback(
        () => handleExportButtonClick(drillDownState.activeTabIndex, exportFunctionMap),
        [drillDownState, exportFunctionMap]
    );
    const confirmationDialogCancelButtonHandler = useCallback(
        (event: React.MouseEvent<HTMLElement>) => props!.onClose({ amountsChangedOnServer, reportChanges: reportChanges }),
        [props.onClose, amountsChangedOnServer, reportChanges]
    );
    const confirmationDialogOnDismissHandler = useCallback(
        () => updateDrillDownState({ type: DrillDownActionType.ConfirmationDialogClosed }),
        [updateDrillDownState]
    );
    const panelOnConfirmHandler = useCallback(() => onSaveButtonClick(PostSaveChangesBehavior.SaveOnly), [onSaveButtonClick]);
    const pivotWrapperClassName: string = useMemo(() => mergeStylesOnDemand(styles.pivotWrapper)(), [styles.pivotWrapper]);
    const pivotStyleSets = useMemo(() => mergeStyleSets(styles.pivot), [styles.pivot]);
    const updateChangeTrackingData = useCallback(
        (changeTrackingData: IWhitePaperReportChanges) =>
            updateDrillDownState({
                type: DrillDownActionType.AdjustmentModalAmountsSave,
                updatedChangeTrackingData: changeTrackingData,
            }),
        [updateDrillDownState]
    );

    const onAmountsChangedOnServer = useCallback(
        (drilldownAmountDefiningRowNumber: AllowUndefined<number>, changes: IWhitePaperReportChanges) => {
            if (!drilldownAmountDefiningRowNumber) {
                return;
            }

            const accountAmountGrid: ICardGridList = refAccountAmountTab.current!;
            const reportRowNumber: number = Number.parseInt(selectedCell.displayRowNumber!);
            const reportColumnId: number = props.whitePaperReportColumn!.originalColumnId!;
            const drilldownAmountDefiningRowNumberAsString: string = drilldownAmountDefiningRowNumber.toString();
            const cellChangesSummaries: IWhitePaperReportCellChangesSummary[] = findDrillDownAmountCellChanges(
                reportRowNumber,
                reportColumnId,
                drilldownAmountDefiningRowNumber,
                changes
            );
            const baseContext: IReportColumnContext = {
                case: props.report.case!,
                entity: props.report.entityCode!,
                jurisdiction: props.report.jurisdiction!,
                year: props.report.year!,
            };
            const changedGridGroups: IGroupExtended[] = accountAmountGrid.getGridGroups<IAccountAmountWhitePaperRow>(
                (rowData: ICardGridListRowData & IAccountAmountWhitePaperRow): boolean => {
                    let context: IReportColumnContext;
                    let adjustment: string;
                    let account: string;

                    if (rowData.rowNumber !== drilldownAmountDefiningRowNumberAsString) {
                        return false;
                    }
                    if (rowData.dataObjectType === 'AdjustmentEntry') {
                        account = rowData.parent!.parent!.account!;
                        adjustment = rowData.parent!.adjustmentCode!;
                        context = {
                            case: rowData.case!,
                            entity: rowData.entity!,
                            jurisdiction: rowData.jurisdiction!,
                            year: Number.parseInt(rowData.year!),
                        };
                    } else if (rowData.dataObjectType === 'AdjustmentSummary' && (rowData.adjustmentEntries?.length ?? 0) === 0) {
                        account = rowData.parent!.account!;
                        adjustment = rowData.adjustmentCode!;
                        context = baseContext;
                    } else {
                        return false;
                    }

                    const cellChangeSummaryFound: boolean = !!cellChangesSummaries.find(
                        (cellChangesSummary: IWhitePaperReportCellChangesSummary): boolean => {
                            if (!(cellChangesSummary.account === account && cellChangesSummary.adjustment === adjustment)) {
                                return false;
                            }

                            const cellChangeFound: boolean = !!cellChangesSummary.changes?.update?.find(
                                (cellChange: IWhitePaperReportCellChange): boolean => {
                                    const changeContext: IReportColumnContext = cellChange.context!;

                                    return (
                                        changeContext.case === context.case &&
                                        changeContext.entity === context.entity &&
                                        changeContext.jurisdiction === context.jurisdiction &&
                                        changeContext.year === context.year
                                    );
                                }
                            );

                            return cellChangeFound;
                        }
                    );

                    return cellChangeSummaryFound;
                }
            );

            for (const gridGroup of changedGridGroups) {
                const currentClassName: string | undefined = gridGroup.className;
                if (gridGroup.className?.includes(disabledClass) && !gridGroup.className?.includes(recalcNeededClass)) {
                    const newClassName = gridGroup.className?.replace(disabledClass, '');
                    gridGroup.className = newClassName ? `${newClassName} ${recalcNeededClass}` : recalcNeededClass;
                }
                if (!gridGroup.className?.includes(recalcNeededClass)) {
                    gridGroup.className = currentClassName ? `${currentClassName} ${recalcNeededClass}` : recalcNeededClass;
                }
            }
        },
        [props.report, props.whitePaperReportColumn, refAccountAmountTab, selectedCell]
    );

    const onEnterAmountsClosedCallback: (amountsChangedOnServer: boolean, changeTrackingData: IWhitePaperReportChanges) => void =
        useCallback(
            (amountsChangedOnServer: boolean, changeTrackingData: IWhitePaperReportChanges) => {
                if (amountsChangedOnServer) {
                    onAmountsChangedOnServer(drillDownState.enterAmountsDialogProps!.definingRowNumber, changeTrackingData);
                }

                updateDrillDownState({
                    type: DrillDownActionType.EnterAmountsDialogClosed,
                    updatedChangeTrackingData: changeTrackingData,
                });
            },
            [drillDownState, onAmountsChangedOnServer, updateDrillDownState]
        );

    return (
        <ToggleWidthPanel
            isOpen={props.showPanel}
            onClose={onDrillDownClose}
            onConfirm={panelOnConfirmHandler}
            primaryButtonDisabled={isSaveButtonDisabled}
            headerText={headerText}
        >
            <Stack id='drilldownHeader' className={mergeStylesOnDemand(styles.header)()}>
                <AccountAmountSection data={selectedCell} />
                <ReportColumnContext
                    case={props.report.case ?? ''}
                    entity={props.whitePaperReportColumn?.entityCode ?? '' ?? ''}
                    jurisdiction={props.report.jurisdiction ?? ''}
                    year={props.report.year?.toString() ?? ''}
                />
                {isServerError && (
                    <div className={mergeStylesOnDemand(styles.errorMessage)()}>
                        <p>{t('dataLoadError')}</p>
                        <p>{t('retryOrContactAdmin')}</p>
                        {renderErrorMessages()}
                    </div>
                )}
                {!isServerError && (errorMessages?.length ?? 0) > 0 && (
                    <div className={mergeStylesOnDemand(styles.errorMessage)()}>{renderErrorMessages()}</div>
                )}
            </Stack>
            <Pivot
                aria-label={headerText}
                componentRef={{
                    set current(instance: IPivot) {
                        addCustomPivotTabControls(instance, styles);
                    },
                }}
                defaultSelectedKey={props.isUnitTestExecution ? drillDownState.activeTabIndex.toString() : undefined}
                id={reportDrillDownPivotId}
                onLinkClick={onPivotLinkClicked}
                selectedKey={!props.isUnitTestExecution ? drillDownState.activeTabIndex.toString() : undefined}
                styles={pivotStyleSets}
                className={pivotWrapperClassName}
                key='reportDrillDownPivot'
            >
                {pivotItems}
            </Pivot>
            <NarrowIconButton
                aria-label={expandCollapseAllButtonText}
                disabled={expandCollapseAllButtonDisabled}
                id={drilldownGridCollapseButtonId}
                onClick={expandAllClickHandler}
                iconName={expandCollapseAllButtonText === expandText ? 'AddCircleRegular' : 'SubtractCircleRegular'}
                style={{ display: 'none' }}
                styles={styles.expandAllButton}
                label={expandCollapseAllButtonText}
            />
            <NarrowIconButton
                id={drilldownExportButtonId}
                onClick={exportClickHandler}
                iconName='ArrowDownloadRegular'
                style={{ display: 'none' }}
                styles={styles.exportButton}
                isProcessing={drillDownState.exportButtonStateMap.get(drillDownState.activeTabIndex)?.isProcessing}
                label={t('export').toString()}
                processingLabel={t('loading').toString()}
                disabled={drillDownState.exportButtonStateMap.get(drillDownState.activeTabIndex)?.isProcessing}
            />
            {showConfirmationDialogOnExit && (
                <ConfirmationDialog
                    show={showConfirmationDialog}
                    filterActionBarProps={{
                        primaryButtonLabel: 'saveChanges',
                        primaryHandler: onConfirmationModalSubmit,
                        secondaryHandler: confirmationDialogCancelButtonHandler,
                        secondaryButtonLabel: 'exitWithoutSaving',
                    }}
                    heading={t('saveChanges')}
                    content={t('preventNavigationDialogContent')}
                    onDismiss={confirmationDialogOnDismissHandler}
                />
            )}

            {drillDownState.enterAmountsDialogProps && (
                <EnterAmountsDialog
                    drillDownRowNumber={Number.parseInt(selectedCell.displayRowNumber!)}
                    columnId={props.whitePaperReportColumn?.originalColumnId!}
                    changeTrackingData={drillDownState.changeTrackingData}
                    taxReturnKey={props.report.id!}
                    rowDescription={`Row ${selectedCell.description}, ${props.whitePaperReportColumn?.entityCode} ${props.whitePaperReportColumn?.entityName} `}
                    updateChangeTrackingData={updateChangeTrackingData}
                    onClose={onEnterAmountsClosedCallback}
                    {...drillDownState.enterAmountsDialogProps}
                />
            )}
            {isJournalEntryEnabled && drillDownState.journalEntryDialogProps && (
                <JournalEntryDialog {...drillDownState.journalEntryDialogProps} />
            )}
        </ToggleWidthPanel>
    );
};

export default withAITracking(applicationInsightsReactPlugin, ReportDrillDown, 'Report Drill Down');

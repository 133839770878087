import { makeStyles, tokens } from '@fluentui/react-components';
import React, { Ref, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IGroupedTaxReturnsToCreate } from '../../../data-types/IGroupedTaxReturnsToCreate';
import { ITaxReturnToCreate } from '../../../data-types/ITaxReturnToCreate';
import AddReturnCard from './AddReturnCard';
import { ShowableTaxReturnItemRecord } from './AddReturnPanel';

export interface IAddReturnCardGroupProps {
    groupedTaxReturnToCreate: IGroupedTaxReturnsToCreate;
    index: number;
    onRemoveClicked: (entityId: string, calculationId: string) => void;
    onEditReturn: (taxReturn: ShowableTaxReturnItemRecord) => void;
}
const useStyles = makeStyles({
    flexContainerStyles: {
        display: 'flex !important',
        flexDirection: 'column',
        gap: '8px',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
        ':focus': {
            border: '1px solid  black ',
        },
    },
    headerTextStyles: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 600,
    },
    containerStyles: {
        padding: '12px',
        backgroundColor: tokens.colorNeutralBackground3,
    },
});

const AddReturnCardGroup: React.FC<IAddReturnCardGroupProps> = ({ groupedTaxReturnToCreate, index, onRemoveClicked, onEditReturn }) => {
    const { t } = useTranslation();
    const { flexContainerStyles, headerTextStyles, containerStyles } = useStyles();
    const cardRefs: Ref<any> = useRef([]);
    const handleKeyDown = (event: any) => {
        const focusedIndex = cardRefs.current.findIndex((ref: any) => ref === document.activeElement);
        if (event.key === 'ArrowDown') {
            const nextIndex = (focusedIndex + 1) % groupedTaxReturnToCreate.taxReturnsToCreate.length;
            cardRefs.current[nextIndex]?.focus();
            event.preventDefault();
        }

        if (event.key === 'ArrowUp') {
            const prevIndex =
                (focusedIndex - 1 + groupedTaxReturnToCreate.taxReturnsToCreate.length) %
                groupedTaxReturnToCreate.taxReturnsToCreate.length;
            cardRefs.current[prevIndex]?.focus();
            event.preventDefault();
        }
    };

    const handleEditButtonClick = (taxReturn: ITaxReturnToCreate) => {
        const mappedObject: ShowableTaxReturnItemRecord = {
            taxReturnItemName: taxReturn.taxReturnItemName,
            taxReturnItemDescription: taxReturn.taxReturnItemDescription,
            jurisdictionCode: taxReturn.jurisdictionCode,
            taxYearName: taxReturn.taxYearName,
            entityKey: groupedTaxReturnToCreate.entityKey,
            entityCode: groupedTaxReturnToCreate.entityCode,
            entityLabel: groupedTaxReturnToCreate.entityName
                ? `${groupedTaxReturnToCreate.entityCode} - ${groupedTaxReturnToCreate.entityName}`
                : groupedTaxReturnToCreate.entityCode,
            calculationKey: taxReturn.calculation.calculationKey,
            calculationName: taxReturn.calculation.name,
            calculationDescription: taxReturn.calculation.description,
            caseCode: taxReturn.caseCode,
            taxReturnItemPeriodKey: taxReturn.periodKey,
            taxReturnItemCaseKey: taxReturn.caseKey,
            columnFileKey: taxReturn.columnFileKey,
            rangeFileKey: taxReturn.rangeFileKey,
            includeAllBU: taxReturn.includeAllBU,
            ignoreEntityGroup: taxReturn.ignoreEntityGroup,
            updateColumnFileKey: taxReturn.updateColumnFileKey,
            updateBack: taxReturn.updateBack,
            updateBackReport: taxReturn.updateBackReport,
        };

        onEditReturn(mappedObject);
    };

    return (
        <div
            data-testid={`entity-card-${index}`}
            id={`entity-card-${index}`}
            aria-label={`${groupedTaxReturnToCreate.entityName} ${groupedTaxReturnToCreate.taxReturnsToCreate.length} ${t('items')}`}
            className={`${flexContainerStyles}  ${containerStyles}`}
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <h3 className={headerTextStyles}>
                {groupedTaxReturnToCreate.entityName} ({groupedTaxReturnToCreate.taxReturnsToCreate.length} {t('items')})
            </h3>
            {groupedTaxReturnToCreate.taxReturnsToCreate.map((taxReturn, cardIndex) => (
                <AddReturnCard
                    ref={(el: any) => (cardRefs.current[cardIndex] = el)}
                    key={taxReturn.taxReturnItemName}
                    taxReturnItemName={taxReturn.taxReturnItemName}
                    calculationKey={taxReturn.calculation.calculationKey ?? 0}
                    calculationName={taxReturn.calculation.name ?? ''}
                    calculationDescription={taxReturn.calculation.description ?? ''}
                    jurisdictionCode={taxReturn.jurisdictionCode}
                    entityKey={groupedTaxReturnToCreate.entityKey}
                    caseCode={taxReturn.caseCode}
                    taxYearName={taxReturn.taxYearName}
                    onDeleteReturn={() => {
                        onRemoveClicked(groupedTaxReturnToCreate.entityId, taxReturn.calculation.id ?? '');
                        document.getElementById(`entity-card-${index}`)?.focus();
                    }}
                    onEditReturn={() => handleEditButtonClick(taxReturn)}
                />
            ))}
        </div>
    );
};

export default AddReturnCardGroup;

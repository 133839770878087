import { mergeStyles } from '@fluentui/react';
import { PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect, useRef } from 'react';
import { useFormPdfView } from '../../../contexts/useFormPdfView';
import { IWhitePaperColumn } from '../../../model';
import DefaultPDFViewer from './DefaultPDFViewer';
import FormTOCSidebar, { TOC_SIDEBAR_WIDTH } from './FormTOCSidebar';
import PdfViewerToolbar from './PDFViewerToolbar';

interface IPdfViewerFormProps {
    returnKey: number | undefined;
    reportColumns: IWhitePaperColumn[];
}

const PdfViewerForm: React.FC<IPdfViewerFormProps> = ({ returnKey, reportColumns }) => {
    const { loadTocData, isLoading, pdfData, tocData, showPdf, clearFormData, onPageChange, isDrawerPinned, setPdfSetting } =
        useFormPdfView();
    const pdfViewer = useRef<PdfViewerComponent>(null);

    const pdfContainerClass = mergeStyles({
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        display: showPdf ? 'flex' : 'none',
        height: pdfData ? '100%' : 'none',
        flexDirection: 'column',
        border: '1px solid lightgray',
        borderRadius: 5,
    });

    const onZoomChange = (zoom: any) => {
        setPdfSetting('currentZoom', zoom.zoomValue);
    };

    useEffect(() => {
        loadTocData();

        return () => clearFormData();
    }, []);

    return (
        <div className={`${pdfContainerClass}`}>
            <PdfViewerToolbar pdfViewer={pdfViewer} returnKey={returnKey} reportColumns={reportColumns}></PdfViewerToolbar>
            {tocData?.folders?.length && pdfData && returnKey ? <FormTOCSidebar /> : null}
            <DefaultPDFViewer
                ref={pdfViewer}
                pdfData={pdfData}
                isLoading={isLoading}
                showPdf={showPdf}
                onPageChange={onPageChange}
                onZoomChange={onZoomChange}
                width={isDrawerPinned ? `calc(100% - ${TOC_SIDEBAR_WIDTH}px)` : '100%'}
            />
        </div>
    );
};

export default PdfViewerForm;

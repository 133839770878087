import { useCorptaxTheme } from '@corptax/react-components-common';
import { IRawStyle } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';
import { tokens } from '@fluentui/tokens';
import { useMemo } from 'react';
import { accountAmountRowPropertyNames } from '../components/enter-amounts-dialog/AccountAmountRowPropertyNames';
import { IAppTheme } from '../theme/IAppTheme';
import { mergeStylesOnDemand } from '../utils/StyleUtils';
export interface IEnterAmountsDialogStyles {
    modalStyles: string;
    dialogContentStyles: string;
    gridComponentCSSClass: string;
    spinnerClassName: string;
    totalRowStyle: IRawStyle;
    buttonOverrides: string;
    dialogBodyStyles: string;
    comboboxStyles: string;
}

const useButtonOverrides = makeStyles({
    root: {
        minWidth: 'unset',
    },
});

const useComboboxStyles = makeStyles({
    root: {
        '&:after': {
            borderBottomColor: tokens.colorCompoundBrandStroke,
        },
        '>.fui-Option__checkIcon': {
            display: 'none',
        },
    },
});

const useDialogStyles = makeStyles({
    root: {
        padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
        maxWidth: '1315px',
        width: 'auto',
        maxHeight: '650px',
        height: 'auto',
        overflow: 'auto',
    },
});

const useDialogBodyStyles = makeStyles({
    root: {
        height: '100%',
    },
});

const useDialogContentStyles = makeStyles({ root: { overflowY: 'hidden', height: '100%' } });

export const useEnterAmountsDialogStyles: () => IEnterAmountsDialogStyles = () => {
    const { brandPalette, customPalette } = useCorptaxTheme<IAppTheme>();
    const dialogContentStyles: string = useDialogContentStyles().root;

    const getSpinnerClassName: () => string = mergeStylesOnDemand({
        displayName: 'spinner',
        boxSizing: 'content-box',
        height: '100%',
    });
    const amountsGridHeaderStyle: IRawStyle = useMemo(() => {
        return {
            '.e-gridheader': {
                '.e-headercontent': {
                    '.e-table': {
                        '.e-columnheader': {
                            '.e-headercell': {
                                '.e-headercelldiv': {
                                    '.e-headertext': {
                                        // overriding the corptax theme which sets this to 600
                                        fontWeight: tokens.fontSizeBase500,
                                    },
                                },
                                backgroundColor: customPalette.blueC7DCE5,
                            },
                            borderTop: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                            alignItems: 'stretch',
                        },
                    },
                },
                border: '0 !important',
                flex: '28px 0 0',
                overflow: 'hidden auto !important',
                scrollbarGutter: 'stable',
                paddingRight: '0 !important',
            },
            /*override the corptax theme which provides left/right border for the header cells
            but leave the correct border for the first and last cell */
            '&.e-grid:not(.sf-grid).e-default .e-headercell, &.e-grid.e-default:not(.e-rtl) tr td:first-child:not(.e-xlsel-left-border,.e-fixedfreeze.e-freezeleftborder), &.e-grid.e-default:not(.e-rtl) tr th.e-headercell:first-child:not(.e-firstcell):not(.e-headercell.e-frozen-left-border), &.e-grid.e-default:not(.e-rtl) tr th.e-filterbarcell:first-child:not(.e-filterbarcell.e-frozen-left-border)':
                {
                    border: 'none',
                    borderTop: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                    borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                    ':last-child': {
                        borderRight: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                    },
                    ':first-child': {
                        borderLeft: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                    },
                },
        };
    }, [customPalette.themeLighter, customPalette.statusCoolLightGrey]);
    const amountsGridCellStyle: IRawStyle = useMemo(() => {
        return {
            '&.e-grid': {
                // override existing theme styles to add more padding to all cells
                '.e-rowcell, .e-headercell': {
                    padding: `${tokens.spacingVerticalXXS} ${tokens.spacingHorizontalL}`,
                    // first and last child have different padding in the existing corptax theme, but we don't want that here
                    ':first-child, :last-child': {
                        padding: `${tokens.spacingVerticalXXS} ${tokens.spacingHorizontalL}`,
                        lineHeight: '20px',
                    },
                },
                // this class is applied to the cell being edited as part of batch edit (despite its name implying that the cell was already edited)
                '.e-editedbatchcell.e-rowcell, .e-editedbatchcell.e-headercell': {
                    ':first-child, :last-child': {
                        padding: `${tokens.spacingVerticalNone} ${tokens.spacingHorizontalNone}`,
                    },
                    padding: `${tokens.spacingVerticalNone} ${tokens.spacingHorizontalNone}`,
                },
                // elements in this hierachy appear when you edit a cell.
                '.e-row': {
                    '.e-input-group': {
                        // if we don't unset the margin like this,
                        // the rows around the cell being edited will be pushed apart ever so slightly
                        margin: 0,
                        // adding the tag name to this selector ensures that this style has higher specificity than existing
                        // styles from the corptax CDN which set paddingTop to 2px and paddingBottom to 1px
                        'input.e-input': {
                            height: 'auto',
                            padding: `${tokens.spacingVerticalNone} ${tokens.spacingHorizontalS}`,
                        },
                    },
                },
                '&.e-grid': {
                    '.e-rowcell': {
                        border: 'none',
                        borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                        ':last-child': {
                            borderRight: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                        },
                    },
                    [`.e-rowcell.${accountAmountRowPropertyNames.creditTotal}, .e-rowcell.${accountAmountRowPropertyNames.debitTotal}, .e-rowcell.${accountAmountRowPropertyNames.combinedTotal}, .e-rowcell.${accountAmountRowPropertyNames.adjustedTotal}`]:
                        {
                            borderLeft: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                        },
                },
                // Do not display filter icon in the Adjusted amount column.
                '.e-gridheader .e-headercontent .e-columnheader .e-headercell.e-lastcell': {
                    '.e-headercelldiv': {
                        marginRight: '-5px',
                    },
                    '.e-icon-filter': {
                        display: 'none',
                    },
                },
                'td.e-updatedtd': {
                    // the corptax CDN adds a green background color to elements with this selector but we don't want that
                    backgroundColor: 'inherit',
                    color: 'inherit',
                },
            },
        };
    }, []);
    const amountsGridStyle: IRawStyle = useMemo(() => {
        return {
            displayName: 'amountsGrid',
            '&.e-grid': {
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
            },
            '.e-gridcontent': {
                '.e-content': {
                    '.e-table': {
                        '.e-row, .e-summaryrow': {
                            '.e-rowcell': {
                                color: tokens.colorNeutralForeground1,
                                fontWeight: 400,
                                borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                            },
                            [`.e-rowcell.${accountAmountRowPropertyNames.accountCode}, .e-rowcell.${accountAmountRowPropertyNames.accountDescription}, .e-rowcell.${accountAmountRowPropertyNames.accountBalanceType}, .e-rowcell.${accountAmountRowPropertyNames.adjustedTotal}`]:
                                {
                                    backgroundColor: customPalette.statusCoolMidGrey,
                                },
                            [`.e-rowcell.${accountAmountRowPropertyNames.accountCode}`]: {
                                borderLeft: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                            },
                        },
                        '.disabledRow > td': {
                            backgroundColor: `${customPalette.statusCoolMidGrey} !important`,
                        },
                    },
                    overflow: 'visible !important',
                },
                overflow: 'hidden auto !important',
                scrollbarGutter: 'stable',
                flex: '0px 1 1',
            },
            border: '0 !important',
            overflow: 'inherit',
        };
    }, [
        brandPalette.white,
        customPalette.statusCoolMidGrey,
        tokens.colorNeutralForeground1,
        brandPalette.coolLightGrayTint1,
        customPalette.statusCoolLightGrey,
    ]);

    const amountsGridFooterStyle: IRawStyle = useMemo(() => {
        return {
            '&.e-grid': {
                '.e-summarycell': {
                    border: 'none',
                },
                '.e-gridfooter': {
                    backgroundColor: `${brandPalette.white}`,
                },
            },
            '.e-gridfooter': {
                '.e-summarycontent': {
                    '.e-table': {
                        '.e-summaryrow': {
                            '.e-summarycell': {
                                backgroundColor: brandPalette.white,
                                color: tokens.colorNeutralForeground1,
                                fontWeight: tokens.fontWeightSemibold,
                                padding: `${tokens.spacingVerticalXXS} ${tokens.spacingHorizontalL}`,
                                textOverflow: 'ellipsis',
                                textWrap: 'nowrap',
                                overflow: 'hidden',
                            },
                        },
                    },
                    overflow: 'hidden !important',
                    borderRadius: 'inherit',
                    borderTop: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
                },
                border: '0 !important',
                overflow: 'hidden auto !important',
                scrollbarGutter: 'stable',
                flex: '36px 0 0',
                paddingRight: '0 !important',
            },
        };
    }, [brandPalette.white, tokens.colorNeutralForeground1]);

    const getAmountsGridClassName: () => string = mergeStylesOnDemand(
        amountsGridHeaderStyle,
        amountsGridCellStyle,
        amountsGridStyle,
        amountsGridFooterStyle
    );
    const totalRowStyle: IRawStyle = useMemo(() => {
        return {
            fontWeight: tokens.fontWeightSemibold,
            fontSize: '16px',
            verticalAlign: 'top',
            lineHeight: '22px',
        };
    }, []);

    return {
        modalStyles: useDialogStyles().root,
        dialogContentStyles,
        gridComponentCSSClass: getAmountsGridClassName(),
        spinnerClassName: getSpinnerClassName(),
        totalRowStyle,
        buttonOverrides: useButtonOverrides().root,
        dialogBodyStyles: useDialogBodyStyles().root,
        comboboxStyles: useComboboxStyles().root,
    } as IEnterAmountsDialogStyles;
};

import { EmitType } from '@syncfusion/ej2-base';
import { GridActionEventArgs, GridComponent } from '@syncfusion/ej2-react-grids';
import { RefObject } from 'react';
import { IEnterAmountsDialogFiltersFilterSelections } from '../components/enter-amounts-dialog/EnterAmountsDialogFilters';
import { IJournalEntryGridProps } from '../components/sidebar/IJournalEntryGridProps';
import { IAdjustment, ICalendarPeriod, ICase, IEntity, IJurisdiction, IWhitePaperReportChanges } from '../model';
import { ISyncfusionGridPopupMenuDismissHack } from '../utils/DialogUtilsV9';

export interface IEnterAmountsDialogState {
    jurisdiction?: IJurisdiction | undefined;
    period?: ICalendarPeriod | undefined;
    entity?: IEntity | undefined;
    adjustment?: IAdjustment | undefined;
    case?: ICase | undefined;
    location: string;
    show: boolean;
    rowNumber: number;
    columnId: number;
    changeTrackingData: IWhitePaperReportChanges;
    taxReturnKey: number;
    saveButtonIsDisabled: boolean;
    rowDescription: string;
    buttonTrigger?: HTMLElement;
    amountsOnly: boolean;
    combineDebitCreditColumns: boolean;
    journalEntryGridProps: IJournalEntryGridProps | null;
    journalEntryGridPopupMenuDismissHack: ISyncfusionGridPopupMenuDismissHack;
}
export enum EnterAmountsModalActionActionType {
    CellEdited,
    FiltersUpdated,
    FiltersReset,
    AmountsSaved,
    JournalEntryOpened,
    JournalEntryClosed,
}

export type EnterAmountsDialogReducer = (
    state: IEnterAmountsDialogState,
    action: EnterAmountsDialogReducerAction
) => IEnterAmountsDialogState;

export type EnterAmountsDialogReducerAction =
    | { type: EnterAmountsModalActionActionType.CellEdited }
    | {
          type: EnterAmountsModalActionActionType.FiltersUpdated;
          filterSelections: IEnterAmountsDialogFiltersFilterSelections;
      }
    | { type: EnterAmountsModalActionActionType.FiltersReset }
    | { type: EnterAmountsModalActionActionType.AmountsSaved; changeTrackingData: IWhitePaperReportChanges }
    | {
          type: EnterAmountsModalActionActionType.JournalEntryOpened;
          accountCode: string;
          gridActionComplete: EmitType<GridActionEventArgs>;
          gridRef: RefObject<GridComponent>;
      }
    | { type: EnterAmountsModalActionActionType.JournalEntryClosed };

export function enterAmountsDialogStateReducer(
    state: IEnterAmountsDialogState,
    action: EnterAmountsDialogReducerAction
): IEnterAmountsDialogState {
    switch (action.type) {
        case EnterAmountsModalActionActionType.CellEdited:
            return { ...state, saveButtonIsDisabled: false };
        case EnterAmountsModalActionActionType.FiltersUpdated:
            const isDatasetChange =
                state.adjustment?.adjustmentCode !== action.filterSelections.adjustment?.adjustmentCode ||
                state.case?.code !== action.filterSelections.case?.code ||
                state.entity?.code !== action.filterSelections.entity?.code ||
                state.jurisdiction?.code !== action.filterSelections.jurisdiction?.code ||
                state.period?.key !== action.filterSelections.calendarPeriod?.key;

            return {
                ...state,
                case: action.filterSelections.case,
                entity: action.filterSelections.entity,
                jurisdiction: action.filterSelections.jurisdiction,
                period: action.filterSelections.calendarPeriod,
                adjustment: action.filterSelections.adjustment,
                saveButtonIsDisabled: isDatasetChange ? true : state.saveButtonIsDisabled,
                amountsOnly: action.filterSelections.amountsOnly,
                combineDebitCreditColumns: action.filterSelections.combineDebitCreditColumns,
            };
        case EnterAmountsModalActionActionType.AmountsSaved:
            return { ...state, saveButtonIsDisabled: true, changeTrackingData: action.changeTrackingData };
        case EnterAmountsModalActionActionType.JournalEntryOpened:
            return {
                ...state,
                journalEntryGridProps: {
                    accountCode: action.accountCode,
                    adjustmentCode: state.adjustment?.adjustmentCode ?? '',
                    caseCode: state.case?.code ?? '',
                    entityCode: state.entity?.code ?? '',
                    jurisdiction: state.jurisdiction?.code ?? '',
                    gridActionComplete: action.gridActionComplete,
                    gridRef: action.gridRef,
                    period: state.period?.periodYear?.toString() ?? '',
                },
            };
        case EnterAmountsModalActionActionType.JournalEntryClosed:
            return { ...state, journalEntryGridProps: null };
        default:
            throw new Error('Unrecognized action type in Enter Amounts Dialog!');
    }
}

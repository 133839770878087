import { useCorptaxTheme } from '@corptax/react-components-common';
import { initializeIcons, mergeStyleSets, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { DataManager } from '@syncfusion/ej2-data';
import {
    ColumnDirective,
    ColumnModel,
    ColumnsDirective,
    GridActionEventArgs,
    GridComponent,
    Inject,
    KeyboardEventArgs,
    PageSettingsModel,
    QueryCellInfoEventArgs,
    Resize,
    SearchSettingsModel,
    SelectionSettingsModel,
    Sort,
    SortDirection,
    SortEventArgs,
    SortSettingsModel,
    ValueType,
    VirtualScroll,
} from '@syncfusion/ej2-react-grids';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllFolders, useGetLatestExecutionInformation, useGetTaxReturns, useSetTaxReturnStatus } from '../../../api/report/report';
import { applicationInsightsReactPlugin } from '../../../ApplicationInsightsService';
import { GridStateContextProvider } from '../../../contexts/gridStateContext';
import { searchOnType, SearchTableContextProvider } from '../../../contexts/searchTableContext';
import { ISelectedTaxReturnsAndFilters } from '../../../data-types/exportReports';
import { IFilterItem } from '../../../data-types/filterItem';
import { useFeedbackMessages } from '../../../hooks/useFeedbackMessages';
import { useHighlightSearchedValues } from '../../../hooks/useHighlightSearchedValues';
import { usePerformanceMetricsForTable } from '../../../hooks/usePerformanceMetricsForTable';
import { usePersistFocusInTableAfterDatabound } from '../../../hooks/usePersistFocusInTableAfterDatabound';
import { useTaxReturnCalculationState } from '../../../hooks/useTaxReturnCalculationState';
import {
    CalculationExecutionStatus,
    CalculationExecutionStatusCode,
    Report,
    ReportListRequest,
    ReportStatus,
    ReturnFolderDto,
} from '../../../model';
import { IAppTheme } from '../../../theme/IAppTheme';
import { isResponseArrayValid } from '../../../utils/ApiClientUtils';
import { getSortComparer } from '../../../utils/ColumnUtils';
import { getDateStringAsLocaleString } from '../../../utils/DateUtils';
import { getEntitiesFilterItem, getFilterOptions, getSelectedTaxReturnsAndFilters } from '../../../utils/FilterUtils';
import { getReportPropertyValueByFieldName, getReturnRequest } from '../../../utils/ReportUtils';
import { getTableKeyWithColumnsFilters, scrollToTop } from '../../../utils/TableUtils';
import DefaultTooltipHost from '../../common/DefaultTooltipHost';
import LoadingSpinner from '../../common/LoadingSpinner';
import { ReportStatusButton } from '../../common/ReportStatusButton';
import { StyledTable } from '../StyledTable';
import AddReturnPanel from './AddReturnPanel';
import FilterBar from './FilterBar';
import ReportLink from './ReportLink';

initializeIcons();

const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'name', direction: 'Ascending' }],
};
const pageSettings: PageSettingsModel = { pageSize: 50 };
const numberOfColumns = 10;

const ReportsTable = () => {
    const { startTrackingScrollEvent, trackRenderFinished } = usePerformanceMetricsForTable('List of returns');
    const { t } = useTranslation();
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const { activeMessage } = useFeedbackMessages();

    const getReportsTableStyles = () =>
        mergeStyleSets({
            wrapperStyles: {
                height: `calc(100vh - ${activeMessage ? '152px' : '120px'})`,
                width: '100%',
            },
            filterBarStyles: {
                position: 'sticky',
                top: 0,
                zIndex: 2,
            },
            scrollablePaneWrapperStyles: {
                position: 'relative',
                background: customPalette.themeLighterAlt,
                border: `1px solid ${customPalette.greyD1D3D4}`,
                borderTop: 'none',
                boxShadow: 'none',
                overflow: 'auto',
                margin: '0 15px',
            },
        });

    const { wrapperStyles, filterBarStyles, scrollablePaneWrapperStyles } = getReportsTableStyles();

    const [columnFilters, setColumnFilters] = useState<Record<string, IFilterItem[]>>({});
    const [folders, setFolders] = useState<IFilterItem[]>([]);
    const [disableActionButtons, setDisableActionButtons] = useState<boolean>(true);
    const { handleDatabound, setSelectedCellIndex } = usePersistFocusInTableAfterDatabound();
    const sortingDirection = useRef<SortDirection | undefined>('Ascending');
    const [hasLatestCalculationStatus, { setTrue: setHasLatestCalculationStatusTrue }] = useBoolean(false);
    const { registerGlobalDependency, fetchAllCalculationExecutionStatuses } = useTaxReturnCalculationState();
    const { mutateAsync: getLatestExecutionInformation } = useGetLatestExecutionInformation();

    const [isAddReturnPanelOpen, { setTrue: openAddReturnPanel, setFalse: closeAddReturnPanel }] = useBoolean(false);

    document.title = t('review');

    const entityFilterKey = 'entityName';
    const caseFilterKey = 'case';
    const jurisdictionFilterKey = 'jurisdiction';
    const periodFilterKey = 'period';
    const folderFilterName = 'folderName';
    const name = 'name';
    const userFullName = 'userFullName';

    const getExecutedTemplate = (data: Report) => {
        let cellContent = '';
        const executed = data.executed;
        if (executed) {
            cellContent = getDateStringAsLocaleString(executed, true);
        }

        if (cellContent === '' && executed) {
            cellContent = executed;
        }

        return (
            <DefaultTooltipHost tooltipId={data.id?.toString()} tooltipContent={cellContent}>
                <label tabIndex={-1}>{cellContent}</label>
            </DefaultTooltipHost>
        );
    };

    const gridRef = useRef<GridComponent>(null);

    const getTaxReturnsRequest = (): ReportListRequest => {
        const selectedFolderKey = columnFilters[folderFilterName]?.length ? columnFilters[folderFilterName][0]?.key : '';
        return getReturnRequest(
            columnFilters,
            parseInt(selectedFolderKey),
            entityFilterKey,
            jurisdictionFilterKey,
            periodFilterKey,
            caseFilterKey
        );
    };

    const updateTaxReturnRowDataInPlace = (taxReturnItemKey: number, updatedInformation: any, refresh: boolean = false) => {
        const report = filteredReportsResponse?.reports?.find((report) => report.id === taxReturnItemKey);
        if (report) {
            dataManager.update('id', { ...updatedInformation, id: taxReturnItemKey });
        }

        if (refresh) {
            gridRef.current?.refresh();
        } else {
            gridRef.current?.setRowData(taxReturnItemKey, { ...report, ...updatedInformation, id: taxReturnItemKey });
        }
    };

    const updateReturnLastExecutedDateInList = (
        taxReturnItemKey: number,
        lastExecuted: string,
        lastExecutedBy?: string,
        refresh: boolean = false
    ) => {
        const valuesToUpdate: any = { executed: lastExecuted };

        if (lastExecutedBy) {
            valuesToUpdate[userFullName] = lastExecutedBy;
        }

        updateTaxReturnRowDataInPlace(taxReturnItemKey, valuesToUpdate, refresh);
    };

    const { mutateAsync, isLoading: isLoadingSetStatus } = useSetTaxReturnStatus({
        mutation: {
            onSuccess: async (data: any, { reportId, params }) => {
                if (data) {
                    updateTaxReturnRowDataInPlace(reportId, { status: params?.reportStatus }, true);
                }
            },
        },
    });

    useEffect(() => {
        if (isLoadingSetStatus) {
            gridRef.current?.showSpinner();
        } else {
            gridRef.current?.hideSpinner();
        }
    }, [isLoadingSetStatus]);

    const { data: folderItems, refetch: updateFolderList } = useGetAllFolders();

    const { data: filteredReportsResponse, isError, isLoading, mutateAsync: getTaxReturns } = useGetTaxReturns();

    const dataManager = useMemo(() => new DataManager(filteredReportsResponse?.reports ?? []), [filteredReportsResponse]);
    const contentLength = filteredReportsResponse?.reports?.length ?? 0;

    const memoizedFilters = useMemo(() => getTaxReturnsRequest(), [columnFilters]);

    const displayCalculationStatusInTable = (taxReturnItemKey: string, calculationExecutionStatus: CalculationExecutionStatus) => {
        if (calculationExecutionStatus.statusCode === CalculationExecutionStatusCode.Executing) {
            updateReturnLastExecutedDateInList(parseInt(taxReturnItemKey), t('calculating'), undefined);
        } else if (calculationExecutionStatus.statusCode === CalculationExecutionStatusCode.Canceled) {
            updateReturnLastExecutedDateInList(parseInt(taxReturnItemKey), t('canceled'), undefined);
        }
    };

    useEffect(() => {
        registerGlobalDependency('ReportsTableTrigger', (taxReturnItemKey: number) => {
            getLatestExecutionInformation({ reportId: taxReturnItemKey }).then((res) => {
                updateReturnLastExecutedDateInList(taxReturnItemKey, res?.executionDate!, res?.userFullName!, true);
            });
        });

        fetchAllCalculationExecutionStatuses().then((statusMap: { [key: string]: CalculationExecutionStatus } | undefined) => {
            if (statusMap) {
                for (const [key, value] of Object.entries(statusMap)) {
                    displayCalculationStatusInTable(key, value);
                }
            }
            setHasLatestCalculationStatusTrue();
        });
    }, [filteredReportsResponse]);

    useEffect(() => {
        getTaxReturns({ data: memoizedFilters });
    }, [memoizedFilters]);

    const { storeSearchValueToHighlight, highlightSearchValueOnRender, clearSearch, hasMatch } =
        useHighlightSearchedValues(getReportPropertyValueByFieldName);

    const entities = getEntitiesFilterItem(filteredReportsResponse?.availableFilterOptions?.entities ?? []);
    const entityGroups = getEntitiesFilterItem(filteredReportsResponse?.availableFilterOptions?.entityGroups ?? []);
    const cases = getFilterOptions(filteredReportsResponse?.availableFilterOptions?.cases);
    const jurisdictions = getFilterOptions(filteredReportsResponse?.availableFilterOptions?.jurisdictions);
    const periods = getFilterOptions(filteredReportsResponse?.availableFilterOptions?.periods);
    const tableKey = getTableKeyWithColumnsFilters('ReportsTable', columnFilters);

    const updateFolders = (items: ReturnFolderDto[]) => {
        const folders: IFilterItem[] = [];
        items.forEach((item) => {
            if (!folders.some((e) => e.key === item.folderKey?.toString())) {
                folders.push({
                    key: item.folderKey?.toString() ?? '',
                    label: item.folderName ? `${item.folderName} (${item.returnCount})` : '',
                    searchBy: [item.folderName ?? ''],
                });
            }
        });

        setFolders(folders);
    };

    useEffect(() => {
        if (isResponseArrayValid(folderItems)) {
            updateFolders(folderItems ?? []);
        }
    }, [folderItems]);

    const gridKeyPressed = (args: KeyboardEventArgs) => {
        if (args.code === 'Enter') {
            const targetElement = args.target as HTMLElement;
            if (targetElement && !targetElement.classList.contains('e-headercell')) {
                args.cancel = true;
            }
        }
    };

    const reportNameTemplateTruncated = (item: Report) => {
        return (
            <DefaultTooltipHost tooltipId={item.id?.toString()} tooltipContent={item.name}>
                <ReportLink report={item} tabIndex={-1} />
            </DefaultTooltipHost>
        );
    };

    const reportHeaderTemplate = (item: ColumnModel) => {
        return (
            <DefaultTooltipHost tooltipId={item.field} tooltipContent={item.headerText}>
                <label>{item.headerText}</label>
            </DefaultTooltipHost>
        );
    };

    const reportStatusTemplate = (item: Report) => {
        return (
            <DefaultTooltipHost tooltipId={item.id?.toString()} tooltipContent={t(item.status ?? '')}>
                <ReportStatusButton
                    key={item.id}
                    status={item.status ?? ReportStatus.NotStarted}
                    onChange={(status) => {
                        handleStatusChange(item.id!, status);
                    }}
                    setSelectedCellIndex={setSelectedCellIndex}
                    isCell={true}
                    tabIndex={-1}
                />
            </DefaultTooltipHost>
        );
    };

    const getColumnTemplate = (fieldName: string) => {
        return (item: Report) => {
            const propertyKey = fieldName as keyof Report;

            return (
                <DefaultTooltipHost tooltipId={item.id?.toString()} tooltipContent={item[propertyKey]?.toString()}>
                    <label tabIndex={-1}>{item[propertyKey]}</label>
                </DefaultTooltipHost>
            );
        };
    };

    const handleStatusChange = async (reportId: number, key: ReportStatus) => {
        try {
            //todo: processing indicator
            await mutateAsync({ reportId: reportId, params: { reportStatus: key } });
            //todo: error indicator
        } catch (error: any) {
            if (error) {
                throw new Error(error?.request?.response);
            }
        }
    };

    const handleReportToExport = (): ISelectedTaxReturnsAndFilters => {
        return getSelectedTaxReturnsAndFilters(gridRef, getTaxReturnsRequest);
    };

    if (isError) {
        return (
            <div>
                <label>{t('Error')}</label>
            </div>
        );
    }

    const selectionSettings: SelectionSettingsModel = {
        checkboxOnly: true,
        persistSelection: true,
    };

    const queryCellInfo = (args: QueryCellInfoEventArgs) => {
        if (args.cell?.classList.contains('e-gridchkbox')) {
            args.cell?.querySelector('input')?.setAttribute('tabindex', '-1');
        }

        highlightSearchValueOnRender(args);
    };

    const handleActionComplete = (args: GridActionEventArgs) => {
        if (args.requestType === 'searching') {
            scrollToTop(gridRef.current);
        }
    };

    const handleActionBegin = (args: GridActionEventArgs) => {
        if (args.requestType === 'sorting') {
            sortingDirection.current = (args as SortEventArgs).direction ?? undefined;
        }

        storeSearchValueToHighlight(args);
    };

    const searchSetting: SearchSettingsModel = {
        fields: [name, entityFilterKey, jurisdictionFilterKey, caseFilterKey, userFullName],
    };

    const sortComparer = (reference: ValueType, comparer: ValueType): number => {
        return getSortComparer(reference, comparer, sortingDirection.current?.toString() || '');
    };

    const handleRowSelected = () => {
        handleDisableActionButtons(gridRef, setDisableActionButtons);
    };

    const onDatabound = () => {
        trackRenderFinished({ rowCount: contentLength, columnCount: numberOfColumns, visibleColumnCount: numberOfColumns });
        handleDatabound();
    };

    const onActionBegin = (args: any) => {
        startTrackingScrollEvent();
        handleActionBegin(args);
    };

    const closeAddReturnPanelAndRefresh = () => {
        clearSearch();
        getTaxReturns({ data: memoizedFilters });
        closeAddReturnPanel();
    };

    return (
        <GridStateContextProvider grid={gridRef}>
            <SearchTableContextProvider searchOn={searchOnType.ListOfReports}>
                <Stack className={wrapperStyles}>
                    <Stack.Item className={filterBarStyles}>
                        <FilterBar
                            updateFolderList={updateFolderList}
                            disableActionButtons={disableActionButtons}
                            isFiltered={filteredReportsResponse?.isFiltered || false}
                            clearHighlightedElements={clearSearch}
                            periods={periods}
                            entities={entities}
                            entityGroups={entityGroups}
                            cases={cases}
                            jurisdictions={jurisdictions}
                            folders={folders}
                            disabled={isLoading}
                            setColumnFilters={setColumnFilters}
                            getReportsToExport={handleReportToExport}
                            openAddReturnSidebarHandler={openAddReturnPanel}
                        />
                    </Stack.Item>
                    {isLoading || !hasLatestCalculationStatus ? (
                        <LoadingSpinner data-testid='loadingSpinner' id='loadingSpinner' label={t('loadingPleaseWait').toString()} />
                    ) : (
                        <Stack.Item className={scrollablePaneWrapperStyles}>
                            <StyledTable
                                dataSource={dataManager}
                                allowResizing={true}
                                allowSorting={true}
                                sortSettings={sortingOptions}
                                pageSettings={pageSettings}
                                enableVirtualization={true}
                                enableVirtualMaskRow={true}
                                height={contentLength === 0 || !hasMatch ? '42px' : '100%'}
                                ref={gridRef}
                                keyPressed={gridKeyPressed}
                                dataBound={onDatabound}
                                selectionSettings={selectionSettings}
                                queryCellInfo={queryCellInfo}
                                aria-busy={!isLoading}
                                rowHeight={36}
                                actionComplete={handleActionComplete}
                                actionBegin={onActionBegin}
                                searchSettings={searchSetting}
                                key={tableKey}
                                rowSelected={handleRowSelected}
                                rowDeselected={handleRowSelected}
                                loadingIndicator={{ indicatorType: 'Shimmer' }}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='id' isPrimaryKey={true} visible={false} />
                                    <ColumnDirective type='checkbox' width='40' allowResizing={false} />
                                    <ColumnDirective
                                        field={name}
                                        headerText={t('report').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={reportNameTemplateTruncated}
                                        width='45%'
                                        minWidth='45%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                    <ColumnDirective
                                        field='status'
                                        headerText={t('status').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={reportStatusTemplate}
                                        width='170px'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                        allowSearching={false}
                                    />
                                    <ColumnDirective
                                        field={entityFilterKey}
                                        headerText={t('entity').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={getColumnTemplate(entityFilterKey)}
                                        width='10%'
                                        minWidth='10%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                    <ColumnDirective
                                        field={jurisdictionFilterKey}
                                        headerText={t('jurisdiction').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={getColumnTemplate(jurisdictionFilterKey)}
                                        width='7%'
                                        minWidth='7%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                    <ColumnDirective
                                        field={periodFilterKey}
                                        headerText={t('period').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={getColumnTemplate(periodFilterKey)}
                                        width='7%'
                                        minWidth='7%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                    <ColumnDirective
                                        field={caseFilterKey}
                                        headerText={t('case').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={getColumnTemplate(caseFilterKey)}
                                        width='5%'
                                        minWidth='5%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                    <ColumnDirective
                                        field='executed'
                                        headerText={t('executed').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={getExecutedTemplate}
                                        width='12%'
                                        minWidth='12%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                    <ColumnDirective
                                        field={userFullName}
                                        headerText={t('executedBy').toString()}
                                        headerTemplate={reportHeaderTemplate}
                                        template={getColumnTemplate(userFullName)}
                                        width='12%'
                                        minWidth='12%'
                                        headerTextAlign='Center'
                                        sortComparer={sortComparer}
                                    />
                                </ColumnsDirective>
                                <Inject services={[Sort, VirtualScroll, Resize]} />
                            </StyledTable>
                            <AddReturnPanel
                                isPanelOpen={isAddReturnPanelOpen}
                                handleClose={closeAddReturnPanel}
                                onSuccess={closeAddReturnPanelAndRefresh}
                            />
                        </Stack.Item>
                    )}
                </Stack>
            </SearchTableContextProvider>
        </GridStateContextProvider>
    );
};

export const handleDisableActionButtons = (gridRef: RefObject<GridComponent>, setDisableActionButtons: (value: boolean) => void) => {
    const selectedRecords = gridRef.current?.getSelectedRecords();
    setDisableActionButtons(!selectedRecords || selectedRecords.length === 0);
};

export default withAITracking(applicationInsightsReactPlugin, ReportsTable, 'List of returns');

import { useCorptaxTheme } from '@corptax/react-components-common';
import { IRawStyle } from '@fluentui/react';
import { IAppTheme } from '../theme/IAppTheme';
import { mergeStylesOnDemand } from '../utils/StyleUtils';

export interface IJournalEntryDialogStyles {
    gridComponentCSSClass: string;
}
export const useJournalEntryDialogStyles: () => IJournalEntryDialogStyles = () => {
    const { customPalette } = useCorptaxTheme<IAppTheme>();
    const gridRootClass: string = 'journalEntryGrid';
    const gridHeaderStyle: IRawStyle = {
        '.e-gridheader': {
            paddingRight: '0px !important',
            '.e-headercontent': {
                '.e-table': {
                    '.e-columnheader': {
                        '.e-headercell': {
                            backgroundColor: customPalette.blueC7DCE5,
                        },
                    },
                },
            },
        },
    };
    const getGridClassName: () => string = mergeStylesOnDemand({ displayName: gridRootClass }, gridHeaderStyle);
    return {
        gridComponentCSSClass: getGridClassName(),
    };
};

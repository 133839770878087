import { getBffUrl } from './EnvironmentUtils';
const queryParams = new URLSearchParams();
if (window.location.search.length > 0 || window.location.pathname !== '/') {
    const queryParam = new URLSearchParams();
    queryParam.append('redirectPath', window.location.pathname + window.location.search);
    queryParams.append('returnUrl', encodeURI('/?' + queryParam.toString()));
}

const REACT_APP_API_BASE_URL = getBffUrl(window.location.host);
export const LOGIN_URL =
    queryParams.size > 0 ? REACT_APP_API_BASE_URL + '/bff/login?' + queryParams.toString() : REACT_APP_API_BASE_URL + '/bff/login';
export const VIEW_REPORT_URL = '/view/';
export const COMPARE_REPORT_URL = '/compare/';
export const REPORTS_TABLE_URL = '/';
export const AVAILABLE_REPORT_ID_TO_VIEW = 183;
export const getLogoutUrl = (sessionId: string): string => {
    return REACT_APP_API_BASE_URL + '/bff/logout?sid=' + sessionId;
};
export const PINNED_SEARCH_PARAM_NAME = 'pinned';

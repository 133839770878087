import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useState } from 'react';
import { applicationInsightsReactPlugin } from '../ApplicationInsightsService';
import { AllowNull } from '../data-types/AllowUndefinedAndNull';

export interface IEnterAmountsDialogTrackingInformation {
    columnId: string;
    reportId: string;
    rowNumber: string;
    success: boolean;
}

export interface IEnterAmountsDialogFilterChangeTrackingInformation extends IEnterAmountsDialogTrackingInformation {
    adjustment: string;
    case: string;
    entity: string;
    jurisdiction: string;
    numberOfAccounts: number;
    year: string;
}

export interface IEnterAmountsDialogInitialLoadTrackingInformation extends IEnterAmountsDialogFilterChangeTrackingInformation {}

export interface IEnterAmountsDialogSaveAmountsTrackingInformation extends IEnterAmountsDialogTrackingInformation {
    numberOfAccountsSaved: number;
    numberOfAmountsSaved: number;
}

export interface IEnterAmountsDialogPerformanceTrackingHook {
    abortEnterAmountsDialogAmountsSaveTracking: () => void;
    getEnterAmountsDialogFilterChangeTrackingInformation: () => AllowNull<IEnterAmountsDialogFilterChangeTrackingInformation>;
    getEnterAmountsDialogInitialLoadTrackingInformation: () => AllowNull<IEnterAmountsDialogInitialLoadTrackingInformation>;
    getEnterAmountsDialogSaveAmountsTrackingInformation: () => AllowNull<IEnterAmountsDialogSaveAmountsTrackingInformation>;
    trackEnterAmountsDialogFilterChangeBegin: (getTrackingInformation: () => IEnterAmountsDialogFilterChangeTrackingInformation) => void;
    trackEnterAmountsDialogFilterChangeEnd: () => void;
    trackEnterAmountsDialogInitialLoadBegin: (getTrackingInformation: () => IEnterAmountsDialogInitialLoadTrackingInformation) => void;
    trackEnterAmountsDialogInitialLoadEnd: () => void;
    trackEnterAmountsDialogSaveAmountsBegin: (getTrackingInformation: () => IEnterAmountsDialogSaveAmountsTrackingInformation) => void;
    trackEnterAmountsDialogSaveAmountsEnd: () => void;
}

interface IEventState {
    beginTime: number;
    trackingInformation: IEnterAmountsDialogTrackingInformation;
}

enum ComponentEvent {
    FilterChange = 'Filter Change',
    InitialLoad = 'Initial Load',
    SaveAmounts = 'Save Amounts',
}

const componentName: string = 'EnterAmountsDialog';
const eventProtoType = { componentName: componentName, durationInMilliseconds: 0 };

export const usePerformanceMetricsForEnterAmountsDialog: () => IEnterAmountsDialogPerformanceTrackingHook = () => {
    const [stateMap] = useState<Map<ComponentEvent, IEventState>>(new Map());
    const abortEnterAmountsDialogAmountsSaveTracking = () => stateMap.delete(ComponentEvent.SaveAmounts);
    const getEnterAmountsDialogFilterChangeTrackingInformation = () =>
        stateMap.get(ComponentEvent.FilterChange)?.trackingInformation ?? null;
    const getEnterAmountsDialogInitialLoadTrackingInformation = () => stateMap.get(ComponentEvent.InitialLoad)?.trackingInformation ?? null;
    const getEnterAmountsDialogSaveAmountsTrackingInformation = () => stateMap.get(ComponentEvent.SaveAmounts)?.trackingInformation ?? null;
    const trackFilterChange = useTrackEvent(applicationInsightsReactPlugin, ComponentEvent.FilterChange, eventProtoType);

    const trackFilterChangeBegin = (getTrackingInformation: () => IEnterAmountsDialogFilterChangeTrackingInformation) => {
        if (!stateMap.has(ComponentEvent.FilterChange)) {
            const eventState: IEventState = {
                beginTime: Date.now(),
                trackingInformation: getTrackingInformation(),
            };

            stateMap.set(ComponentEvent.FilterChange, eventState);
        }
    };

    const trackFilterChangeEnd = () => {
        const eventState: IEventState | undefined = stateMap.get(ComponentEvent.FilterChange);

        if (eventState) {
            const durationInMilliseconds = Date.now() - eventState.beginTime!;
            const eventDetails = { ...eventProtoType, durationInMilliseconds, ...eventState.trackingInformation };

            stateMap.delete(ComponentEvent.FilterChange);
            trackFilterChange(() => eventDetails);
        }
    };

    const trackInitialLoadBegin = (getTrackingInformation: () => IEnterAmountsDialogInitialLoadTrackingInformation) => {
        if (!stateMap.has(ComponentEvent.InitialLoad)) {
            const eventState: IEventState = {
                beginTime: Date.now(),
                trackingInformation: getTrackingInformation(),
            };

            stateMap.set(ComponentEvent.InitialLoad, eventState);
        }
    };

    const trackInitialLoad = useTrackEvent(applicationInsightsReactPlugin, ComponentEvent.InitialLoad, eventProtoType);

    const trackInitialLoadEnd = () => {
        const eventState: IEventState | undefined = stateMap.get(ComponentEvent.InitialLoad);

        if (eventState) {
            const durationInMilliseconds = Date.now() - eventState.beginTime;
            const eventDetails = { ...eventProtoType, durationInMilliseconds, ...eventState.trackingInformation };

            stateMap.delete(ComponentEvent.InitialLoad);
            trackInitialLoad(() => eventDetails);
        }
    };

    const trackSaveAmounts = useTrackEvent(applicationInsightsReactPlugin, ComponentEvent.SaveAmounts, eventProtoType);

    const trackSaveAmountsBegin = (getTrackingInformation: () => IEnterAmountsDialogSaveAmountsTrackingInformation) => {
        if (!stateMap.has(ComponentEvent.SaveAmounts)) {
            const eventState: IEventState = {
                beginTime: Date.now(),
                trackingInformation: getTrackingInformation(),
            };

            stateMap.set(ComponentEvent.SaveAmounts, eventState);
        }
    };

    const trackSaveAmountsEnd = () => {
        const eventState: IEventState | undefined = stateMap.get(ComponentEvent.SaveAmounts);

        if (eventState) {
            const durationInMilliseconds = Date.now() - eventState.beginTime;
            const eventDetails = { ...eventProtoType, durationInMilliseconds, ...eventState.trackingInformation };

            stateMap.delete(ComponentEvent.SaveAmounts);
            trackSaveAmounts(() => eventDetails);
        }
    };

    return {
        abortEnterAmountsDialogAmountsSaveTracking: abortEnterAmountsDialogAmountsSaveTracking,
        getEnterAmountsDialogFilterChangeTrackingInformation: getEnterAmountsDialogFilterChangeTrackingInformation,
        getEnterAmountsDialogInitialLoadTrackingInformation: getEnterAmountsDialogInitialLoadTrackingInformation,
        getEnterAmountsDialogSaveAmountsTrackingInformation: getEnterAmountsDialogSaveAmountsTrackingInformation,
        trackEnterAmountsDialogFilterChangeBegin: trackFilterChangeBegin,
        trackEnterAmountsDialogFilterChangeEnd: trackFilterChangeEnd,
        trackEnterAmountsDialogInitialLoadBegin: trackInitialLoadBegin,
        trackEnterAmountsDialogInitialLoadEnd: trackInitialLoadEnd,
        trackEnterAmountsDialogSaveAmountsBegin: trackSaveAmountsBegin,
        trackEnterAmountsDialogSaveAmountsEnd: trackSaveAmountsEnd,
    } as IEnterAmountsDialogPerformanceTrackingHook;
};
